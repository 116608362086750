import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const codeString = `
android{
    .....
    defaultConfig{
    targetSdkVersion 28
   }
compileOptions {
        sourceCompatibility JavaVersion.VERSION_1_8
        targetCompatibility JavaVersion.VERSION_1_8
}
        ......
}    
dependencies {
implementation 'com.geospark.android:geospark:1.5'
}
`;
const InstallAndroid = ({ component1, component2 }) => {
  return (
    <div className="sdk-type-component">
      <span className="gs-span">
        Install the SDK to your project via Gradle in Android Studio, add the
        dependencies below in your app build.gradle file.
      </span>
      <div className="gs-codesection">
        <SyntaxHighlighterComponent language="swift" codeString={codeString} />

        <CopyToClipboard text={codeString}>
          <Button className="icon-button">
            <FaRegCopy />
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

InstallAndroid.displayName = "InstallAndroid";
InstallAndroid.propTypes = {};
InstallAndroid.defaultProps = {};

export default Cssmodules(InstallAndroid, styles);
