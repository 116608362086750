import moment from "moment";
import dataSet from "./validationDataSet";
class Methods {
  static checkNameValidation(
    name,
    checkLetters,
    checkLettersDigits,
    len = 6,
    maxlen = 30
  ) {
    const trimName = String(name).trim();
    if (!(len === 0) && (name === null || String(name).length < 1)) {
      return 1;
    } else if (String(name).length < len) {
      return 2;
    } else if (String(name).length > maxlen) {
      return 3;
    } else if (checkLetters && !/^[a-zA-Z\s]*$/.test(name)) {
      return 4;
    } else if (
      !(len === 0) &&
      checkLettersDigits &&
      !/^[a-zA-Z0-9\W\s]+$/.test(name)
    ) {
      return 5;
    } else if (name !== null && name.length !== trimName.length) {
      return 6;
    }

    return 0;
  }

  static checkShortNameValidation(
    name,
    checkLetters,
    checkLettersDigits,
    len = 3,
    maxlen = 30
  ) {
    const trimName = String(name).trim();
    if (!(len === 0) && (name === null || String(name).length < 1)) {
      return 1;
    } else if (String(name).length < len) {
      return 2;
    } else if (String(name).length > maxlen) {
      return 3;
    } else if (checkLetters && !/^[a-zA-Z\s]*$/.test(name)) {
      return 4;
    } else if (
      name !== null &&
      checkLettersDigits &&
      !/^[a-zA-Z0-9\s]+$/.test(name)
    ) {
      return 5;
    } else if (name !== null && name.length !== trimName.length) {
      return 6;
    } else if (maxlen == 100 && String(name).length > maxlen) {
      return 7;
    }

    return 0;
  }

  static checkPromoCodeValidation(
    name,
    checkLetters,
    checkLettersDigits,
    len = 3,
    maxlen = 30
  ) {
    const trimName = String(name).trim();
    if (String(name).length > maxlen) {
      return 3;
    } else if (checkLetters && !/^[a-zA-Z\s]*$/.test(name)) {
      return 4;
    } else if (
      name !== null &&
      checkLettersDigits &&
      !/^[a-zA-Z0-9\s]+$/.test(name)
    ) {
      return 5;
    }
    return 0;
  }

  static checkPasswordValidation(password, verifyPassword, checkLen) {
    const trimName = String(password).trim();
    if (password === null || String(password).length < 1) {
      return 1;
    } else if (verifyPassword && password !== verifyPassword) {
      return 4;
    } else if (!checkLen && String(password).length < 8) {
      return 2;
    } else if (!checkLen && String(password).length > 20) {
      return 3;
    } else if (password !== null && password.length !== trimName.length) {
      return 5;
    }
    return 0;
  }
  static checkApnsPasswordValidation(password) {
    const trimName = String(password).trim();
    if (password === null || String(password).length < 1) {
      return 1;
    }
    return 0;
  }

  static checkEmailValidation(email, emailExist) {
    const expression =
      /(?!.*\.{2})^([a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-\/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([ \t]*\r\n)?[ \t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([ \t]*\r\n)?[ \t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
    const trimName = String(email).trim();
    if (email === null || String(email).length < 1) {
      return 1;
    } else if (email !== null && email.length !== trimName.length) {
      return 4;
    } else if (!expression.test(String(email).toLowerCase())) {
      return 2;
    } else if (emailExist != null) {
      return 3;
    }
    const email_blacklist_expression = new RegExp(
      dataSet.emailBlackList.join("|")
    );
    if (email_blacklist_expression.test(email)) {
      return 5;
    }
    return 0;
  }

  static checkSymbolValidation(name, checkLetters, oneHundred, oneThousand) {
    const trimName = String(name).trim();

    if (
      name !== null &&
      String(name).length > 1 &&
      checkLetters &&
      !/^[a-zA-Z\s]*$/.test(name)
    ) {
      return 2;
    } else if (!oneHundred && !oneThousand && String(name).length > 30) {
      return 3;
    } else if (oneHundred && !oneThousand && String(name).length > 100) {
      return 5;
    } else if (!oneHundred && oneThousand && String(name).length > 1000) {
      return 6;
    } else if (name && name !== null && name.length !== trimName.length) {
      return 4;
    }
    return 0;
  }

  static checkWebsiteValidation(website, nullAllow) {
    if (nullAllow && (website === null || String(website).length < 1)) {
      return 1;
    } else if (
      website !== null &&
      String(website).length >= 1 &&
      !/https?:\/\/(www\.)?[-a-zA-Z0-9@:%._\+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_\+.~#?&//=]*)/.test(
        website
      )
    ) {
      return 2;
    }
    return 0;
  }

  static checkBundleIDValidation(bundleId) {
    const expression = /^[A-Za-z0-9\.\-]{1,155}$/;

    if (bundleId === null || String(bundleId).length < 1) {
      return 1;
    } else if (!expression.test(bundleId)) {
      return 7;
    }
    return 0;
  }

  static checkPackageNameValidation(bundleId) {
    const expression = /^[A-Za-z0-9\.\-]{1,155}$/;

    if (bundleId === null || String(bundleId).length < 1) {
      return 1;
    } else if (!expression.test(bundleId)) {
      return 8;
    }
    return 0;
  }

  //calcualte distance between two point using lat and long
  static getDistance(lat1, lon1, lat2, lon2) {
    var radlat1 = (Math.PI * lat1) / 180;
    var radlat2 = (Math.PI * lat2) / 180;
    // var radlon1 = (Math.PI * lon1) / 180;
    // var radlon2 = (Math.PI * lon2) / 180;
    var theta = lon1 - lon2;
    var radtheta = (Math.PI * theta) / 180;
    var dist =
      Math.sin(radlat1) * Math.sin(radlat2) +
      Math.cos(radlat1) * Math.cos(radlat2) * Math.cos(radtheta);
    dist = Math.acos(dist);
    dist = (dist * 180) / Math.PI;
    dist = dist * 60 * 1.1515;

    const distance = dist * 1.609344 * 1000;
    return distance;
  }

  static checkKey(key) {
    const trimName = String(key).trim();
    const expression = /^[A-Za-z0-9!-_@#$%^&*(),.?":{}|<>]{2,200}$/;

    if (key === null || String(key).length < 1) {
      return 1;
    }
    if (!expression.test(key)) {
      return 2;
    }
    return 0;
  }

  static checkCountlyParams(credential_param) {
    const trimName = String(credential_param).trim();
    const expression = /^[0-9A-Fa-f_]{2,200}$/;

    if (credential_param === null || String(credential_param).length < 1) {
      return 1;
    }
    if (!expression.test(credential_param)) {
      return 2;
    }
    return 0;
  }

  static checkUserIDValidation(id) {
    const expression = /^[0-9A-Fa-f_]{24}$/;

    if (!expression.test(id)) {
      return 1;
    }
    return 0;
  }

  static checkGeofenceIDValidation(id) {
    const expression = /^[0-9A-Fa-f_]{24}$/;

    if (!expression.test(id)) {
      return 1;
    }
    return 0;
  }

  static dateFormatter(input_date) {
    const splitDate = input_date.split("-");
    const new_fromat_date =
      splitDate[1] + "/" + splitDate[2] + "/" + splitDate[0];
    return new_fromat_date;
  }

  static checkGSTValidation(number) {
    const trimName = String(number).split(" ");
    // const expression = /^([0]{1}[1-9]{1}|[1-2]{1}[0-9]{1}|[3]{1}[0-7]{1})([a-zA-Z]{5}[0-9]{4}[a-zA-Z]{1}[1-9a-zA-Z]{1}[zZ]{1}[0-9a-zA-Z]{1})+$/;
    // if (number === null || String(number).length < 1) {
    //   return 1;
    // }
    if (String(number).length > 32) {
      return 1;
    }
    if (number !== null && trimName.length > 1) {
      return 2;
    }
    return 0;
  }

  static TimeFormatter = (total_time) => {
    let time_format = "";
    if (total_time > 86400) {
      const total_days = Math.floor(total_time / 86400);
      total_time = total_time % 86400;
      time_format = String(total_days) + "days ";
    }

    if (total_time > 3600) {
      const total_hrs = Math.floor(total_time / 3600);
      total_time = total_time % 3600;
      time_format = time_format + String(total_hrs) + "hrs ";
    }

    if (total_time > 60) {
      const total_min = Math.floor(total_time / 60);
      total_time = total_time % 60;
      time_format = time_format + String(total_min) + "mins";
    } else {
      time_format = time_format + String(total_time) + "sec";
    }

    return time_format;
  };

  static ActiveBeforeTime = (recorded_at) => {
    const now = moment();
    let time_format = "";
    let total_time = Math.abs(
      Math.floor(
        moment
          .duration(now.diff(moment.utc(recorded_at).local().format()))
          .asMinutes()
      )
    );

    if (total_time > 1440) {
      const total_days = Math.floor(total_time / 1440);
      total_time = total_time % 1440;
      time_format = String(total_days) + "days ";
    }

    if (total_time > 60) {
      const total_hrs = Math.floor(total_time / 60);
      total_time = total_time % 60;
      // const total_mins = total_time - 60 * total_hrs;
      time_format =
        time_format + String(total_hrs) + "hrs " + String(total_time) + "mins";
    } else {
      time_format = time_format + String(total_time) + "mins";
    }

    return time_format;
  };

  static ActiveBeforeInsightTime = (recorded_at) => {
    const now = moment();
    let time_format = "";
    let total_time = Math.abs(
      Math.floor(
        moment
          .duration(now.diff(moment.utc(recorded_at).local().format()))
          .asMinutes()
      )
    );

    if (total_time > 1440) {
      const total_days = Math.floor(total_time / 1440);
      total_time = total_time % 1440;
      time_format = String(total_days) + "days ";
      return time_format;
    }

    if (total_time > 60) {
      const total_hrs = Math.floor(total_time / 60);
      total_time = total_time % 60;
      // const total_mins = total_time - 60 * total_hrs;
      time_format =
        time_format + String(total_hrs) + "hrs " + String(total_time) + "mins";
      return time_format;
    } else {
      time_format = time_format + String(total_time) + "mins";
    }

    return time_format;
  };

  //check whether location update is within 24hours
  static Before24h = (recorded_at) => {
    const now = moment();
    // let diff_hours = “”;
    let total_time = Math.abs(
      Math.floor(
        moment
          .duration(now.diff(moment.utc(recorded_at).local().format()))
          .asMinutes()
      )
    );

    const total_hours = Math.floor(total_time / 60);
    return total_hours;
  };

  static TextTruncate(str, length, ending) {
    if (length == null) {
      length = 100;
    }
    if (ending == null) {
      ending = "...";
    }
    if (str.length > length) {
      return str.substring(0, length - ending.length) + ending;
    } else {
      return str;
    }
  }
  /*
    For checking any generic data field is required is true or false
  */
  static checkRequired(inputData) {
    if (
      inputData === null ||
      inputData == undefined ||
      (typeof inputData == "number" && String(inputData).length < 1) ||
      (typeof inputData == "string" && String(inputData).length < 1) ||
      (typeof inputData == "object" && Array.isArray(inputData))
    ) {
      return 0;
    }
    return -1;
  }
  static checkRequiredString(inputData) {
    if (inputData === null || inputData == undefined || inputData.length < 1) {
      return 0;
    }
    if (inputData.length < 1 || inputData.length > 80) {
      return 1;
    }
    return -1;
  }
  static checkLengthString(inputData) {
    if (inputData.length > 40) {
      return 1;
    }
    return -1;
  }
}

export default Methods;
