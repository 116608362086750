import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import history from "../../actions/common/history";
import CommonMethod from "../common/commonMethod";
import CreateProject from "../../components/project/createProject/CreateProject";
import notificationsActions from "../../actions/common/notifications";
import projectAction from "../../actions/projectAction";

import BoxAndMap from "../../components/common/BoxAndMap";
import Navbar from "../../components/common/navbar/Navbar";

import OverlaySpinner from "../../components/common/OverlaySpinner";
import ProgressFooter from "../../components/common/footer/ProgressFooter";

export class ProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      projectName: null,
      project_data: null,
      projectType: null,
      validProjectName: -1,
      token: null,
      modalComponent: false,
      manualIgniteStatus: false,
      stepTryIgnite: -1,
      createProject: false
    };
    this.handleChange = this.handleChange.bind(this);
    this.onCreateProject = this.onCreateProject.bind(this);
  }

  componentWillMount() {
    this.setState({});
  }

  async componentDidMount() {
    const token = await localStorage.getItem("playground_token");
    const manual_ignite = await localStorage.getItem("isManualIgniteProject");
    if (manual_ignite === "YES") {
      this.setState({ manualIgniteStatus: true });
      localStorage.removeItem("isManualIgniteProject");
    }
    this.setState({
      token: token
    });
  }

  componentWillUpdate(nextProps, nextState) {
    const {
      projectType,
      projectName,
      token,
      manualIgniteStatus,
      createProject
    } = this.state;

    const { project_created } = this.props;

    if (nextProps.projects_details && nextProps.projects_details.length > 0) {
      if (projectType === "manual") {
        this.props.history.push({
          pathname: `/project/${nextProps.projects_details[0].id}/manual`,
          state: {
            project_name: projectName,
            publish_key: nextProps.projects_details[0].publish_key
          }
        });
      } else if (projectType === "demo") {
        let selected_project = nextProps.projects_details[0];
        if (this.state.manualIgniteStatus) {
          selected_project =
            nextProps.projects_details[nextProps.projects_details.length - 1];
        }
        if (createProject && nextProps.project_created) {
          this.props.history.push({
            pathname: `/project/${selected_project.id}/demo`,
            state: {
              publish_key: selected_project.publish_key,
              secret_api_key: selected_project.secret_api_key,
              project_name: projectName,
              project_id: selected_project.id,
              token: token,
              manualIgniteStatus: manualIgniteStatus
            }
          });
        } else {
          this.props.history.push("/projects");
        }
      }
    }
  }

  componentDidUpdate(prevProps) {}

  async handleChange(e) {
    let maxlen = 25;
    let change = {};
    change[e.target.name] = e.target.value;

    const isValidProjectName = await CommonMethod.checkShortNameValidation(
      e.target.value,
      false,
      true,
      2,
      maxlen
    );

    this.setState({
      validProjectName: isValidProjectName
    });

    this.setState(change);
  }

  async onCreateProject(e, projectType) {
    e.preventDefault();

    const { projectName, token, createProject } = this.state;

    const { actions } = this.props;

    const isValidProjectName = await CommonMethod.checkShortNameValidation(
      projectName,
      false,
      true,
      2
    );

    this.setState({
      validProjectName: isValidProjectName
    });

    if (isValidProjectName === 0) {
      const res = await actions.createProject({ projectName, token });
      this.setState({
        projectType: projectType,
        createProject: true
      });
      localStorage.setItem("is_project_updated", "YES");
    }

    window.onpopstate = function (event) {
      history.push("/projects");
    };
  }

  render() {
    const { projectName, validProjectName, manualIgniteStatus, stepTryIgnite } =
      this.state;
    const { project_saving, projects_details } = this.props;
    return (
      <div>
        <div>
          <Navbar />
          <BoxAndMap label="post-login" showDemoImage={true}>
            <div className="project-left">
              <div className="project-section">
                <div>
                  <CreateProject
                    handleChange={this.handleChange}
                    onCreateProject={this.onCreateProject}
                    manualIgniteStatus={manualIgniteStatus}
                    validProjectName={validProjectName}
                    showFirstLabel={true}
                    projects_details={projects_details}
                    disabled={
                      projectName === null ? true : validProjectName !== 0
                    }
                    stepTryIgnite={stepTryIgnite}
                  />
                </div>
              </div>
            </div>
            <div class="gs-footer" />
            <ProgressFooter
              className="half-progress"
              stepTryIgnite={stepTryIgnite}
            />
          </BoxAndMap>

          {project_saving && (
            <OverlaySpinner show={project_saving} fullScreen={true} />
          )}
        </div>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    projects_details: state.projectsReducer.projects_details,
    project_saving: state.projectsReducer.project_saving,
    project_created: state.projectsReducer.project_created
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...projectAction,
    ...notificationsActions
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(ProjectContainer);
