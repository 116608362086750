export const NameErrorHelp = [
  "This field is required.",
  "Ensure this field has atleast 6 characters.",
  "Ensure this field should have less than 30 characters.",
  "Ensure this field only contains letters.",
  "Ensure this field only contains letters and digits.",
  "White space is not allowed.",
  "Please enter valid bundleID.",
  "Please enter valid package Name."
];

export const ProjectNameHelp = [
  "This field is required.",
  "Ensure this field has atleast 6 characters.",
  "Ensure this field should have less than 25 characters.",
  "Ensure this field only contains letters.",
  "Ensure this field only contains letters and digits.",
  "White space is not allowed.",
  "Please enter valid bundleID.",
  "Please enter valid package Name."
];

export const EmailErrorHelp = [
  "This field is required.",
  "Enter a valid Email Address.",
  "Email already exists.",
  "White space is not allowed.",
  "Invalid email. Please use a different provider."
];

export const PasswordErrorHelp = [
  "This field is required.",
  "Ensure this field has atleast 8 characters.",
  "Ensure this field has no more than 20 characters.",
  "Passwords should match",
  "White space is not allowed."
];

export const SymbolErrorHelp = [
  "This field is required.",
  "Invalid field input.",
  "Ensure this field should have less than 30 characters.",
  "White space is not allowed.",
  "Ensure this field should have less than 100 characters.",
  "Ensure this field should have less than 1000 characters."
];

export const WebsiteErrorHelp = [
  "This field is required.",
  "Please enter valid url (Your url must include http(s)://)"
];

export const ShortNameErrorHelp = [
  "This field is required.",
  "Ensure this field has atleast 3 characters.",
  "Ensure this field should have less than 30 characters.",
  "Ensure this field only contains letters.",
  "Ensure this field only contains letters and digits.",
  "White space is not allowed.",
  "Maximum 10 tags can be created."
];

export const ExtraShortNameErrorHelp = [
  "This field is required.",
  "Ensure this field has atleast 2 characters.",
  "Ensure this field should have less than 25 characters.",
  "Ensure this field only contains letters.",
  "Ensure this field only contains letters and digits.",
  "White space is not allowed."
];

export const UserIDErrorHelp = ["Enter a valid User ID."];

export const GeofenceIDErrorHelp = ["Enter a valid Geofence ID."];

export const GSTErrorHelp = [
  "Enter a valid length GST No.",
  "White space is not allowed.",
  "Invalid GST number"
];

export const syncCountlyErrorHelp = [
  "This field is required.",
  "Ensure this field have proper alpha numeric characters without white spaces."
];

export const keyErrorHelp = [
  "This field is required.",
  "Ensure this field have proper alpha numeric and special characters without white spaces."
];

export const requiredCheckerHelp = [
  "This field is required.",
  "Ensure this field should have less than 80 characters."
];
export const requiredCheckerLength = [
  "Ensure this field should have less than 40 characters."
];
