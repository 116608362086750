import React from "react";
import Cssmodules from "react-css-modules";
import IOSSDKDocsBox from "./SDKDocsBox";

import IOSPlistConfige from "../../../../../public/images/IOSPlistConfige.png";
import IOSBKModeConfige from "../../../../../public/images/IOSBKModeConfige.png";

import styles from "./iOSSDKDocs.scss";

const ConfigeProject = ({ docsStep, onGoBack, onGoNext }) => {
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          introduction="Install using Cocoapods, open podfile add SDK to file."
          className="second-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <img
            className="gs-integrate-img"
            src={IOSPlistConfige}
            alt="IOS Plist Confige"
          />
          <span className="gs-intro">
            Then, in your project settings, go to Capabilities > Background
            Modes and turn on background fetch, location updates
            ,remote-notifications.
          </span>
          <img
            className="gs-integrate-img"
            src={IOSBKModeConfige}
            alt="IOS Background Mode Confige"
          />
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

ConfigeProject.displayName = "ConfigeProject";
ConfigeProject.propTypes = {};
ConfigeProject.defaultProps = {};

export default Cssmodules(ConfigeProject, styles);
