import * as types from "../actions/actionTypes";
import _ from "underscore";

const initialState = {
  geofence_fetching: false,
  geofences: null,
  geofence_pages: null,
  event_pages: null,
  geofence_count: null,
  geofence_fetching_error: null,
  geofence_tags: null,
  geofence_events: null,
  page_number: 1,
  searched_geofenceId: false,
  current_project_id: null,
  current_geofence_id: null,
  active_pagination_item: 0,
  show_pagination: false,
  delete_geofence: false,
  delete_geofence_data: null,
  geofence_search_fetching_error: null
};

function searchByGeofenceID(geofences, id) {
  for (let i = 0; i < geofences.length; i++) {
    if (geofences[i].id === id) {
      return i;
    }
  }
  return null;
}

export default function geofenceReducer(state = initialState, action) {
  switch (action.type) {
    case types.GEOFENCES_DATA_FETCHING:
      let data = {
        geofence_fetching: true,
        current_project_id: action.data.data.project_id,
        searched_geofenceId: null,
        active_pagination_item: action.data.data.page_limit,
        geofences: state.geofences
      };

      let new_fetched = false;
      if (action.data.data.hasOwnProperty("new_geofences_fetch")) {
        new_fetched = action.data.data.new_geofences_fetch;
        if (new_fetched) {
          data.geofences = null;
        }
      }

      if (action.data.data.project_id !== state.current_project_id) {
        data.geofences = null;
        data.geofence_pages = null;
        data.event_pages = null;
        data.geofence_count = null;
        data.geofence_tags = null;
        data.geofence_events = null;
        data.page_number = 1;
        data.current_geofence_id = null;
        data.active_pagination_item = 0;
        data.show_pagination = false;
      }

      return Object.assign({}, state, data);
    case types.GEOFENCES_DATA_FETCHING_SUCCESS:
      let new_geofences = state.geofences;

      if (state.geofences && action.data.prev_page !== null) {
        new_geofences = new_geofences.concat(action.data.geofences);
      } else {
        new_geofences = action.data.geofences;
      }

      return Object.assign({}, state, {
        geofence_fetching: false,
        geofences: new_geofences,
        geofence_pages: action.data.next_page,
        geofence_count: action.data.geofence_count,
        delete_geofence: false
      });
    case types.GEOFENCES_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        geofence_fetching: false,
        geofence_fetching_error: action.error,
        delete_geofence: false
      });
    case types.SEARCH_GEOFENCE_DATA_FETCHING:
      return Object.assign({}, state, {
        geofence_fetching: true
        // searched_geofenceId: action.data.geofence.geofence_id
      });
    case types.SEARCH_GEOFENCE_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        geofence_fetching: false,
        geofence_pages: null,
        geofence_count: null,
        geofences: action.data.geofences
      });
    case types.SEARCH_GEOFENCE_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        geofence_fetching: false,
        geofence_search_fetching_error:
          action.data.response.data.data.geofence_id.geofence_id
      });

    case types.GEOFENCE_TAG_DATA_FETCHING:
      return Object.assign({}, state, {
        geofence_fetching: true
      });
    case types.GEOFENCE_TAG_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        geofence_fetching: false,
        geofence_tags: action.data
      });

    case types.CREATE_GEOFENCE_SAVING_SUCCESS:
      if (_.isEmpty(state.geofences)) {
        state.geofences = [];
      }
      state.geofence_count.total_geofences =
        state.geofence_count.total_geofences + 1;
      state.geofences.push(action.data);
      return Object.assign({}, state, {
        geofence_fetching: false,
        geofences: state.geofences
      });
    case types.CREATE_GEOFENCE_SAVING:
      return Object.assign({}, state, {
        geofence_fetching: true
      });
    case types.UPDATE_GEOFENCE_SAVING_SUCCESS:
      const ObjectIndex = searchByGeofenceID(state.geofences, action.data.id);
      let new_geofence_count;
      if (state.geofence_count) {
        new_geofence_count = state.geofence_count.enabled_geofences;
      }
      if (ObjectIndex != null) {
        if (
          state.geofences[ObjectIndex].is_enabled &&
          !action.data.is_enabled
        ) {
          new_geofence_count--;
        } else if (
          !state.geofences[ObjectIndex].is_enabled &&
          action.data.is_enabled
        ) {
          new_geofence_count++;
        }
        state.geofences[ObjectIndex].is_enabled = action.data.is_enabled;
      }
      if (state.geofence_count) {
        state.geofence_count.enabled_geofences = new_geofence_count;
      }
      return Object.assign({}, state, {
        geofence_fetching: false,
        geofences: [...state.geofences],
        geofence_count: state.geofence_count
      });
    case types.UPDATE_GEOFENCE_SAVING:
      return Object.assign({}, state, {
        geofence_fetching: true
      });
    case types.DELETE_GEOFENCE_SAVING:
      return Object.assign({}, state, {
        geofence_fetching: true,
        delete_geofence: false
      });

    case types.DELETE_GEOFENCE_SAVING_FAILURE:
      return Object.assign({}, state, {
        delete_geofence_data: action.data
      });
    case types.DELETE_GEOFENCE_SAVING_SUCCESS:
      const Index = searchByGeofenceID(state.geofences, action.data);
      if (Index != null) {
        state.geofences.splice(Index, 1);
        state.geofence_count.total_geofences =
          state.geofence_count.total_geofences - 1;

        if (state.geofences.length === 0) {
          state.geofence_pages = state.geofence_pages - 1;
          state.show_pagination = true;
          state.page_number = 0;
        }
      }
      return Object.assign({}, state, {
        geofences: state.geofences,
        geofence_fetching: false,
        delete_geofence: true
      });

    case types.GEOFENCE_EVENTS_DATA_FETCHING:
      let geofence_events = state.geofence_events;
      if (state.current_geofence_id !== action.data.geofence_id) {
        geofence_events = null;
      }
      return Object.assign({}, state, {
        current_geofence_id: action.data.geofence_id,
        geofence_events: geofence_events
      });
    case types.GEOFENCE_EVENTS_DATA_FETCHING_SUCCESS:
      let new_events = state.geofence_events;
      if (state.geofence_events && action.data.prev_page !== null) {
        new_events = new_events.concat(action.data.events);
      } else {
        new_events = action.data.events;
      }
      return Object.assign({}, state, {
        geofence_events: new_events,
        event_pages: action.data.next_page
      });
    default:
      return state;
  }
}
