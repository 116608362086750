import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";
import AndroidSDKDocsBox from "./SDKDocsBox";

import styles from "./androidSDKDocs.scss";

const StartTracking = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `//To enable location, call the requestLocationPermissions and 
//requestLocationServices method.
if(!Roam.checkLocationPermission(this)) {
      Roam.requestLocationPermission(this);
} else if (!Roam.checkLocationServices(this)) {
      Roam.requestLocationServices(this);
} else{
      //Call this method to start tracking the location.  
      Roam.startTracking(this);
}`;

  const codeString10 = `
if (!Roam.checkLocationPermission(this)) {
    Roam.requestLocationPermission(this);
} else if (!Roam.checkBackgroundLocationPermission(this)) {
    Roam.requestBackgroundLocationPermission(this);
} else if (!Roam.checkLocationServices(this)) {    
    Roam.requestLocationServices(this);
} else {    
    Roam.startTracking(this);
}`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <AndroidSDKDocsBox
          introduction="To start tracking the location."
          className="forth-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
          <p>To start tracking the location above Android 10</p>
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString10}
            />
            <CopyToClipboard text={codeString10}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </AndroidSDKDocsBox>
      </div>
    </div>
  );
};

StartTracking.displayName = "StartTracking";
StartTracking.propTypes = {};
StartTracking.defaultProps = {};

export default Cssmodules(StartTracking, styles);
