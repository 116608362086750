import ApiCall from "./apiCall";
const pageNoItems = [10, 25, 50, 100];

class ProjectsApi {
  static createProject({ projectName, token }) {
    return ApiCall.post(
      "projects/",
      {
        name: projectName
      },
      token
    );
  }

  static getProjects(token) {
    return ApiCall.get("projects/", token);
  }

  static getProjectStats({ projectId, token }) {
    return ApiCall.get(
      `insights/project/stats/?project_id=${projectId}`,
      token
    );
  }

  static countlySync({ project_id, token }) {
    return ApiCall.get(
      `integrations/countly/sync/?project_id=${project_id}`,
      token
    );
  }

  static updateProject({ project, project_id, token }) {
    return ApiCall.put(`projects/${project_id}/`, project, token);
  }

  static testWebhookEvent({ project_id, event, token }) {
    return ApiCall.post(
      `projects/${project_id}/webhook/test`,
      { event: event },
      token
    );
  }

  static sendTestEvent({ project_id, integration, event, token }) {
    return ApiCall.post(
      `projects/${project_id}/${integration}/test`,
      { event: event },
      token
    );
  }

  static testSlackEvent({ project_id, event, token }) {
    return ApiCall.post(
      `projects/${project_id}/slack/test`,
      { event: event },
      token
    );
  }

  static deleteProject({ project, token }) {
    return ApiCall.delete(`projects/${project.project_id}/`, project, token);
  }

  static updateApp({ app, token }) {
    const project_id = app.project_id,
      app_id = app.app_id;
    delete app.app_id;
    delete app.project_id;
    return ApiCall.put(`projects/${project_id}/apps/${app_id}`, app, token);
  }

  static createApp({ projectId, data, token }) {
    return ApiCall.post(`projects/${projectId}/apps/`, data, token);
  }

  static deleteApp({ app, token }) {
    return ApiCall.delete(
      `projects/${app.project_id}/apps/${app.app_id}`,
      app,
      token
    );
  }

  static getProjectActiveUsers({ projectId, startDate, token }) {
    return ApiCall.get(`user/${projectId}/?date=${startDate}`, token);
  }

  static getSdkUsers({ data, apiKey }) {

    console.log(data)

    if(data.userKeyArray !== undefined && data.userValueArray !== undefined){

      var metadataString = "";
      

      for(var i=0; i<data.userKeyArray.length; i++){
        if(data.userKeyArray[i] !== "" && data.userValueArray[i] !== ""){
          metadataString = metadataString + data.userKeyArray[i] + ":" + data.userValueArray[i] + ","
        }
      }

      if(metadataString !== ""){
        metadataString = metadataString.slice(0, -1);
      }


      if (
        data.platform !== undefined &&
        data.start_date !== undefined &&
        data.end_date !== undefined &&
        data.status !== undefined
      ) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&platform=${data.platform}&status=${data.status}${metadataString === "" ? "" : `&metadata=${metadataString}`}`,
          apiKey
        );
      } else if (
        data.platform !== undefined &&
        data.start_date !== undefined &&
        data.end_date !== undefined
      ) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&platform=${data.platform}${metadataString === "" ? "" : `&metadata=${metadataString}`}`,
          apiKey
        );
      } else if (
        data.status !== undefined &&
        data.start_date !== undefined &&
        data.end_date !== undefined
      ) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&status=${data.status}${metadataString === "" ? "" : `&metadata=${metadataString}`}`,
          apiKey
        );
      } else if (data.hasOwnProperty("user_id") && data.user_id) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&user_id=${data.user_id}${metadataString === "" ? "" : `&metadata=${metadataString}`}`,
          apiKey
        );
      } else {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}${metadataString === "" ? "" : `&metadata=${metadataString}`}`,
          apiKey
        );
      }
    } else {
      if (
        data.platform !== undefined &&
        data.start_date !== undefined &&
        data.end_date !== undefined &&
        data.status !== undefined
      ) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&platform=${data.platform}&status=${data.status}`,
          apiKey
        );
      } else if (
        data.platform !== undefined &&
        data.start_date !== undefined &&
        data.end_date !== undefined
      ) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&platform=${data.platform}`,
          apiKey
        );
      } else if (
        data.status !== undefined &&
        data.start_date !== undefined &&
        data.end_date !== undefined
      ) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&status=${data.status}`,
          apiKey
        );
      } else if (data.hasOwnProperty("user_id") && data.user_id) {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&user_id=${data.user_id}`,
          apiKey
        );
      } else {
        return ApiCall.getApiKey(
          `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}`,
          apiKey
        );
      }
    }




    

    // let request_url = `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}`;
    // if (data.hasOwnProperty("user_id") && data.user_id) {
    //   request_url = `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&user_id=${data.user_id}`;
    // }
    // return ApiCall.getApiKey(request_url, apiKey);
  }

  static getSdkUsersLocation({ data, token }) {
    let request_url = `user/${data.project_id}/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&page_limit=10`;
    if (data.hasOwnProperty("user_id") && data.user_id) {
      request_url = `user/${data.project_id}/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&user_id=${data.user_id}`;
    }
    return ApiCall.get(request_url, token);
  }

  static getNoActiveSdkUsers({ data, token }) {
    return ApiCall.get(`user/${data.project_id}/?users_count=True`, token);
  }

  static getUserEvents({ data, token }) {
    return ApiCall.getUserEvents(
      `https://api.roam.ai/v1/api/event/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&user_id=${data.user_id}`,
      token
    );
  }

  static getUserTimeline({ data, token }) {
    return ApiCall.get(
      `user/timeline/?user_id=${data.user_id}&start_date=${data.start_date}&end_date=${data.end_date}&page_limit=5&page_number=${data.page_number}`,
      token
    );
  }

  static getUserTrips({ data, token }) {
    return ApiCall.get(
      `trips/v2/?user_id=${data.user_id}&trip_type=completed&start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&page_limit=5`,
      token
    );
  }

  static getUserHomeWork({ data, token }) {
    return ApiCall.get(
      `insights/user/${data.user_id}/?app_id=${data.app_id}`,
      token
    );
  }

  static getProjectTrips({ data, token }) {
    return ApiCall.getFromMotion(
      `trips/v2/?project_id=${data.project_id}&start_date=${data.start_date}&end_date=${data.end_date}&page_limit=10&page_number=${data.page_number}`,
      token
    );
  }

  static getProjectTripRoute({ data, token }) {
    return ApiCall.getFromMotion(`trips/v2/route/${data.trip_id}/`, token);
  }

  static getGeofences({ data, token }) {
    if(data.geofenceKeyArray !== undefined && data.geofenceValueArray !== undefined){
      
      var metadataString = "";

      for(var i=0; i<data.geofenceKeyArray.length; i++){
        if(data.geofenceKeyArray[i] !== "" && data.geofenceValueArray[i] !== ""){
          metadataString = metadataString + data.geofenceKeyArray[i] + ":" + data.geofenceValueArray[i] + ","
        }
      }

      if(metadataString !== ""){
        metadataString = metadataString.slice(0, -1);
      }

      if (
        (data.is_enabled === true || data.is_enabled === false) &&
        data.start_date !== undefined &&
        data.end_date !== undefined
      ) {
        return ApiCall.get(
          `geofence/?project_id=${data.project_id}&start_date=${
            data.start_date
          }&end_date=${data.end_date}&page_number=${
            data.page_number
          }&page_limit=${pageNoItems[data.page_limit]}&is_enabled=${
            data.is_enabled
          }${metadataString === "" ? "" : `&metadata=${metadataString}`}
        `,
          token
        );
      } else {
        return ApiCall.get(
          `geofence/?project_id=${data.project_id}&start_date=${
            data.start_date
          }&end_date=${data.end_date}&page_number=${
            data.page_number
          }&page_limit=${pageNoItems[data.page_limit]}${metadataString === "" ? "" : `&metadata=${metadataString}`}`,
          token
        );
      }
    } else {
      if (
        (data.is_enabled === true || data.is_enabled === false) &&
        data.start_date !== undefined &&
        data.end_date !== undefined
      ) {
        return ApiCall.get(
          `geofence/?project_id=${data.project_id}&start_date=${
            data.start_date
          }&end_date=${data.end_date}&page_number=${
            data.page_number
          }&page_limit=${pageNoItems[data.page_limit]}&is_enabled=${
            data.is_enabled
          }
        `,
          token
        );
      } else {
        return ApiCall.get(
          `geofence/?project_id=${data.project_id}&start_date=${
            data.start_date
          }&end_date=${data.end_date}&page_number=${
            data.page_number
          }&page_limit=${pageNoItems[data.page_limit]}`,
          token
        );
      }
    }


    
  }

  static getProjectEvents({ data, token }) {
    return ApiCall.get(
      `geofence/events/?project_id=${data.project_id}&endpoint_type=GeofenceEventApi&start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&page_limit=10`,
      token
    );
  }

  static searchGeofence({ data, token }) {
    if (
      data.description &&
      (data.is_enabled === true || data.is_enabled === false)
    ) {
      return ApiCall.get(
        `geofence/?project_id=${data.project_id}&description=${data.description}&is_enabled=${data.is_enabled}
        `,
        token
      );
    } else if (data.description && data.is_enabled === null) {
      return ApiCall.get(
        `geofence/?project_id=${data.project_id}&description=${data.description}`,
        token
      );
    } else if (data.id) {
      return ApiCall.get(
        `geofence/?project_id=${data.project_id}&geofence_id=${data.id}`,
        token
      );
    }
  }

  static getGeofenceTags({ projectId, token }) {
    return ApiCall.get(`geofence/${projectId}/tags/`, token);
  }

  static getGeofenceEvents({ data, token }) {
    return ApiCall.get(
      `geofence/${data.geofence_id}/event/?project_id=${data.project_id}&endpoint_type=GeofenceEventApi&start_date=${data.start_date}&page_number=${data.page_number}&end_date=${data.end_date}&page_limit=10`,
      token
    );
  }

  static getGeofence({ data, token }) {
    return ApiCall.get(`geofence/?geofence_id=${data.geofence_id}`, token);
  }

  static createGeofence({ geofence, token }) {
    delete geofence.id;
    return ApiCall.post("geofence/", geofence, token);
  }
  static updateGeofence({ geofence, token }) {
    return ApiCall.put(`geofence/${geofence.id}/`, geofence, token);
  }
  static deleteGeofence({ geofence, token }) {
    return ApiCall.delete(`geofence/${geofence.id}/`, geofence, token);
  }

  static generateApiKey({ app, token }) {
    return ApiCall.post("projects/roll/apikey/", app, token);
  }
  static generatePK({ app, token }) {
    return ApiCall.post("projects/roll/pk/", app, token);
  }
  static getCampaignsData({ data, token }) {
    return ApiCall.get(
      `campaign/?project_id=${data.project_id}&page_number=${data.page_number}`,
      token
    );
  }
  static getCampaignData({ data, token }) {
    return ApiCall.get(
      `campaign/?project_id=${data.project_id}&campaign_id=${data.campaign_id}&page_number=1`,
      token
    );
  }

  static updateCampaign({ data, token }) {
    return ApiCall.put(
      `campaign/?project_id=${data.project_id}&campaign_id=${data.campaign_id}`,
      data,
      token
    );
  }

  static createCampaign({ data, token }) {
    return ApiCall.post("campaign/", data, token);
  }
  static fileUpload({ data, token }) {
    return ApiCall.post("fileUpload/", data, token);
  }

  static createUser({ data, apiKey }) {
    return ApiCall.postApiKey("api/user/", data, apiKey);
  }
  static getCreatedUser({ data, apiKey }) {
    let request_url = `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}`;
    if (data.hasOwnProperty("user_id") && data.user_id) {
      request_url = `api/user/?start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}&user_id=${data.user_id}`;
    }
    return ApiCall.getApiKey(request_url, apiKey);
  }

  static deleteUser({ user, apiKey }) {
    return ApiCall.deleteApiKey(`api/user/?user_id=${user}`, user, apiKey);
  }

  static searchUser({ data, token }) {
    if (
      data.description &&
      data.start_date !== undefined &&
      data.end_date !== undefined &&
      data.status !== undefined &&
      data.platform !== undefined
    ) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&start_date=${data.start_date}&end_date=${data.end_date}&platform=${data.platform}&status=${data.status}&page_number=${data.page_number}`,
        token
      );
    } else if (
      data.description &&
      data.start_date !== undefined &&
      data.end_date !== undefined &&
      data.status !== undefined
    ) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&start_date=${data.start_date}&end_date=${data.end_date}&status=${data.status}&page_number=${data.page_number}`,
        token
      );
    } else if (
      data.description &&
      data.start_date !== undefined &&
      data.end_date !== undefined &&
      data.platform !== undefined
    ) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&start_date=${data.start_date}&end_date=${data.end_date}&platform=${data.platform}&page_number=${data.page_number}`,
        token
      );
    } else if (
      data.description &&
      data.start_date !== undefined &&
      data.end_date !== undefined
    ) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&start_date=${data.start_date}&end_date=${data.end_date}&page_number=${data.page_number}`,
        token
      );
    } else if (
      data.description &&
      data.status !== undefined &&
      data.platform !== undefined
    ) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&platform=${data.platform}&status=${data.status}&page_number=${data.page_number}`,
        token
      );
    } else if (data.description && data.status !== undefined) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&status=${data.status}&page_number=${data.page_number}`,
        token
      );
    } else if (data.description && data.platform !== undefined) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&platform=${data.platform}&page_number=${data.page_number}`,
        token
      );
    } else if (data.description) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&description=${data.description}&page_number=${data.page_number}`,
        token
      );
    } else if (data.id) {
      return ApiCall.get(
        `user/?project_id=${data.project_id}&user_id=${data.id}&page_number=${data.page_number}`,
        token
      );
    }
  }

  static getActiveUsers({ activeUserData, apiKey }) {
    let request_url = `api/user/active/?start_date=${activeUserData.start_date}&end_date=${activeUserData.end_date}&page_number=${activeUserData.page_number}&page_limit=10`;
    return ApiCall.getApiKey(request_url, apiKey);
  }

  static getUserLocations({ data, apiKey }) {
    let request_url = `api/location/?start_date=${data.start_date}&end-date=${data.end_date}&page_number=${data.page_number}&page_limit=30&user_id=${data.user_id}`;
    return ApiCall.getApiKey(request_url, apiKey);
  }
}
export default ProjectsApi;
