import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";

import Main from "../common/mainContainer";
import CommonMethod from "../common/commonMethod";
import validationData from "../common/validationDataSet";
import SignUp from "../../components/authentication/SignUp";
import EmailInputCode from "../../components/authentication/EmailInputCode";
import notificationsActions from "../../actions/common/notifications";
import ProgressFooter from "../../components/common/footer/ProgressFooter";
import BoxAndMap from "../../components/common/BoxAndMap";
import Navbar from "../../components/common/navbar/Navbar";
import TitleButtonBox from "../../components/common/TitleButtonBox";
import OverlaySpinner from "../../components/common/OverlaySpinner";
import AuthApi from "../../api/authApi";
import history from "../../actions/common/history";
import preLoginCommonStyle from "../../components/common/preLoginCommonStyle";
import Recaptcha from "react-google-invisible-recaptcha";
import axios from "axios";
import {Helmet} from "react-helmet";

export class SignUpContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      company_name: null,
      full_name: null,
      email: null,
      password: null,
      promo_code: null,
      validCompanyName: -1,
      validPromoCode: -1,
      validFullName: -1,
      validEmail: -1,
      validPassword: -1,
      showLoading: true,
      showEmailCodeComponent: false,
      code1: null,
      code2: null,
      validEmailCode: -1,
      industry: null
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChangeRoute = this.onChangeRoute.bind(this);
    this.onSignUp = this.onSignUp.bind(this);
    this.onIncorrectEmail = this.onIncorrectEmail.bind(this);
    this.onChangeCode = this.onChangeCode.bind(this);
    this.onSubmitEmailCode = this.onSubmitEmailCode.bind(this);
    this.onResendCode = this.onResendCode.bind(this);
    this.handleKeyPress = this.handleKeyPress.bind(this);
    this.onEnterCode = this.onEnterCode.bind(this);
    this.onResolved = this.onResolved.bind(this);
  }

  getGeoInfo = () => {
    axios
      .get("https://ipapi.co/json/")
      .then((response) => {
        let data = response.data;
        localStorage.setItem("currency", data.currency);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  componentDidMount() {
    document.addEventListener("keydown", this.handleKeyPress);
    document.addEventListener("keydown", this.onEnterCode);
    this.setState({
      showLoading: false
    });
		localStorage.removeItem("currency");
    // this.getGeoInfo();
  }

  async handleChange(e) {

    if(e.target === "industry"){
      this.setState({
        industry: e.value
      })
      return
    }

    let change = {};
    change[e.target.name] = e?.target?.value;

    if (e.target.name === "company_name") {
      const isValidCompanyName = await CommonMethod.checkShortNameValidation(
        e.target.value,
        false,
        false,
        3
      );
      this.setState({
        validCompanyName: isValidCompanyName
      });
    } else if (e.target.name === "full_name") {
      const isValidName = await CommonMethod.checkShortNameValidation(
        e.target.value,
        false,
        true,
        3
      );
      this.setState({
        validFullName: isValidName
      });
    } else if (e.target.name === "email") {
      const isValidEmail = await CommonMethod.checkEmailValidation(
        e.target.value
      );
      this.setState({
        validEmail: isValidEmail
      });
    } else if (e.target.name === "password") {
      const isValidPassword = await CommonMethod.checkPasswordValidation(
        e.target.value
      );
      this.setState({
        validPassword: isValidPassword
      });
    } else if (e.target.name === "promo_code") {
      const isValidPromoCode = await CommonMethod.checkPromoCodeValidation(
        e.target.value
      );
      this.setState({
        validPromoCode: isValidPromoCode
      });
    }

    this.setState(change);
  }

  onChangeRoute(e, path = "/login") {
    e.preventDefault();
    this.props.history.push(path);
  }

  async onSignUp(e) {
    e.preventDefault();
    const { company_name, full_name, email, password, promo_code, industry } = this.state;

    // this.setState({
    //   showEmailCodeComponent: true
    // });
    this.recaptcha.execute();
    const isValidCompanyName = await CommonMethod.checkShortNameValidation(
      company_name,
      false,
      false,
      3
    );
    const isValidName = await CommonMethod.checkShortNameValidation(
      full_name,
      false,
      true,
      3
    );
    const isValidEmail = await CommonMethod.checkEmailValidation(email);
    const isValidPassword = await CommonMethod.checkPasswordValidation(
      password
    );
    const isValidPromoCode = await CommonMethod.checkPromoCodeValidation(
      promo_code
    );
    this.setState({
      validCompanyName: isValidCompanyName,
      validFullName: isValidName,
      validEmail: isValidEmail,
      validPassword: isValidPassword,
      validPromoCode: isValidPromoCode
    });

    const isValidForm =
      isValidCompanyName === 0 &&
      isValidName === 0 &&
      isValidEmail === 0 &&
      isValidPassword === 0;

    if (isValidForm) {
      this.setState({
        showLoading: true
      });

      let source = window.location.href;

      const responseData = await AuthApi.createAccount(
        email,
        password,
        company_name,
        full_name,
        promo_code,
        source,
        !industry ? "" : industry.value
      );
      const responseParse = JSON.parse(JSON.stringify(responseData));

      const { actions } = this.props;
      this.setState({
        showLoading: false
      });
      if (
        !responseParse.hasOwnProperty("status") ||
        (responseParse.hasOwnProperty("status") &&
          validationData.serverErrorCodes.includes(responseParse.status))
      ) {
      }

      if (responseParse.status === 200) {
        actions.sendNotification("success", {
          title: responseParse.data.msg
        });

        const user_email = responseParse.data.data.email;

        await localStorage.setItem("user_email", user_email);

        this.setState({
          showEmailCodeComponent: true
        });
      } else if (responseParse.status === 201) {
        const user_email = responseParse.data.data.email;

        await localStorage.setItem("user_email", user_email);

        await localStorage.removeItem("is_existing_usr");
        actions.sendNotification("success", {
          title:
            "Sweet! You're almost ready to go ! Please check your email to verify your account."
        });
        this.setState({
          showEmailCodeComponent: true
        });
      } else if (
        responseParse.response.status <= 400 &&
        responseParse.response.status < 500
      ) {
        const errorMsg = responseParse.response.data.data.non_field_error;
        if (errorMsg[0] === "{'Account already exists.'}") {
          return actions.sendNotification("error", {
            title: "This email address is already associated with an account."
          });
        }
        actions.sendNotification("error", {
          title: errorMsg
        });
      } else if (responseParse.response.status === 403) {
        actions.sendNotification("error", {
          title: responseParse.response.data.msg
        });
      } else {
        await localStorage.removeItem("BackendError");
        actions.sendNotification("error", {
          title:
            "Looks like our backend servers not responding. Please try again in sometime."
        });
        setTimeout(() => (window.location = "/"), 3000);
        return;
      }
    }
  }

  onChangeCode(type, e) {
    if (e.toString().length === 6) {
      this.setState({
        validEmailCode: 0
      });
    } else {
      this.setState({
        validEmailCode: 1
      });
    }
    this.setState({
      [type]: e
    });
  }

  onIncorrectEmail(e) {
    e.preventDefault();
    this.setState({
      showEmailCodeComponent: false
    });
  }

  async onSubmitEmailCode(e) {
    e.preventDefault();
    const { email, code1 } = this.state;
    const code = code1.toString();
    const isValidEmailCode = code.length === 6 ? 0 : 1;
    this.setState({
      validEmailCode: isValidEmailCode
    });

    if (!isValidEmailCode) {
      this.setState({
        showLoading: true
      });
      const responseData = await AuthApi.emailCodeVerification(email, code);
      const responseParse = JSON.parse(JSON.stringify(responseData));

      const { actions } = this.props;
      this.setState({
        showLoading: false
      });
      if (responseParse.status === 200) {
        actions.sendNotification("success", {
          title: responseParse.data.msg
        });
        const token = responseParse.data.data.token;
        await localStorage.setItem("playground_token", token);

        setTimeout(() => {
          history.push("/create-project");
          this.setState({
            showLoading: false
          });
        }, 2000);
      } else if (
        responseParse.response.status <= 400 &&
        responseParse.response.status < 500
      ) {
        this.setState({
          validEmailCode: 2
        });
        const errorMsg = responseParse.response.data.msg;
        actions.sendNotification("error", {
          title: errorMsg
        });
      }
    }
  }

  async onResendCode(e) {
    e.preventDefault();
    const { email } = this.state;

    this.setState({
      showLoading: true
    });
    const responseData = await AuthApi.forgotPassword(email, "signup");
    const responseParse = JSON.parse(JSON.stringify(responseData));

    const { actions } = this.props;
    this.setState({
      showLoading: false
    });

    if (responseParse.status === 200) {
      actions.sendNotification("success", {
        title: responseParse.data.msg
      });
    } else if (
      responseParse.response.status <= 400 &&
      responseParse.response.status < 500
    ) {
      const errorMsg = responseParse.response.data.data.non_field_error;
      actions.sendNotification("error", {
        title: errorMsg
      });
    } else {
      const errorMsg = responseParse.response.data.msg;
      actions.sendNotification("error", {
        title: errorMsg
      });
    }
  }
  handleKeyPress(e) {
    if (e.keyCode === 13 && !this.state.showEmailCodeComponent) {
      this.onSignUp(e);
    }
  }

  onEnterCode(e) {
    if (e.keyCode === 13 && this.state.showEmailCodeComponent) {
      this.onSubmitEmailCode(e);
    }
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleKeyPress);
    document.addEventListener("keydown", this.onEnterCode);
  }

  onResolved() {
    console.log("");
  }

  render() {
    const {
      email,
      code1,
      validCompanyName,
      validPromoCode,
      validFullName,
      validEmail,
      validPassword,
      showLoading,
      showEmailCodeComponent,
      validEmailCode,
      industry
    } = this.state;

    return (
      <Main>
        <div style={preLoginCommonStyle.signupContainerStyle}>
        <Helmet>
          <title>Roam.ai | Create Your Account</title>
          <meta name="description" content="Sign up for Roam.ai to unlock the power of location intelligence. Use our dashboard to create projects and start building custom location-aware apps. Start your free trial now." />
        </Helmet>
          <Navbar />
          <BoxAndMap label="Sign up" onChangeRoute={this.onChangeRoute}>
            <div className="prelogin-left">
              <div className="signup-card">
                <div>
                  <TitleButtonBox
                    title="Create an account"
                    // subTitle="Create an account"
                    buttonLabel1="Create an account"
                    buttonLabel2="Login"
                    onButton1Click={this.onSignUp}
                    onButton2Click={this.onChangeRoute}
                    onEnter={this.handleKeyPress}
                  >
                    <SignUp
                      handleChange={this.handleChange}
                      validCompanyName={validCompanyName}
                      validPromoCode={validPromoCode}
                      validFullName={validFullName}
                      validEmail={validEmail}
                      validPassword={validPassword}
                      onChangeRoute={this.onChangeRoute}
                      industry={industry}
                    />
                  </TitleButtonBox>
                </div>
              </div>
              {/* <ProgressFooter className="quarter-progress" /> */}
            </div>
          </BoxAndMap>
          {showEmailCodeComponent && (
            <EmailInputCode
              email={email}
              code1={code1}
              onIncorrectEmail={this.onIncorrectEmail}
              onChangeCode={this.onChangeCode}
              onSubmitEmailCode={this.onSubmitEmailCode}
              onResendCode={this.onResendCode}
              validEmailCode={validEmailCode}
              onEnterCode={this.onEnterCode}
            />
          )}
          {showLoading && (
            <OverlaySpinner show={showLoading} fullScreen={true} />
          )}
          <Recaptcha
            ref={(ref) => (this.recaptcha = ref)}
            sitekey="6Ld5QmQaAAAAACx6o-TCf6LQ0ppPPDWkFqhi1TTo"
            onResolved={this.onResolved}
          />
        </div>
      </Main>
    );
  }
}

function mapStateToProps(state) {
  return {};
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...notificationsActions
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(mapStateToProps, mapDispatchToProps)(SignUpContainer);
