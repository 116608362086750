import React from "react";
import Cssmodules from "react-css-modules";
import { FaAndroid, FaApple, FaReact } from "react-icons/fa";
import Flutter from "../../../public/images/flutter.svg";

import IntegrateSDKCard from "./IntegrateSDKCard";
import styles from "./integrateSDK.scss";

const IntegrateSDK = ({ onClickOnSDK, projectName, project }) => {
  let ios_created = false,
    android_created = false;
  if (project) {
    project.apps.map((app) => {
      if (app.app_platform === 1) {
        android_created = true;
      }
      if (app.app_platform === 2) {
        ios_created = true;
      }
    });
  }
  return (
    <div>
      <div className="integrate-sdk-content">
        <h3>
          Let's Integrate the SDK for <br />
          <span>{projectName}</span>
        </h3>
        <p>
          Choose the platform you'd like to integrate it in and a few steps to
          start tracking.
        </p>
      </div>
      <div className="postlogin-card">
        <div
          className={
            android_created ? "post-sdkbox sdkbox-disabled" : "post-sdkbox"
          }
        >
          <IntegrateSDKCard
            icon={<FaAndroid />}
            label="Android"
            onClickOnSDK={onClickOnSDK}
          />
        </div>
        <div
          className={
            ios_created ? "post-sdkbox sdkbox-disabled" : "post-sdkbox"
          }
        >
          <IntegrateSDKCard
            icon={<FaApple />}
            label="iOS"
            onClickOnSDK={onClickOnSDK}
          />
        </div>
        {!(android_created || ios_created) && (
          <div>
            <div className="post-sdkbox">
              <IntegrateSDKCard
                icon={<FaReact />}
                label="React Native"
                onClickOnSDK={onClickOnSDK}
              />
            </div>

            <div className="post-sdkbox">
              <IntegrateSDKCard
                icon={<img src={Flutter} alt="flutter-sdk" />}
                label="Flutter"
                onClickOnSDK={onClickOnSDK}
              />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

IntegrateSDK.displayName = "IntegrateSDK";
IntegrateSDK.propTypes = {};
IntegrateSDK.defaultProps = {};

export default Cssmodules(IntegrateSDK, styles);
