import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import ReactNativeSDKCodeType from "./ReactNativeSDKCodeType";

import styles from "./reactNativeSDKDocs.scss";

const codeString1 = `
import Roam   

`;

const codeString2 = `
#import <Roam/Roam.h>

`;

const codeString3 = `
func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
    Roam.intialize("PUBLISHABLEKEY")
    return true
}
`;

const codeString4 = `
func application(_ application: UIApplication, didRegisterForRemoteNotificationsWithDeviceToken deviceToken: Data) {
    Roam.setDeviceToken(deviceToken)
}
`;

const codeString5 = `
- (void)application:(UIApplication *)application didRegisterForRemoteNotificationsWithDeviceToken:(NSData *)deviceToken{
    [Roam didRegisterForRemoteNotificationsWithDeviceToken:deviceToken];
  }
`;

const InstallAndroid = ({ docsStep, onGoBack, onGoNext }) => {
  return (
    <div className="install-Android-component">
      <div>
        <span className="gs-span">
          Import Roam into your AppDelegate file.
        </span>
        <ReactNativeSDKCodeType
          codeString1={codeString1}
          codeString2={codeString2}
        />
        <span className="gs-span">
          Initialize the SDK in your AppDelegate class before calling any other
          GeoSpark methods under this application:didFinishLaunchingWithOptions:
        </span>

        <ReactNativeSDKCodeType codeString1={codeString3} codeString2={null} />

        <h3>APNS Configurations</h3>
        <span className="gs-span">
          GeoSpark SDK is capable of sending push notifications to your users.
          Check here to get device token.
        </span>
        <ReactNativeSDKCodeType
          codeString1={codeString4}
          codeString2={codeString5}
        />
      </div>
    </div>
  );
};

InstallAndroid.displayName = "InstallAndroid";
InstallAndroid.propTypes = {};
InstallAndroid.defaultProps = {};

export default Cssmodules(InstallAndroid, styles);
