import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";
import ReactNativeSDKDocsBox from "./SDKDocsBox";
import SDKType from "./SDKType";
import IOSPermission from "./IOSPermission";
import AndroidPermission from "./AndroidPermission";
import styles from "./reactNativeSDKDocs.scss";

const codeString = `
// Call this method to check Location Permission for Android & iOS
GeoSpark.checkLocationPermission( (status) => {
  // do something with status
});
​
// Call this method to request Location Permission for Android & iOS
GeoSpark.requestLocationPermission();
`;

const GetPermissions = ({ docsStep, onGoBack, onGoNext }) => {
  return (
    <div className="rn-component">
      <div className="integrate-sdk-card">
        <ReactNativeSDKDocsBox
          introduction="Get location permission from the App user on the device. Also check if the user has turned on location services for the device. In addition, get motion permission for iOS."
          className="fifth-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="javascript"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>

            <SDKType
              component1={<IOSPermission />}
              component2={<AndroidPermission />}
            />
          </div>
        </ReactNativeSDKDocsBox>
      </div>
    </div>
  );
};

GetPermissions.displayName = "GetPermissions";
GetPermissions.propTypes = {};
GetPermissions.defaultProps = {};

export default Cssmodules(GetPermissions, styles);
