import React from "react";
import { Route, Switch } from "react-router-dom";
import history from "./actions/common/history";
import CreateProject from /* webpackPrefetch: true */ "./containers/project/createProjectContainer";
import SignUp from /* webpackPrefetch: true */ "./containers/authentication/signUpContainer";
import ManualProject from /* webpackPrefetch: true */ "./containers/project/manualProjectContainer";

const LocationPlanContainer = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/account/locationPlanContainer"
  )
);
const BillingDashboardContainer = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/account/billingDashboardContainer"
  )
);
const ProjectDetailsContainer = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/project/projectDetails/projectContainer"
  )
);
const AddCardContainer = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./containers/account/addCardContainer")
);
const BillingPlanContainer = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/account/billingPlanContainer"
  )
);
const PricingContainer = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/account/pricingContainer"
  )
);
const DemoProject = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/project/demoProjectContainer"
  )
);

const Projects = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./containers/project/projectsContainer")
);
const ResetPassword = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/authentication/resetPasswordContainer"
  )
);
const ForgotPassword = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/authentication/forgotPasswordContainer"
  )
);
const Login = React.lazy(() =>
  import(
    /* webpackPrefetch: true */ "./containers/authentication/loginContainer"
  )
);

const PrivateRoute = React.lazy(() =>
  import(/* webpackPrefetch: true */ "./containers/authentication/privateRoute")
);

const Routes = () => {
  let playground_token;

  if (window.localStorage) {
    playground_token = localStorage.getItem("playground_token");
  }

  if (
    playground_token === null &&
    (window.location.pathname === "" ||
      window.location.pathname === "/")
  ) {
    history.push("/login");
  } else if (
    playground_token &&
    (window.location.pathname === "" ||
      window.location.pathname === "/")
  ) {
    history.push("/projects");
  } else if (
    playground_token &&
    window.location.pathname === "/billing-plan"
  ) {
    history.push("/cloud-pricing-plans");
  } else if (
    playground_token &&
    window.location.pathname === "/location-plans"
  ) {
    history.push("/trackonly-pricing-plans");
  }
  return (
    <Switch>
      <Route exact path="/login" component={Login} />
      <Route path="/signup" component={SignUp} />
      <Route path="/forgot-password" component={ForgotPassword} />
      {/* <Route path="/reset-password" component={ResetPassword} /> */}
      <Route
        path="/v3/accounts/profiles/set-password/*"
        component={ResetPassword}
      />
      <PrivateRoute
        path="/create-project"
        component={CreateProject}
      />
      <PrivateRoute path="/projects" component={Projects} />
      <PrivateRoute
        path="/project/:id/manual"
        component={ManualProject}
      />
      <PrivateRoute
        path="/project/:id/demo"
        component={DemoProject}
      />
      <PrivateRoute
        path="/trackonly-pricing-plans"
        component={LocationPlanContainer}
      />
      <PrivateRoute
        path="/cloud-pricing-plans"
        component={BillingPlanContainer}
      />
      <PrivateRoute
        path="/pricing"
        component={PricingContainer}
      />
      <PrivateRoute
        path="/subscription"
        component={AddCardContainer}
      />
      <PrivateRoute
        path="/project/:id/"
        component={ProjectDetailsContainer}
      />
      <PrivateRoute
        path="/billing-dashboard"
        component={BillingDashboardContainer}
      />
    </Switch>
  );
};

export default Routes;
