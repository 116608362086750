export const PROJECT_CREATE_SAVING = "PROJECT_CREATE_SAVING";
export const PROJECT_CREATE_SAVING_SUCCESS = "PROJECT_CREATE_SAVING_SUCCESS";
export const PROJECT_CREATE_SAVING_FAILURE = "PROJECT_CREATE_SAVING_FAILURE";

export const CREATE_APP_SAVING = "CREATE_APP_SAVING";
export const CREATE_APP_SAVING_SUCCESS = "CREATE_APP_SAVING_SUCCESS";
export const CREATE_APP_SAVING_FAILURE = "CREATE_APP_SAVING_FAILURE";

export const PROJECTS_DATA_FETCHING = "PROJECTS_DATA_FETCHING";
export const PROJECTS_DATA_FETCHING_SUCCESS = "PROJECTS_DATA_FETCHING_SUCCESS";
export const PROJECTS_DATA_FETCHING_FAILURE = "PROJECTS_DATA_FETCHING_FAILURE";

export const PROJECT_STATS_DATA_FETCHING = "PROJECT_STATS_DATA_FETCHING";
export const PROJECT_STATS_DATA_FETCHING_SUCCESS =
  "PROJECT_STATS_DATA_FETCHING_SUCCESS";
export const PROJECT_STATS_DATA_FETCHING_FAILURE =
  "PROJECT_STATS_DATA_FETCHING_FAILURE";

export const PROJECT_DATA_SAVING = "PROJECT_DATA_SAVING";
export const PROJECT_DATA_SAVING_SUCCESS = "PROJECT_DATA_SAVING_SUCCESS";
export const PROJECT_DATA_SAVING_FAILURE = "PROJECT_DATA_SAVING_FAILURE";

export const COUNTLY_SYNC = "COUNTLY_SYNC";
export const COUNTLY_SYNC_SUCCESS = "COUNTLY_SYNC_SUCCESS";
export const COUNTLY_SYNC_FAILURE = "COUNTLY_SYNC_FAILURE";

export const DELETE_PROJECT_SAVING = "DELETE_PROJECT_SAVING";
export const DELETE_PROJECT_SAVING_SUCCESS = "DELETE_PROJECT_SAVING_SUCCESS";
export const DELETE_PROJECT_SAVING_FAILURE = "DELETE_PROJECT_SAVING_FAILURE";

export const UPDATE_APP_SAVING = "UPDATE_APP_SAVING";
export const UPDATE_APP_SAVING_SUCCESS = "UPDATE_APP_SAVING_SUCCESS";
export const UPDATE_APP_SAVING_FAILURE = "UPDATE_APP_SAVING_FAILURE";

export const DELETE_APP_SAVING = "DELETE_APP_SAVING";
export const DELETE_APP_SAVING_SUCCESS = "DELETE_APP_SAVING_SUCCESS";
export const DELETE_APP_SAVING_FAILURE = "DELETE_APP_SAVING_FAILURE";

export const CREATE_PROJECT_SAVING = "CREATE_PROJECT_SAVING";
export const CREATE_PROJECT_SAVING_SUCCESS = "CREATE_PROJECT_SAVING_SUCCESS";
export const CREATE_PROJECT_SAVING_FAILURE = "CREATE_PROJECT_SAVING_FAILURE";

export const PROJECT_SDK_USER_FETCHING = "PROJECT_SDK_USER_FETCHING";
export const PROJECT_SDK_USER_FETCHING_SUCCESS =
  "PROJECT_SDK_USER_FETCHING_SUCCESS";
export const PROJECT_SDK_USER_FETCHING_FAILURE =
  "PROJECT_SDK_USER_FETCHING_FAILURE";

export const PROFILE_DATA_FETCHING = "PROFILE_DATA_FETCHING";
export const PROFILE_DATA_FETCHING_SUCCESS = "PROFILE_DATA_FETCHING_SUCCESS";
export const PROFILE_DATA_FETCHING_FAILURE = "PROFILE_DATA_FETCHING_FAILURE";

export const PROFILE_DATA_SAVING = "PROFILE_DATA_SAVING";
export const PROFILE_DATA_SAVING_SUCCESS = "PROFILE_DATA_SAVING_SUCCESS";
export const PROFILE_DATA_SAVING_FAILURE = "PROFILE_DATA_SAVING_FAILURE";

export const ACCOUNT_DATA_FETCHING = "ACCOUNT_DATA_FETCHING";
export const ACCOUNT_DATA_FETCHING_SUCCESS = "ACCOUNT_DATA_FETCHING_SUCCESS";
export const ACCOUNT_DATA_FETCHING_FAILURE = "ACCOUNT_DATA_FETCHING_FAILURE";

export const ACCOUNT_DATA_SAVING = "ACCOUNT_DATA_SAVING";
export const ACCOUNT_DATA_SAVING_SUCCESS = "ACCOUNT_DATA_SAVING_SUCCESS";
export const ACCOUNT_DATA_SAVING_FAILURE = "ACCOUNT_DATA_SAVING_FAILURE";

export const ACCOUNT_ADD_MEMBER = "ACCOUNT_ADD_MEMBER";
export const ACCOUNT_ADD_MEMBER_SUCCESS = "ACCOUNT_ADD_MEMBER_SUCCESS";
export const ACCOUNT_ADD_MEMBER_FAILURE = "ACCOUNT_ADD_MEMBER_FAILURE";

export const ACCOUNT_EDIT_MEMBER = "ACCOUNT_EDIT_MEMBER";
export const ACCOUNT_EDIT_MEMBER_SUCCESS = "ACCOUNT_EDIT_MEMBER_SUCCESS";
export const ACCOUNT_EDIT_MEMBER_FAILURE = "ACCOUNT_EDIT_MEMBER_FAILURE";

export const ACCOUNT_DELETE_MEMBER = "ACCOUNT_DELETE_MEMBER";
export const ACCOUNT_DELETE_MEMBER_SUCCESS = "ACCOUNT_DELETE_MEMBER_SUCCESS";
export const ACCOUNT_DELETE_MEMBER_FAILURE = "ACCOUNT_DELETE_MEMBER_FAILURE";

export const ACCOUNT_PLAN_UPDATE = "ACCOUNT_PLAN_UPDATE";
export const ACCOUNT_PLAN_UPDATE_SUCCESS = "ACCOUNT_PLAN_UPDATE_SUCCESS";
export const ACCOUNT_PLAN_UPDATE_FAILURE = "ACCOUNT_PLAN_UPDATE_FAILURE";

export const CHANGE_PASSWORD_SAVING = "CHANGE_PASSWORD_SAVING";
export const CHANGE_PASSWORD_SAVING_SUCCESS = "CHANGE_PASSWORD_SAVING_SUCCESS";
export const CHANGE_PASSWORD_SAVING_FAILURE = "CHANGE_PASSWORD_SAVING_FAILURE";

export const LOAD_INSIGHT_DATA = "LOAD_INSIGHT_DATA";
export const LOAD_INSIGHT_DATA_SUCCESS = "LOAD_INSIGHT_DATA_SUCCESS";
export const LOAD_INSIGHT_DATA_FAILURE = "LOAD_INSIGHT_DATA_FAILURE";

export const BILLING_DETAILS_FETCHING = "BILLING_DETAILS_FETCHING";
export const BILLING_DETAILS_FETCHING_SUCCESS =
  "BILLING_DETAILS_FETCHING_SUCCESS";
export const BILLING_DETAILS_FETCHING_FAILURE =
  "BILLING_DETAILS_FETCHING_FAILURE";

export const BILLING_INVOICES_FETCHING = "BILLING_INVOICES_FETCHING";
export const BILLING_INVOICES_FETCHING_SUCCESS =
  "BILLING_INVOICES_FETCHING_SUCCESS";
export const BILLING_INVOICES_FETCHING_FAILURE =
  "BILLING_INVOICES_FETCHING_FAILURE";

export const CARDS_DETAILS_FETCHING = "CARDS_DETAILS_FETCHING";
export const CARDS_DETAILS_FETCHING_SUCCESS = "CARDS_DETAILS_FETCHING_SUCCESS";
export const CARDS_DETAILS_FETCHING_FAILURE = "CARDS_DETAILS_FETCHING_FAILURE";

export const CREATE_CARD_SAVING = "CREATE_CARD_SAVING";
export const CREATE_CARD_SAVING_SUCCESS = "CREATE_CARD_SAVING_SUCCESS";
export const CREATE_CARD_SAVING_FAILURE = "CREATE_CARD_SAVING_FAILURE";

export const SDK_USERS_DATA_FETCHING = "SDK_USERS_DATA_FETCHING";
export const SDK_USERS_DATA_FETCHING_SUCCESS =
  "SDK_USERS_DATA_FETCHING_SUCCESS";
export const SDK_USERS_DATA_FETCHING_FAILURE =
  "SDK_USERS_DATA_FETCHING_FAILURE";

export const SDK_NO_ACTIVE_USERS_FETCHING = "SDK_NO_ACTIVE_USERS_FETCHING";
export const SDK_NO_ACTIVE_USERS_FETCHING_SUCCESS =
  "SDK_NO_ACTIVE_USERS_FETCHING_SUCCESS";
export const SDK_NO_ACTIVE_USERS_FETCHING_FAILURE =
  "SDK_NO_ACTIVE_USERS_FETCHING_FAILURE";

export const USER_EVENTS_DATA_FETCHING = "USER_EVENTS_DATA_FETCHING";
export const USER_EVENTS_DATA_FETCHING_SUCCESS =
  "USER_EVENTS_DATA_FETCHING_SUCCESS";
export const USER_EVENTS_DATA_FETCHING_FAILURE =
  "USER_EVENTS_DATA_FETCHING_FAILURE";

export const USER_TIMELINE_DATA_FETCHING = "USER_TIMELINE_DATA_FETCHING";
export const USER_TIMELINE_DATA_FETCHING_SUCCESS =
  "USER_TIMELINE_DATA_FETCHING_SUCCESS";
export const USER_TIMELINE_DATA_FETCHING_FAILURE =
  "USER_TIMELINE_DATA_FETCHING_FAILURE";

export const USER_TRIPS_DATA_FETCHING = "USER_TRIPS_DATA_FETCHING";
export const USER_TRIPS_DATA_FETCHING_SUCCESS =
  "USER_TRIPS_DATA_FETCHING_SUCCESS";
export const USER_TRIPS_DATA_FETCHING_FAILURE =
  "USER_TRIPS_DATA_FETCHING_FAILURE";

export const USER_HOME_WORK_FETCHING = "USER_HOME_WORK_FETCHING";
export const USER_HOME_WORK_FETCHING_SUCCESS =
  "USER_HOME_WORK_FETCHING_SUCCESS";
export const USER_HOME_WORK_FETCHING_FAILURE =
  "USER_HOME_WORK_FETCHING_FAILURE";

export const PROJECT_TRIPS_DATA_FETCHING = "PROJECT_TRIPS_DATA_FETCHING";
export const PROJECT_TRIPS_DATA_FETCHING_SUCCESS =
  "PROJECT_TRIPS_DATA_FETCHING_SUCCESS";
export const PROJECT_TRIPS_DATA_FETCHING_FAILURE =
  "PROJECT_TRIPS_DATA_FETCHING_FAILURE";

export const PROJECT_TRIP_ROUTE_DATA_FETCHING =
  "PROJECT_TRIP_ROUTE_DATA_FETCHING";
export const PROJECT_TRIP_ROUTE_DATA_FETCHING_SUCCESS =
  "PROJECT_TRIP_ROUTE_DATA_FETCHING_SUCCESS";
export const PROJECT_TRIP_ROUTE_DATA_FETCHING_FAILURE =
  "PROJECT_TRIP_ROUTE_DATA_FETCHING_FAILURE";

export const GEOFENCES_DATA_FETCHING = "GEOFENCES_DATA_FETCHING";
export const GEOFENCES_DATA_FETCHING_SUCCESS =
  "GEOFENCES_DATA_FETCHING_SUCCESS";
export const GEOFENCES_DATA_FETCHING_FAILURE =
  "GEOFENCES_DATA_FETCHING_FAILURE";

export const GEOFENCE_DATA_FETCHING = "GEOFENCE_DATA_FETCHING";
export const GEOFENCE_DATA_FETCHING_SUCCESS = "GEOFENCE_DATA_FETCHING_SUCCESS";
export const GEOFENCE_DATA_FETCHING_FAILURE = "GEOFENCE_DATA_FETCHING_FAILURE";

export const GEOFENCE_TAG_DATA_FETCHING = "GEOFENCE_TAG_DATA_FETCHING";
export const GEOFENCE_TAG_DATA_FETCHING_SUCCESS =
  "GEOFENCE_TAG_DATA_FETCHING_SUCCESS";
export const GEOFENCE_TAG_DATA_FETCHING_FAILURE =
  "GEOFENCE_TAG_DATA_FETCHING_FAILURE";

export const CREATE_GEOFENCE_SAVING = "CREATE_GEOFENCE_SAVING";
export const CREATE_GEOFENCE_SAVING_SUCCESS = "CREATE_GEOFENCE_SAVING_SUCCESS";
export const CREATE_GEOFENCE_SAVING_FAILURE = "CREATE_GEOFENCE_SAVING_FAILURE";

export const UPDATE_GEOFENCE_SAVING = "UPDATE_GEOFENCE_SAVING";
export const UPDATE_GEOFENCE_SAVING_SUCCESS = "UPDATE_GEOFENCE_SAVING_SUCCESS";
export const UPDATE_GEOFENCE_SAVING_FAILURE = "UPDATE_GEOFENCE_SAVING_FAILURE";

export const DELETE_GEOFENCE_SAVING = "DELETE_GEOFENCE_SAVING";
export const DELETE_GEOFENCE_SAVING_SUCCESS = "DELETE_GEOFENCE_SAVING_SUCCESS";
export const DELETE_GEOFENCE_SAVING_FAILURE = "DELETE_GEOFENCE_SAVING_FAILURE";

export const SEARCH_GEOFENCE_DATA_FETCHING = "SEARCH_GEOFENCE_DATA_FETCHING";
export const SEARCH_GEOFENCE_DATA_FETCHING_SUCCESS =
  "SEARCH_GEOFENCE_DATA_FETCHING_SUCCESS";
export const SEARCH_GEOFENCE_DATA_FETCHING_FAILURE =
  "SEARCH_GEOFENCE_DATA_FETCHING_FAILURE";
export const GEOFENCE_EVENTS_DATA_FETCHING = "GEOFENCE_EVENTS_DATA_FETCHING";
export const GEOFENCE_EVENTS_DATA_FETCHING_SUCCESS =
  "GEOFENCE_EVENTS_DATA_FETCHING_SUCCESS";
export const GEOFENCE_EVENTS_DATA_FETCHING_FAILURE =
  "GEOFENCE_EVENTS_DATA_FETCHING_FAILURE";

export const PROJECT_EVENTS_DATA_FETCHING = "PROJECT_EVENTS_DATA_FETCHING";
export const PROJECT_EVENTS_DATA_FETCHING_SUCCESS =
  "PROJECT_EVENTS_DATA_FETCHING_SUCCESS";
export const PROJECT_EVENTS_DATA_FETCHING_FAILURE =
  "PROJECT_EVENTS_DATA_FETCHING_FAILURE";

export const GENERATE_API_KEY = "GENERATE_API_KEY";
export const GENERATE_API_KEY_SUCCESS = "GENERATE_API_KEY_SUCCESS";
export const GENERATE_API_KEY_FAILURE = "GENERATE_API_KEY_FAILURE";

export const GENERATE_PRIMARY_KEY = "GENERATE_PRIMARY_KEY";
export const GENERATE_PRIMARY_KEY_SUCCESS = "GENERATE_PRIMARY_KEY_SUCCESS";
export const GENERATE_PRIMARY_KEY_FAILURE = "GENERATE_PRIMARY_KEY_FAILURE";

export const PROJECT_CAMPAIGNS_DATA_FETCHING =
  "PROJECT_CAMPAIGNS_DATA_FETCHING";
export const PROJECT_CAMPAIGNS_DATA_FETCHING_SUCCESS =
  "PROJECT_CAMPAIGNS_DATA_FETCHING_SUCCESS";
export const PROJECT_CAMPAIGNS_DATA_FETCHING_FAILURE =
  "PROJECT_CAMPAIGNS_DATA_FETCHING_FAILURE";

export const CAMPAIGN_DATA_FETCHING = "CAMPAIGN_DATA_FETCHING";
export const CAMPAIGN_DATA_FETCHING_SUCCESS = "CAMPAIGN_DATA_FETCHING_SUCCESS";
export const CAMPAIGN_DATA_FETCHING_FAILURE = "CAMPAIGN_DATA_FETCHING_FAILURE";

export const CREATE_CAMPAIGN = "CREATE_CAMPAIGN";
export const CREATE_CAMPAIGN_SUCCESS = "CREATE_CAMPAIGN_SUCCESS";
export const CREATE_CAMPAIGN_FAILURE = "CREATE_CAMPAIGN_FAILURE";

export const UPDATE_CAMPAIGN = "UPDATE_CAMPAIGN";
export const UPDATE_CAMPAIGN_SUCCESS = "UPDATE_CAMPAIGN_SUCCESS";
export const UPDATE_CAMPAIGN_FAILURE = "UPDATE_CAMPAIGN_FAILURE";

// used for the location hists map displaying data
export const HEATMAP_DATA_FETCHING = "HEATMAP_DATA_FETCHING";
export const HEATMAP_DATA_FETCHING_SUCCESS = "HEATMAP_DATA_FETCHING_SUCCESS";
export const HEATMAP_DATA_FETCHING_FAILURE = "HEATMAP_DATA_FETCHING_FAILURE";

// used to reset the trips and the trip route data
export const CLEAR_TRIPS_DATA = "CLEAR_TRIPS_DATA";

// used to control the css change for modal in projects container
export const PROJECT_ADD = "PROJECT_ADD";
export const PROJECT_NOT_ADD = "PROJECT_NOT_ADD";
export const USER_DATA_CLEAR = "USER_DATA_CLEAR";

//used to get billing monthly spend service data
export const BILLING_MONTHLY_SPEND_FETCHING = "BILLING_MONTHLY_SPEND_FETCHING";
export const BILLING_MONTHLY_SPEND_FETCHING_SUCCESS =
  "BILLING_MONTHLY_SPEND_FETCHING_SUCCESS";
export const BILLING_MONTHLY_SPEND_FETCHING_FAILURE =
  "BILLING_MONTHLY_SPEND_FETCHING_FAILURE";

export const UPDATE_BILLING_SAVING = "UPDATE_BILLING_SAVING";
export const UPDATE_BILLING_SUCCESS = "UPDATE_BILLING_SUCCESS";
export const UPDATE_BILLING_FAILURE = "UPDATE_BILLING_FAILURE";

// used to get billing data
export const LOAD_BILLING_DATA = "LOAD_BILLING_DATA";
export const LOAD_BILLING_DATA_SUCCESS = "LOAD_BILLING_DATA_SUCCESS";
export const LOAD_BILLING_DATA_FAILURE = "LOAD_BILLING_DATA_FAILURE";

//used to create iban payment
export const IBAN_PAYMENT_SAVING = "IBAN_PAYMENT_SAVING";
export const IBAN_PAYMENT_SUCCESS = "IBAN_PAYMENT_SUCCESS";
export const IBAN_PAYMENT_FAILURE = "IBAN_PAYMENT_FAILURE";

//used to get iban details
export const IBAN_DETAILS_FETCHING = "IBAN_DETAILS_FETCHING";
export const IBAN_DETAILS_FETCHING_SUCCESS = "IBAN_DETAILS_FETCHING_SUCCESS";
export const IBAN_DETAILS_FETCHING_FAILURE = "IBAN_DETAILS_FETCHING_FAILURE";

//test generic events
export const TEST_EVENTS_FAILURE = "TEST_EVENTS_FAILURE";
export const TEST_EVENTS_SAVING = "TEST_EVENTS_SAVING";
export const TEST_EVENTS_SUCCESS = "TEST_EVENTS_SUCCESS";

//test webhook events
export const TEST_WEBHOOK_EVENTS_FAILURE = "TEST_WEBHOOK_EVENTS_FAILURE";
export const TEST_WEBHOOK_EVENTS_SAVING = "TEST_WEBHOOK_EVENTS_SAVING";
export const TEST_WEBHOOK_EVENTS_SUCCESS = "TEST_WEBHOOK_EVENTS_SUCCESS";

//test slack events
export const TEST_SLACK_EVENTS_FAILURE = "TEST_SLACK_EVENTS_FAILURE";
export const TEST_SLACK_EVENTS_SAVING = "TEST_SLACK_EVENTS_SAVING";
export const TEST_SLACK_EVENTS_SUCCESS = "TEST_SLACK_EVENTS_SUCCESS";

//verify coupon events
export const COUPON_CODE_FETCHING_FAILURE = "COUPON_CODE_FETCHING_FAILURE";
export const COUPON_CODE_FETCHING = "COUPON_CODE_FETCHING";
export const COUPON_CODE_FETCHING_SUCCESS = "COUPON_CODE_FETCHING_SUCCESS";

//remove coupon events
export const REMOVE_COUPON_CODE_FETCHING_FAILURE =
  "REMOVE_COUPON_CODE_FETCHING_FAILURE";
export const REMOVE_COUPON_CODE_FETCHING = "REMOVE_COUPON_CODE_FETCHING";
export const REMOVE_COUPON_CODE_FETCHING_SUCCESS =
  "REMOVE_COUPON_CODE_FETCHING_SUCCESS";

//create user events
export const CREATE_USER_FAILURE = "CREATE_USER_FAILURE";
export const CREATE_USER_FETCHING = "CREATE_USER_FETCHING";
export const CREATE_USER_SUCCESS = "CREATE_USER_SUCCESS";

//get create user events
export const GET_CREATED_USERS_FETCHING_FAILURE =
  "GET_CREATED_USERS_FETCHING_FAILURE";
export const GET_CREATED_USERS_FETCHING = "GET_CREATED_USERS_FETCHING";
export const GET_CREATED_USERS_FETCHING_SUCCESS =
  "GET_CREATED_USERS_FETCHING_SUCCESS";

//get user location data events
export const SDK_USERS_LOCATION_DATA_FETCHING_FAILURE =
  "SDK_USERS_LOCATION_DATA_FETCHING_FAILURE";
export const SDK_USERS_LOCATION_DATA_FETCHING =
  "SDK_USERS_LOCATION_DATA_FETCHING";
export const SDK_USERS_LOCATION_DATA_FETCHING_SUCCESS =
  "SDK_USERS_LOCATION_DATA_FETCHING_SUCCESS";

//delete user
export const DELETE_USER_FAILURE = "DELETE_USER_FAILURE";
export const DELETE_USER_FETCHING = "DELETE_USER_FETCHING";
export const DELETE_USER_SUCCESS = "DELETE_USER_SUCCESS";

//search user
export const SEARCH_USER_FAILURE = "SEARCH_USER_FAILURE";
export const SEARCH_USER_FETCHING = "SEARCH_USER_FETCHING";
export const SEARCH_USER_SUCCESS = "SEARCH_USER_SUCCESS";

//get active users
export const GET_ACTIVE_USERS_FAILURE = "GET_ACTIVE_USERS_FAILURE";
export const GET_ACTIVE_USERS_FETCHING = "GET_ACTIVE_USERS_FETCHING";
export const GET_ACTIVE_USERS_SUCCESS = "GET_ACTIVE_USERS_SUCCESS";

//get user locations
export const GET_USER_LOCATIONS_FAILURE = "GET_USER_LOCATIONS_FAILURE";
export const GET_USER_LOCATIONS_FETCHING = "GET_USER_LOCATIONS_FETCHING";
export const GET_USER_LOCATIONS_SUCCESS = "GET_USER_LOCATIONS_SUCCESS";
