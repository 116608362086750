import * as types from "../actions/actionTypes";

const initialState = {
  account_fetching: false,
  account_details: null,
  account_fetching_error: null,
  account_saving: false,
  account_saving_error: null,
  location_hits_saving: false,
  location_hits_data: null,
  location_hits_error: null,
  plan_type: null,
  insight_data: null,
  billing_details_add: false,
  billing_data_saving: false,
  billing_saving_error: null,
  billing_data: null,
  client_settings_error: null,
  plan_saving_error: null
};

export default function myAccountReducer(state = initialState, action) {
  switch (action.type) {
    case types.ACCOUNT_DATA_FETCHING:
      return Object.assign({}, state, {
        account_fetching: true
      });
    case types.ACCOUNT_DATA_FETCHING_SUCCESS:
      let store_data = {
        account_fetching: false,
        account_details: action.data
      };
      if (action.data.hasOwnProperty("plan_type")) {
        store_data.plan_type = action.data.plan_type;
      }
      // if (action.data.hasOwnProperty("account_type")) {
      //   store_data.plan_type = action.data.account_type;
      // }
      return Object.assign({}, state, store_data);
    case types.ACCOUNT_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        account_fetching: false,
        account_fetching_error: action.error
      });
    case types.ACCOUNT_DATA_SAVING:
      return Object.assign({}, state, {
        account_saving: true
      });
    case types.ACCOUNT_DATA_SAVING_SUCCESS:
      store_data = {
        account_saving: false,
        account_details: action.data
      };
      if (action.data.hasOwnProperty("plan_type")) {
        store_data.plan_type = action.data.plan_type;
      }
      // if (action.data.hasOwnProperty("account_type")) {
      //   store_data.plan_type = action.data.account_type;
      // }
      return Object.assign({}, state, store_data, {
        billing_details_add: true
      });
    case types.ACCOUNT_DATA_SAVING_FAILURE:
      return Object.assign({}, state, {
        account_saving: false,
        account_saving_error: action.error
      });
    case types.ACCOUNT_PLAN_UPDATE:
      return Object.assign({}, state, {
        account_saving: true
      });
    case types.ACCOUNT_PLAN_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        account_saving: false,
        plan_type: action.data.account_type
      });
    case types.ACCOUNT_PLAN_UPDATE_FAILURE:
      return Object.assign({}, state, {
        account_saving: false,
        account_saving_error: action.error,
        plan_saving_error: action.data.response.data
      });
    case types.ACCOUNT_ADD_MEMBER:
      return Object.assign({}, state, {
        account_details: state.account_details,
        account_fetching: true
      });

    case types.ACCOUNT_ADD_MEMBER_SUCCESS:
      let new_account_data = state.account_details.members.concat(action.data);
      const account_data = state.account_details;
      account_data.members = new_account_data;
      return Object.assign({}, state, {
        account_details: account_data,
        account_fetching: false
      });

    case types.ACCOUNT_ADD_MEMBER_FAILURE:
      return Object.assign({}, state, {
        account_fetching: false
      });

    case types.ACCOUNT_EDIT_MEMBER_SUCCESS:
      function search(members, data_email) {
        for (let i = 0; i < members.length; i++) {
          if (members[i].email === data_email) {
            return i;
          }
        }
        return null;
      }
      let ObjectIndex = search(
        state.account_details.members,
        action.data.email
      );

      if (ObjectIndex != null) {
        state.account_details.members[ObjectIndex] = action.data;
      }
      return Object.assign({}, state, {
        account_details: state.account_details,
        account_fetching: false
      });

    case types.ACCOUNT_EDIT_MEMBER:
      return Object.assign({}, state, {
        account_details: state.account_details,
        account_fetching: true
      });

    case types.ACCOUNT_DELETE_MEMBER_SUCCESS:
      function searchId(members, id) {
        for (let i = 0; i < members.length; i++) {
          if (members[i].id === id) {
            return i;
          }
        }
        return null;
      }
      let ObjectIndx = searchId(state.account_details.members, action.data.id);
      if (ObjectIndx != null) {
        state.account_details.members.splice(ObjectIndx, 1);
      }
      return Object.assign({}, state, {
        account_details: state.account_details,
        account_fetching: false
      });

    case types.ACCOUNT_DELETE_MEMBER:
      return Object.assign({}, state, {
        account_details: state.account_details,
        account_fetching: true
      });

    case types.LOAD_INSIGHT_DATA_SUCCESS:
      return Object.assign({}, state, {
        account_saving: false,
        insight_data: action.data.account.count
      });
    case types.LOAD_INSIGHT_DATA_FAILURE:
      return Object.assign({}, state, {
        account_saving: false,
        account_saving_error: action.error
      });
    case types.LOAD_INSIGHT_DATA:
      return Object.assign({}, state, {
        account_saving: true
      });
    case types.HEATMAP_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        location_hits_saving: false,
        location_hits_data: action.data
      });
    case types.HEATMAP_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        location_hits_saving: false,
        location_hits_error: action.error
      });
    case types.HEATMAP_DATA_FETCHING:
      return Object.assign({}, state, {
        location_hits_saving: true
      });

    case types.LOAD_BILLING_DATA_SUCCESS:
      return Object.assign({}, state, {
        billing_data_saving: false,
        billing_data: action.data
      });
    case types.LOAD_BILLING_DATA_FAILURE:
      return Object.assign({}, state, {
        billing_data_saving: false,
        billing_saving_error: action.error,
        client_settings_error: action.data
      });
    case types.LOAD_BILLING_DATA:
      return Object.assign({}, state, {
        billing_data_saving: true
      });
    default:
      return state;
  }
}
