import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./androidSDKIntegrationSteps.scss";
import InstallSdk from "../AndroidSDKDocs/InstallSDK";
import SetDeviceToken from "../AndroidSDKDocs/SetDeviceToken";
import InitializedSDK from "../AndroidSDKDocs/InitializedSDK";
import CreateUser from "../AndroidSDKDocs/CreateUser";
import StartTracking from "../AndroidSDKDocs/StartTracking";

const AndroidSDKIntegrationSteps = ({
  validPackageName,
  handleChange,
  docsStep,
  onGoBack,
  onGoNext,
  projectName,
  publish_key
}) => {
  return (
    <div className="android-ask-component">
      <div className="">
        <h3>
          Integrate the Android SDK for <br />
          <span>{projectName}</span>
        </h3>
        {docsStep === 0 ? (
          <InstallSdk onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 1 ? (
          <InitializedSDK
            onGoBack={onGoBack}
            onGoNext={onGoNext}
            publish_key={publish_key}
          />
        ) : docsStep === 2 ? (
          <CreateUser onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 3 ? (
          <StartTracking onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : (
          <InstallSdk onGoBack={onGoBack} onGoNext={onGoNext} />
        )}
      </div>
    </div>
  );
};

AndroidSDKIntegrationSteps.displayName = "AndroidSDKIntegrationSteps";
AndroidSDKIntegrationSteps.propTypes = {};
AndroidSDKIntegrationSteps.defaultProps = {};

export default Cssmodules(AndroidSDKIntegrationSteps, styles);
