import React from "react";
import Cssmodules from "react-css-modules";

import ReactNativeTitleProgressBar from "../ReactNativeSDKMethod/ReactNativeTitleProgressBar";

import styles from "./reactNativeSDKDocs.scss";
import Button from "../../../../common/Button";

const RNSDKDocsBox = ({
  children,
  introduction,
  className,
  Button1Label,
  Button2Label,
  onClickOnButton1,
  onClickOnButton2
}) => {
  return (
    <div className="rn-component">
      <div>
        <ReactNativeTitleProgressBar className={className} />
        <div className="integrate-box">
          <span className="gs-intro">{introduction}</span>
          {children}
        </div>
        <Button className="gs-secondary" onClick={e => onClickOnButton1(e)}>
          {Button1Label}
        </Button>
        <Button className="gs-primary" onClick={e => onClickOnButton2(e)}>
          {Button2Label}
        </Button>
      </div>
    </div>
  );
};

RNSDKDocsBox.displayName = "RNSDKDocsBox";
RNSDKDocsBox.propTypes = {};
RNSDKDocsBox.defaultProps = {};

export default Cssmodules(RNSDKDocsBox, styles);
