import React from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";

import Button from "./Button";
import styles from "./titleButtonBox.scss";
import commonStyle from "../common/commonStyle";

const TitleButtonBoxComponent = ({
  title,
  subTitle,
  children,
  buttonLabel1,
  buttonLabel2,
  onButton1Click,
  onButton2Click,
  onEnter,
  resetPasswordStatus
}) => {
  return (
    <div className="box-component">
      <h3>{title}</h3>
      <h4>{subTitle}</h4>
      <div>{children}</div>
      {buttonLabel1 && (
        <Button
          className="gs-primary prelogin-button-fill"
          style={commonStyle.preLoginBtnStyles}
          label={buttonLabel1}
          onClick={(e) => onButton1Click(e)}
          onEnter={(e) => onEnter(e)}
        />
      )}

      {buttonLabel2 && (
        <React.Fragment>
          <h2 className="divider">
            <span>or</span>
          </h2>
          <Button
            className="gs-secondary prelogin-button"
            style={commonStyle.preLoginBtnStyles}
            label={buttonLabel2}
            onClick={(e) => onButton2Click(e)}
          />
        </React.Fragment>
      )}
    </div>
  );
};

TitleButtonBoxComponent.displayName = "TitleButtonBoxComponent";
TitleButtonBoxComponent.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  label: PropTypes.string,
  buttonLabel: PropTypes.string
};
TitleButtonBoxComponent.defaultProps = {
  buttonLabel1: null,
  buttonLabel2: null
};

export default Cssmodules(TitleButtonBoxComponent, styles);
