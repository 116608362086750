import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import IOSSDKCodeType from "./IOSSDKCodeType";

import Button from "../../../../common/Button";
import IOSSDKDocsBox from "./SDKDocsBox";

import styles from "./iOSSDKDocs.scss";

const StartTracking = ({
  docsStep,
  onGoBack,
  onGoNext,
  onIOSTypeChange,
  iOSType
}) => {
  const codeString1 = `// To enable motion and location, call requestMotion and requestLocation methods.
if Roam.isLocationEnabled() == false{
  Roam.requestLocation()
}else {
  Roam.startTracking()
}`;
  const codeString2 = `if ([Roam isLocationEnabled] == false){
  [Roam requestLocation];
}else {
  [Roam startTracking];
}`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          introduction="To start tracking the location."
          className="fifth-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <IOSSDKCodeType
              codeString1={codeString1}
              codeString2={codeString2}
              onIOSTypeChange={onIOSTypeChange}
              iOSType={iOSType}
            />
            <CopyToClipboard
              text={
                iOSType === "Swift" ? codeString1.trim() : codeString2.trim()
              }
            >
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

StartTracking.displayName = "StartTracking";
StartTracking.propTypes = {};
StartTracking.defaultProps = {};

export default Cssmodules(StartTracking, styles);
