import React from "react";
import "../common/simpleLoader.scss";
const Simpleloader = props => {
  return (
    <div>
      <p className="loadertitle">{props.loaderTitle}</p>
      <div className="loader" />
    </div>
  );
};

export default Simpleloader;
