import React, { Component } from "react";
import { connect } from "react-redux";
import Notifications from "react-notification-system-redux";
import { bindActionCreators } from "redux";
import notificationsActions from "../../actions/common/notifications";
export class MainContainer extends Component {
  // async componentWillMount() {
  //   const token = await localStorage.getItem("playground_token");
  //   if (token) {
  //     history.push("/create-project");
  //   }
  // }
  render() {
    const { notifications } = this.props;
    let notification = [];
    if (notifications.length > 1) {
      notification.push(notifications[notifications.length - 1]);
    } else {
      notification = notifications;
    }
    const style = {
      Containers: { DefaultStyle: { zIndex: 11000 } }
    };
    return (
      <div className={this.props.class}>
        {this.props.children}
        <Notifications notifications={notification} style={style} />
      </div>
    );
  }
}

function mapStateToProps(state) {
  const props = {
    notifications: state.notifications
  };
  return props;
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...notificationsActions
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MainContainer);
