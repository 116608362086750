import React from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";
import { Form } from "react-bootstrap";

import styles from "./projectNameForm.scss";
import FieldGroup from "../../common/InputBox";
import { FaFileAlt } from "react-icons/fa";
import { ExtraShortNameErrorHelp} from "../../common/commonMessage/errorMessages";
import Button from "../../common/Button";
import { IconContext } from "react-icons";
import { IoMdListBox } from "react-icons/io";

const ProjectNameForm = ({
  handleChange,
  onSubmit,
  keyPress,
  validProjectName,
  onChangeRoute,
  onCreateProject,
  disabled,
  showFirstLabel,
  manualIgniteStatus,
}) => {
  return (
    <div>
      <div>
        <h3>
          Create your{" "}
          {showFirstLabel && !manualIgniteStatus ? <span>first</span> : ""}{" "}
          project
        </h3>
        <h6>One place to manage and visualize your location enabled apps.</h6>
      </div>

      <Form onKeyDown={keyPress}>
        <div className="project-card">
          <div className="pre-textbox">
            <FieldGroup
              id="ProjectName"
              label="Project Name*"
              name="projectName"
              type="text"
              placeholder="enter project name"
              onChange={e => handleChange(e)}
              help={
                validProjectName > 0
                  ? ExtraShortNameErrorHelp[validProjectName - 1]
                  : null
              }
              icon={
                <IconContext.Provider
                  value={{
                    className: validProjectName !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <IoMdListBox />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
        </div>
        {!manualIgniteStatus ? (
          <div className="gs-project-button">
         <Button
            className={
              validProjectName !== 0 ? "gs-btn gs-disabled" : "gs-primary"
            }
            disabled={validProjectName !== 0}
            label="Create Project"
            onClick={e => onCreateProject(e, "manual")}
          />
            
          </div>
        ) : (
          ""
        )}
      </Form>
    </div>
  );
};

ProjectNameForm.displayName = "ProjectNameForm";
ProjectNameForm.propTypes = {
  handleChange: PropTypes.func,
  showFirstLabel: false
};
ProjectNameForm.defaultProps = {};

export default Cssmodules(ProjectNameForm, styles);
