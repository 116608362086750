import React from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";

import styles from "./signUp.scss";
import FieldGroup from "../common/InputBox";
import CustomDropdown from "../common/CustomSelect";
import { Form } from "react-bootstrap";
import { IconContext } from "react-icons";
import { FaUser, FaBuilding, FaUsers } from "react-icons/fa";
import { MdLock, MdEmail, MdDomain } from "react-icons/md";
import { IoMdPricetag } from "react-icons/io";

import {
  ShortNameErrorHelp,
  EmailErrorHelp,
  PasswordErrorHelp
} from "../common/commonMessage/errorMessages";
import { IndustryType } from "../common/commonMessage/dataType";


const SignUp = ({
  handleChange,
  onSubmit,
  keyPress,
  validCompanyName,
  validPromoCode,
  validFullName,
  validEmail,
  validPassword,
  onChangeRoute,
  industry
}) => {
  return (
    <div className="signup-component">
      <Form onKeyDown={keyPress}>
        <div className="prelogin-card">
          <div className="pre-textbox">
            <FieldGroup
              id="SFullName"
              label="Full Name*"
              name="full_name"
              type="text"
              placeholder="enter your full name"
              onChange={e => handleChange(e)}
              help={
                validFullName > 0 ? ShortNameErrorHelp[validFullName - 1] : null
              }
              // icon={<FaUser />}
              icon={
                <IconContext.Provider
                  value={{
                    className: validFullName !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <FaUser />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
          <div className="pre-textbox">
            <FieldGroup
              id="SEmail"
              label="Email*"
              name="email"
              type="text"
              placeholder="enter your email"
              onChange={e => handleChange(e)}
              help={validEmail > 0 ? EmailErrorHelp[validEmail - 1] : null}
              // icon={<MdEmail className="gs-filled" />}
              icon={
                <IconContext.Provider
                  value={{
                    className: validEmail !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <MdEmail />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
          <div className="pre-textbox">
            <FieldGroup
              id="fullPasswordText"
              label="Password*"
              name="password"
              type="password"
              placeholder="enter your password"
              onChange={e => handleChange(e)}
              help={
                validPassword > 0 ? PasswordErrorHelp[validPassword - 1] : null
              }
              icon={
                <IconContext.Provider
                  value={{
                    className: validPassword !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <MdLock />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
          <div className="pre-textbox">
            <FieldGroup
              id="SCompanyName"
              label="Company Name*"
              name="company_name"
              type="text"
              placeholder="enter your company name"
              onChange={e => handleChange(e)}
              help={
                validCompanyName > 0
                  ? ShortNameErrorHelp[validCompanyName - 1]
                  : null
              }
              icon={
                <IconContext.Provider
                  value={{
                    className: validCompanyName !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <MdDomain />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
          <div className="pre-textbox">
            <FieldGroup
              id="SPromoCode"
              label="Promo Code (optional)"
              name="promo_code"
              type="text"
              placeholder="enter optional promo code"
              onChange={e => handleChange(e)}
              help={
                validPromoCode > 0
                  ? ShortNameErrorHelp[validPromoCode - 1]
                  : null
              }
              icon={
                <IconContext.Provider
                  value={{
                    className: validPromoCode !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <IoMdPricetag />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
          <CustomDropdown
              placeholder="Select industry"
              onChange={handleChange}
              industry={industry}
              items={IndustryType}
            />
        </div>
        {/* <div className="gs-signup">
          <div className="forgotpass">
            <a href="/" onClick={e => onChangeRoute(e, "/forgot-password")}>
              Forgot Password?
            </a>
          </div>
        </div> */}
      </Form>
    </div>
  );
};

SignUp.displayName = "SignUp";
SignUp.propTypes = {
  handleChange: PropTypes.func
};
SignUp.defaultProps = {};

export default Cssmodules(SignUp, styles);
