import React from "react";
import Cssmodules from "react-css-modules";
import CodeInputBox from "./CodeInput/CodeInputBox";
// import line from "../../public/images/icons-line.png";

import Button from "../common/Button";
import styles from "./emailInputCode.scss";

const EmailInputCodeComponent = ({
  email,
  code1,
  onIncorrectEmail,
  onChangeCode,
  onSubmitEmailCode,
  onChangeRoute,
  onResendCode,
  validEmailCode,
  onEnterCode
}) => {
  return (
    <div className="code-input-component">
      <div>
        <div className="gs-code-box">
          <span>
            <h1 className="gs-code-title">
              Enter your confirmation code sent to your email.
            </h1>
          </span>
        </div>
        <div className="gs-code-box gs-code-email">
          <h1 className="gs-code-title">
            "<span className="gs-email">{email}</span>"
          </h1>
        </div>
        <div
          className={
            validEmailCode === 2
              ? "gs-code-box gs-code-input gs-code-box-error"
              : "gs-code-box gs-code-input"
          }
        >
          <CodeInputBox
            type="text"
            fields={6}
            // isValid={false}
            autoFocus={true}
            onChange={e => onChangeCode("code1", e)}
          />
        </div>
        <div className="gs-code-box">
          <Button
            className={
              validEmailCode === 0 ? "gs-btn gs-primary" : "gs-btn gs-disabled"
            }
            label="Confirm"
            onClick={e => onSubmitEmailCode(e)}
            onEnterCode={e => onEnterCode(e)}
          />
        </div>
        <div className="gs-code-box">
          <a href="/" onClick={e => onResendCode(e)}>
            Resend confirmation code
          </a>
        </div>
        <div className="gs-code-box">
          <a href="/" onClick={e => onIncorrectEmail(e)}>
            Incorrect email?
          </a>
        </div>
      </div>
    </div>
  );
};

EmailInputCodeComponent.displayName = "EmailInputCodeComponent";
EmailInputCodeComponent.propTypes = {};
EmailInputCodeComponent.defaultProps = {};

export default Cssmodules(EmailInputCodeComponent, styles);
