import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./integrateSDK.scss";
import Button from "../../common/Button";
import IntegrateSDKTypes from "./IntegrateSDKTypes";

const IntegrateSDK = ({
  onClickOnSDK,
  onGoBack,
  onSkip,
  projectName,
  SDKType,
  onCreateApp,
  handleChange,
  onSkipIntegration
}) => {
  return (
    <div className="integrate-sdk-component">
      <p>
        <IntegrateSDKTypes
          onClickOnSDK={onClickOnSDK}
          projectName={projectName}
        />
        <span className="gs-post-span">
          Not the tech guy? Go back and try demo app or skip for now.
        </span>
        <section className="gs-post-buttons">
        <Button
            className="gs-btn gs-primary"
            label="Go Back"
            onClick={e => onGoBack(e)}
          />
          <Button
            className="gs-btn gs-secondary"
            label="Skip"
            onClick={e => onSkipIntegration(e)}
          />
        </section>
      </p>
    </div>
  );
};

IntegrateSDK.displayName = "IntegrateSDK";
IntegrateSDK.propTypes = {};
IntegrateSDK.defaultProps = {};

export default Cssmodules(IntegrateSDK, styles);
