import React from "react";
import Cssmodules from "react-css-modules";

import AndroidTitleProgressBar from "../AndroidSDKMethod/AndroidTitleProgressBar";

import styles from "./androidSDKDocs.scss";
import Button from "../../../../common/Button";

const AndroidSDKDocsBox = ({
  children,
  introduction,
  className,
  Button1Label,
  Button2Label,
  onClickOnButton1,
  onClickOnButton2
}) => {
  return (
    <div className="gs-code-component">
      <div>
        <AndroidTitleProgressBar className={className} />
        <div className="gs-code-section">
          <span className="gs-intro">{introduction}</span>
          {children}
        </div>
        <div className="gs-code-btn">
          <Button className="gs-secondary" onClick={e => onClickOnButton1(e)}>
            {Button1Label}
          </Button>
          <Button className="gs-primary" onClick={e => onClickOnButton2(e)}>
            {Button2Label}
          </Button>
        </div>
      </div>
    </div>
  );
};

AndroidSDKDocsBox.displayName = "AndroidSDKDocsBox";
AndroidSDKDocsBox.propTypes = {};
AndroidSDKDocsBox.defaultProps = {};

export default Cssmodules(AndroidSDKDocsBox, styles);
