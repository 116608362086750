import * as types from "../actions/actionTypes";

const initialState = {
  events_details: null,
  events_fetching: false,
  events_fetching_error: null,
  event_pages: null,
  current_project_id: null,
  geofence_details: null
};

export default function eventReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_EVENTS_DATA_FETCHING:
      let data = {
        events_fetching: true,
        current_project_id: action.data.data.project_id
      };

      if (action.data.data.project_id !== state.current_project_id) {
        data.events_details = null;
        data.event_pages = null;
        data.geofence_details = null;
      }

      return Object.assign({}, state, data);
    case types.PROJECT_EVENTS_DATA_FETCHING_SUCCESS:
      let new_events = state.events_details;
      if (state.events_details && action.data.prev_page !== null) {
        new_events = new_events.concat(action.data.events);
      } else {
        new_events = action.data.events;
      }

      return Object.assign({}, state, {
        events_fetching: false,
        events_details: new_events,
        event_pages: action.data.next_page
      });

    case types.PROJECT_EVENTS_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        events_fetching: false,
        events_fetching_error: action.error
      });

    case types.GEOFENCE_DATA_FETCHING:
      return Object.assign({}, state, {
        events_fetching: true
      });

    case types.GEOFENCE_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        events_fetching: false,
        geofence_details: action.data.geofences[0]
      });

    case types.GEOFENCE_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        events_fetching: false,
        events_fetching_error: action.error
      });

    default:
      return state;
  }
}
