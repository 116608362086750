import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighter from "react-syntax-highlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";

import styles from "./syntaxHighlighter.scss";

const SyntaxHighlighterComponent = ({ language, codeString }) => {
  return (
    <SyntaxHighlighter
      showLineNumbers={true}
      language={language}
      style={github}
    >
      {codeString}
    </SyntaxHighlighter>
  );
};

SyntaxHighlighterComponent.displayName = "SyntaxHighlighterComponent";
SyntaxHighlighterComponent.propTypes = {};
SyntaxHighlighterComponent.defaultProps = {
  language: "java"
};

export default Cssmodules(SyntaxHighlighterComponent, styles);
