const commonLabel = {
  color: "#495fc3",
  opacity: 0.9,
  fontSize: 14,
  top: -15,
  fontWeight: 400,
  left: 0,
  marginBottom: 5
};

const commonCompTitle = {
  width: "auto",
  float: "left",
  color: "#495fc3",
  fontSize: 20,
  fontWeight: 500
};

const commonGreyLabel = {
  color: "#b7b7b7"
};

const commonProjectBox = {
  position: "absolute",
  opacity: 1,
  zIndex: 1000,
  backgroundColor: "white",
  width: "576px",
  margin: "30px 60px",
  padding: "20px 20px 20px 30px",
  // overflowY: "auto",
  maxHeight: "93%",
  borderRadius: "7px",
  boxShadow: "rgba(137, 134, 134, 0.1) 4px 5px 8px 5px",
  overflow: "hidden"
};

const commonFilterbtn = {
  // position: "relative",
  top: "-1.5em",
  // left: "95%",
  float: "right",
  backgroundColor: "#f2f2f2",
  width: "26px",
  height: "26px",
  padding: "8px",
  borderRadius: "20px",
  cursor: "pointer",
  marginRight: "10px"
};

const commonHR = {
  marginInlineStart: "-1.88em",
  marginInlineEnd: "-1.2em",
  borderTop: "1.5px solid rgba(0, 0, 0, 0.1) !important",
  marginBlockStart: "15px",
  marginBlockEnd: "0",
  marginBottom: "0"
};

const commonToggleBtn = {
  position: "relative",
  marginTop: "-4%",
  left: "95%",
  backgroundColor: "#f2f2f2",
  width: "32px",
  height: "32px",
  padding: "4px",
  borderRadius: "31px",
  color: "rgb(217, 217, 217)",
  cursor: "pointer"
};

const commonMarginSvg = {
  marginRight: "5px !important"
};

const noData = {
  color: "#a5a5a5",
  fontSize: "14px",
  marginLeft: "2px"
};

const refreshData = {
  backgroundColor: "#045EC8",
  color: "#fff",
  borderRadius: "15px",
  padding: "8px",
  width: "26px",
  height: "26px",
  marginLeft: "1em",
  marginBottom: "1em"
};

const updatedData = {
  background: "#ffffff",
  boxShadow: "-2px -1px 34px 7px rgba(0, 0, 0, 0.05)",
  borderRadius: "5px",
  padding: "20px 25px 20px 21px",
  borderBottom: "1px solid #f2f2f2",
  width: "99% !important",
  marginTop: "1em",
  fontSize: "14px"
};
const updatedSvg = {
  fontSize: "26px",
  color: "#484848",
  backgroundColor: "#f2f2f2",
  padding: "3px",
  borderRadius: "100%",
  float: "right"
};

const commonTimeLabel = {
  color: "#9c9c9c",
  fontSize: "12px"
};

const newButtonStyles = {
  width: "75px",
  background: "#fff",
  height: "30px",
  fontSize: "12px",
  letterSpacing: "0.12px",
  borderRadius: "3px",
  cursor: "pointer"
};

const closeButtonStyle = {
  width: "75px",
  border: "1px solid #EBEEF0",
  background: "#fff",
  color: "#787884",
  height: "30px",
  fontSize: "12px",
  letterSpacing: "0.12px",
  color: "#E02020"
};

const closeButtonStyleHover = {
  borderColor: "#E02020 !important"
};

const SectionTitle = {
  fontSize: "14px",
  letterSpacing: "0.14px",
  fontWeight: "700",
  marginBottom: "0",
  margin: "25px 0"
};
const SectionSubTitle = {
  fontSize: "14px",
  letterSpacing: "0.14px",
  fontWeight: "500",
  marginBottom: "0"
};

const InfoBtn = {
  width: "14px",
  marginLeft: "5px",
  height: "auto",
  display: "none"
};

const preLoginBtnStyles = {
  width: "32em",
  fontWeight: "500"
};

const linkColor = {
  color: "#045ec8"
};
export default {
  commonLabel,
  commonCompTitle,
  commonGreyLabel,
  commonProjectBox,
  commonFilterbtn,
  commonHR,
  commonToggleBtn,
  commonMarginSvg,
  noData,
  refreshData,
  updatedData,
  updatedSvg,
  commonTimeLabel,
  newButtonStyles,
  closeButtonStyle,
  SectionTitle,
  SectionSubTitle,
  InfoBtn,
  closeButtonStyleHover,
  preLoginBtnStyles,
  linkColor
};
