import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { FaRegCopy } from "react-icons/fa";
import IOSSDKDocsBox from "./SDKDocsBox";
import IOSSDKCodeType from "./IOSSDKCodeType";

import Button from "../../../../common/Button";

import styles from "./iOSSDKDocs.scss";

const InstallSdk = ({
  docsStep,
  onGoBack,
  onGoNext,
  iOSType,
  onIOSTypeChange
}) => {
  const codeString = `
  pod 'roam-ios'
  `;
  const codeString1 = `
  import Roam
  
  `;
  const codeString2 = `
  #import <Roam/Roam.h>

  `;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          className="first-step"
          introduction="Install using Cocoapods, open podfile add SDK to file."
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString.trim()}
            />
            <CopyToClipboard text={codeString.trim()}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
          <span className="gs-span">
            Once you have updated your Podfile run pod install in your terminal.
            <br />
            Import Roam into your AppDelegate file.
          </span>

          <div className="gs-codesection">
            <IOSSDKCodeType
              codeString1={codeString1.trim()}
              codeString2={codeString2.trim()}
              onIOSTypeChange={onIOSTypeChange}
              iOSType={iOSType}
            />

            <CopyToClipboard
              text={
                iOSType === "Swift" ? codeString1.trim() : codeString2.trim()
              }
            >
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>

          {/* <CopyToClipboard text={codeString1}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard> */}
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

InstallSdk.displayName = "InstallSdk";
InstallSdk.propTypes = {};
InstallSdk.defaultProps = {};

export default Cssmodules(InstallSdk, styles);
