import { combineReducers } from "redux";
import projectsReducer from "./projectsReducer";
import accountReducer from "./accountReducer";
import profileReducer from "./profileReducer";
import billingReducer from "./billingReducer";
import usersReducer from "./usersReducer";
import tripReducer from "./tripReducer";
import geofenceReducer from "./geofenceReducer";
import eventReducer from "./eventReducer";
import campaignReducer from "./campaignReducer";
import { reducer as notifications } from "react-notification-system-redux";

const rootReducer = combineReducers({
  notifications,
  projectsReducer,
  profileReducer,
  accountReducer,
  billingReducer,
  usersReducer,
  tripReducer,
  geofenceReducer,
  eventReducer,
  campaignReducer
});

export default rootReducer;
