import React from "react";
import Cssmodules from "react-css-modules";
// import { CopyToClipboard } from "react-copy-to-clipboard";
// import { FaRegCopy } from "react-icons/fa";

// import Button from "../../../../common/Button";
import ReactNativeSDKDocsBox from "./SDKDocsBox";
import SDKType from "./SDKType";
import InitializeIOS from "./InitializeIOS";
import InitializeAndroid from "./InitializeAndroid";
import styles from "./reactNativeSDKDocs.scss";

const InitializeSDK = ({ docsStep, onGoBack, onGoNext }) => {
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <ReactNativeSDKDocsBox
          introduction="Initialize the SDK in your AppDelegate class before calling any other Roam methods under this application:didFinishLaunchingWithOptions:"
          className="third-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <SDKType
            component1={<InitializeIOS />}
            component2={<InitializeAndroid />}
          />
        </ReactNativeSDKDocsBox>
      </div>
    </div>
  );
};

InitializeSDK.displayName = "InitializeSDK";
InitializeSDK.propTypes = {};
InitializeSDK.defaultProps = {};

export default Cssmodules(InitializeSDK, styles);
