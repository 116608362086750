export const UserType = ["Admin", "Developer", "Viewer"];
export const SavedUserTypes = ["Owner", "Admin", "Developer", "Viewer"];
export const ApnsType = ["Test", "Production"];
export const IndustryType = [
    {
        name: "Delivery",
        value: "delivery"
    },
    {
        name: "Ride Hailing",
        value: "ride-hailing"
    },
    {
        name: "Fitness",
        value: "fitness"
    },
    {
        name: "Retail",
        value: "retail"
    },
    {
        name: "User Tracking",
        value: "user-tracking"
    }
];
