import React from "react";
import Cssmodules from "react-css-modules";
import moment from "moment";
import { Map, Marker, Tooltip, Popup, ZoomControl } from "react-leaflet";
import { IconContext } from "react-icons";
import { Row, Col } from "react-bootstrap";
import {
  FaBatteryEmpty,
  FaBatteryQuarter,
  FaBatteryHalf,
  FaBatteryThreeQuarters,
  FaBatteryFull
} from "react-icons/fa";

// import { GoogleMutant } from "react-leaflet-googlemutant";
import GoogleMutant from "../../containers/common/googlemutant";
import L from "leaflet";

import commonMethod from "../../containers/common/commonMethod";
import styles from "./mapComponent.scss";
import endtripiconPng from "../../public/images/marker.png";
let position = [51.505, -0.09];

let endtripicon = L.icon({
  iconRetinaUrl: endtripiconPng,
  iconUrl: endtripiconPng,
  iconSize: [34],
  iconAnchor: [24, 48]
});

const TimeDifference = (recorded_at) => {
  const now = moment();
  let time_format = "";
  let total_time = Math.abs(
    Math.floor(
      moment
        .duration(now.diff(moment.utc(recorded_at).local().format()))
        .asMinutes()
    )
  );
  if (total_time > 1440) {
    const total_days = Math.floor(total_time / 1440);
    total_time = total_time % 1440;
    time_format = String(total_days) + "days ";
  }

  if (total_time > 60) {
    const total_hrs = Math.floor(total_time / 60);
    total_time = total_time % 60;
    // const total_mins = total_time - 60 * total_hrs;
    time_format =
      time_format + String(total_hrs) + "hrs " + String(total_time) + "mins";
  } else {
    time_format = time_format + String(total_time) + "mins";
  }

  return time_format;
};

const MapComponent = ({ users }) => {
  if (users && users.length > 0 && users[0]?.coordinates) {
    position = [
      users[0].coordinates.coordinates[1],
      users[0].coordinates.coordinates[0]
    ];
  }
  return (
    <div className="map-component">
      <Map center={position} zoom={16} ZoomControl={false}>
        <ZoomControl position="bottomright" />
        <GoogleMutant type="roadmap" />

        {/* {users &&
          users.map((user, i) => (
            <div className="tooltip">
              {user.hasOwnProperty("coordinates") && (
                <Marker
                  ref={(circle) => {
                    if (this) {
                      this.circle = circle;
                    }
                  }}
                  id={user.coordinates.coordinates[0]}
                  position={[
                    user.coordinates.coordinates[1],
                    user.coordinates.coordinates[0],
                  ]}
                  icon={endtripicon}
                >
                  <Tooltip direction="top" permanent>
                    <div className="marker-data">
                      <div>
                        <b>User ID : </b>
                        <span>{user.user_id}</span>
                      </div>

                      {user.description && (
                        <div>
                          <b>Description : </b>
                          <span>
                            {user.description.length > 15
                              ? user.description.substring(0, 15) + "..."
                              : user.description}
                          </span>
                        </div>
                      )}
                      <div>
                        <b>Status : </b>
                        <span>Active</span>{" "}
                        {commonMethod.ActiveBeforeTime(
                          user.hasOwnProperty("recorded_at")
                            ? user.recorded_at
                            : user.hasOwnProperty("started_at")
                            ? user.started_at
                            : user.ended_at
                        )}
                        <span className="span-dark"> ago</span>
                      </div>

                      {user.hasOwnProperty("battery_remaining") && (
                        <div className="battery-status">
                          <b>Battery Status : </b>
                          <div>
                            <Row className="battery-icon">
                              <Col sm={1} lg={1} md={1}>
                                <IconContext.Provider
                                  value={{
                                    color: "#7ed321",
                                    size: "2em",
                                  }}
                                >
                                  <div className="battery-icon">
                                    {user.battery_remaining > 0 ? (
                                      user.battery_remaining >= 25 ? (
                                        user.battery_remaining >= 50 ? (
                                          user.battery_remaining >= 75 ? (
                                            <FaBatteryFull />
                                          ) : (
                                            <FaBatteryThreeQuarters />
                                          )
                                        ) : (
                                          <FaBatteryHalf />
                                        )
                                      ) : (
                                        <FaBatteryQuarter />
                                      )
                                    ) : (
                                      <FaBatteryEmpty />
                                    )}
                                  </div>
                                </IconContext.Provider>
                              </Col>
                              <Col sm={1} lg={1} md={1}>
                                <span className="battery-rem">
                                  {user.battery_remaining}%
                                </span>
                              </Col>
                            </Row>
                          </div>
                        </div>
                      )}

                      {user.hasOwnProperty("bat_stat") && (
                        <div>
                          <b>Charging Status : </b>
                          <span>{user.bat_stat}</span>
                        </div>
                      )}
                    </div>
                  </Tooltip>
                </Marker>
              )}
            </div>
          ))} */}
      </Map>
    </div>
  );
};

MapComponent.displayName = "MapComponent";
MapComponent.propTypes = {};
MapComponent.defaultProps = {};

Cssmodules(MapComponent, styles);
export default MapComponent;
