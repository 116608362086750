import React from "react";
import Cssmodules from "react-css-modules";
import IgniteLogo from "../../../public/images/ignite-small.png";
import RoamAroundLogo from "../../../public/images/roamaround-logo.svg";

import styles from "./igniteCard.scss";
import commonStyle from "../../common/commonStyle";

const IgniteCard = ({
  onChangeRoute,
  disabled,
  onCreateProject,
  manualIgniteStatus
}) => {
  let msg = "Try our demo app";
  if (manualIgniteStatus) {
    msg = "Continue";
  }
  return (
    <div className="ignite-card-component">
      <div className="gs-ignite-head">
        <h3>
          <span>RoamAround </span>
          {/* <a
            href="https://www.roam.ai/"
            target="_blank"
            rel="noopener noreferrer"
            style={commonStyle.linkColor}
          >
            roam.ai
          </a> */}
        </h3>
        <div>
          <img
            alt="ignite-logo"
            src={RoamAroundLogo}
            style={{ width: "3em", height: "auto" }}
          />
        </div>
      </div>
      <p className="gs-ignite-descp">
        <span style={{ "font-weight": "500" }}>Not sure where to start?</span>{" "}
        Try our demo app to experience location tracking and other features.
      </p>
      <a
        className={disabled ? "disabled" : "default gs-try-app"}
        // href="/project-demo"
        onClick={(e) => onCreateProject(e, "demo")}
      >
        {msg}
      </a>
    </div>
  );
};

IgniteCard.displayName = "GeoSparkIgniteCard";
IgniteCard.propTypes = {};
IgniteCard.defaultProps = {};

export default Cssmodules(IgniteCard, styles);
