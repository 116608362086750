import * as types from "../actions/actionTypes";

const initialState = {
  current_project_id: null,
  campaigns: null,
  campaigns_fetching: false,
  campaigns_fetching_error: null,
  campaign_details: null,
  active_campaign_id: null
};

function searchByCampaignID(campaigns, id) {
  for (let i = 0; i < campaigns.length; i++) {
    if (campaigns[i].id === id) {
      return i;
    }
  }
  return null;
}

function concatCampaignList(data, newData){
  if(data === null || data === undefined){
    return newData;
  }
  let tempCampaigns = data.campaigns.concat(newData.campaigns)
  data.campaigns = tempCampaigns
  return data;
}

export default function campaignReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_CAMPAIGNS_DATA_FETCHING:
      let data = {
        campaigns_fetching: true,
        current_project_id: action.data.data.project_id
      };

      if (action.data.data.project_id !== state.current_project_id) {
        data.campaigns = null;
        data.campaign_details = null;
        data.active_campaign_id = null;
      }
      return Object.assign({}, state, data);
    case types.PROJECT_CAMPAIGNS_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        campaigns_fetching: false,
        campaigns: concatCampaignList(state.campaigns, action.data)
      });

    case types.PROJECT_CAMPAIGNS_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        campaigns_fetching: false,
        campaigns_fetching_error: action.error
      });

    case types.CAMPAIGN_DATA_FETCHING:
      return Object.assign({}, state, {
        campaigns_fetching: true
      });
    case types.CAMPAIGN_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        campaigns_fetching: false,
        campaign_details: action.data
      });

    case types.CAMPAIGN_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        campaigns_fetching: false,
        campaigns_fetching_error: action.error
      });

    case types.CREATE_CAMPAIGN:
      return Object.assign({}, state, {
        campaigns_fetching: true
      });
    case types.CREATE_CAMPAIGN_SUCCESS:
      let new_campaigns = state.campaigns === null ? [] : state.campaigns;
      new_campaigns.campaigns = [action.data].concat(new_campaigns.campaigns);
      return Object.assign({}, state, {
        campaigns_fetching: false,
        campaigns: new_campaigns
      });
    case types.CREATE_CAMPAIGN_FAILURE:
      return Object.assign({}, state, {
        campaigns_fetching: false,
        campaigns_fetching_error: action.error
      });

    case types.UPDATE_CAMPAIGN:
      return Object.assign({}, state, {
        campaigns_fetching: true
      });

    case types.UPDATE_CAMPAIGN_SUCCESS:
      const ObjectIndex = searchByCampaignID(state.campaigns, action.data.id);

      if (ObjectIndex != null) {
        state.campaigns[ObjectIndex] = action.data;
      }
      return Object.assign({}, state, {
        campaigns_fetching: true,
        campaigns: {...state.campaigns}
      });

    default:
      return state;
  }
}
