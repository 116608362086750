import Notifications from "react-notification-system-redux";

function sendNotification(type, data) {
  const options = data;
  //Optional styling
  // const style = {
  //   sendNotification: {
  //     DefaultStyle: {
  //       margin: "10px 5px 2px 1px"
  //     },
  //     success: {
  //       color: "red"
  //     }
  //   }
  // };
  if (!("position" in options)) {
    options.position = "tr";
  }
  if (!("autoDismiss" in options)) {
    options.autoDismiss = 3;
  }
  return dispatch => dispatch(Notifications[type](options));
}

export default {
  sendNotification
};
