import React from "react";
import Cssmodules from "react-css-modules";
import { Navbar } from "react-bootstrap";
import { Row, Col } from "react-bootstrap";
import logo from "../../../public/images/roam-logo1.webp";
import windowUrlChecker from "./windowUrlChecker";
import Cyprinuslogo from "../../../public/images/TheCyprinus-14.png";
import styles from "./navbar.scss";

const MainNavbar = ({ onChangeRoute }) => {
  return (
    <Row className="main-header">
      <Col xs={12} sm={12} md={12} lg={12}>
        <Navbar expand="lg">
          <Navbar.Brand
          // href="/projects"
          // onClick={e => onChangeRoute(e, "/projects")}
          >
            <img
              alt="roam-logo"
              className={
                windowUrlChecker()
                  ? "main-geo-logo cyprinus-logo"
                  : "main-geo-logo"
              }
              src={windowUrlChecker() ? Cyprinuslogo : logo}
            />
          </Navbar.Brand>
        </Navbar>
      </Col>
    </Row>
  );
};

MainNavbar.displayName = "MainNavbar";

export default Cssmodules(MainNavbar, styles);
