import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import "./index.scss";
import Routes from "./routes";
import history from "./actions/common/history";
import { Provider } from "react-redux";
import configureStore from "./store/configureStore";
import registerServiceWorker from "./registerServiceWorker";
import { Router } from "react-router-dom";
import OverlaySpinner from "./components/common/OverlaySpinner";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";

Sentry.init({
  dsn:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "https://b40e89a103a24b3ca6997e96b0f8bceb@o1026207.ingest.sentry.io/5992565"
      : "",
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.reactRouterV4Instrumentation(history),
    }),
  ],
  release:
    process.env.REACT_APP_NODE_ENV === "production"
      ? "production roam.ai-frontend-dashboard"
      : "test roam.ai-frontend-dashboard" + new Date(),
  environment:
    process.env.REACT_APP_NODE_ENV === "production" ? "production" : "test",

  tracesSampleRate: 1.0,
});

const store = configureStore();

ReactDOM.render(
  <Sentry.ErrorBoundary
    fallback={({ error, componentStack, resetError }) => (
      <React.Fragment>
        <div>You have encountered an error</div>
        <div>{error.toString()}</div>
        <div>{componentStack}</div>
        <button
          onClick={() => {
            this.setState({ message: "Roam.ai" });
            resetError();
          }}
        >
          Click here to reset!
        </button>
      </React.Fragment>
    )}
  >
    <Provider store={store}>
      <Router history={history}>
        <Suspense fallback={<OverlaySpinner />}>
          <Routes />
        </Suspense>
      </Router>
    </Provider>{" "}
  </Sentry.ErrorBoundary>,
  document.getElementById("root")
);
registerServiceWorker();
