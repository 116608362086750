import React from "react";
import Cssmodules from "react-css-modules";
import { Tabs, Tab } from "react-bootstrap";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import styles from "./iOSSDKDocs.scss";

const IOSSDKCodeType = ({
  codeString1,
  codeString2,
  onIOSTypeChange,
  iOSType
}) => {
  return (
    <div className="ios-code-type-component">
      <Tabs
        defaultActiveKey={iOSType}
        id="ios-code-type"
        onSelect={e => onIOSTypeChange(e)}
      >
        <Tab eventKey="Swift" title="Swift">
          <SyntaxHighlighterComponent
            language="java"
            codeString={codeString1}
          />
        </Tab>
        <Tab eventKey="Objective-C" title="Objective-C">
          <SyntaxHighlighterComponent
            language="java"
            codeString={codeString2}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

IOSSDKCodeType.displayName = "IOSSDKCodeType";
IOSSDKCodeType.propTypes = {};
IOSSDKCodeType.defaultProps = {
  iOSType: "Swift"
};

export default Cssmodules(IOSSDKCodeType, styles);
