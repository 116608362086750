import * as types from "./actionTypes";
import ProjectApi from "../api/projectApi";
import notifications from "./common/notifications";
import { createApiAction } from "./common/base";
import { bindActionCreators } from "redux";

const createProject = createApiAction(
  ({ projectName, token }) => ProjectApi.createProject({ projectName, token }),
  {
    errorType: types.PROJECT_CREATE_SAVING_FAILURE,
    requestType: types.PROJECT_CREATE_SAVING,
    responseType: types.PROJECT_CREATE_SAVING_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Project created successfully.",
        autoDismiss: 3
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to create project.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const updateProject = createApiAction(
  ({ project, project_id, token }) =>
    ProjectApi.updateProject({ project, project_id, token }),
  {
    errorType: types.PROJECT_DATA_SAVING_FAILURE,
    requestType: types.PROJECT_DATA_SAVING,
    responseType: types.PROJECT_DATA_SAVING_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Your Project data has been updated successfully."
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to save project data.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

// actions to test webhook & slack events

const sendTestEvent = createApiAction(
  ({ project_id, integration, event, token }) =>
    ProjectApi.sendTestEvent({ project_id, integration, event, token }),
  {
    errorType: types.TEST_EVENTS_FAILURE,
    requestType: types.TEST_EVENTS_SAVING,
    responseType: types.TEST_EVENTS_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: data.data.data.msg
      })(dispatch);
    },
    onError: (dispatch, data) => {
      const notification = {
        title: `Failed to test event`,
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const testWebhookEvent = createApiAction(
  ({ project_id, event, token }) =>
    ProjectApi.testWebhookEvent({ project_id, event, token }),
  {
    errorType: types.TEST_WEBHOOK_EVENTS_FAILURE,
    requestType: types.TEST_WEBHOOK_EVENTS_SAVING,
    responseType: types.TEST_WEBHOOK_EVENTS_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: data.data.data.msg
      })(dispatch);
    },
    onError: (dispatch, data) => {
      const notification = {
        title: "Failed to test webhook event",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const testSlackEvent = createApiAction(
  ({ project_id, event, token }) =>
    ProjectApi.testSlackEvent({ project_id, event, token }),
  {
    errorType: types.TEST_SLACK_EVENTS_FAILURE,
    requestType: types.TEST_SLACK_EVENTS_SAVING,
    responseType: types.TEST_SLACK_EVENTS_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: data.data.data.msg
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to test slack event",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const syncCountly = createApiAction(
  ({ project_id, token }) => ProjectApi.countlySync({ project_id, token }),
  {
    errorType: types.COUNTLY_SYNC_FAILURE,
    requestType: types.COUNTLY_SYNC,
    responseType: types.COUNTLY_SYNC_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Data sync successful."
      })(dispatch);
      bindActionCreators(loadProjectsDetails, dispatch)(args.token);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Sync failed",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const deleteProject = createApiAction(
  ({ project, token }) => ProjectApi.deleteProject({ project, token }),
  {
    errorType: types.DELETE_PROJECT_SAVING_FAILURE,
    requestType: types.DELETE_PROJECT_SAVING,
    responseType: types.DELETE_PROJECT_SAVING_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Your Project has been deleted successfully."
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to delete project.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const updateApp = createApiAction(
  ({ app, token }) => ProjectApi.updateApp({ app, token }),
  {
    errorType: types.UPDATE_APP_SAVING_FAILURE,
    requestType: types.UPDATE_APP_SAVING,
    responseType: types.UPDATE_APP_SAVING_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Your App data has been updated successfully."
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to save app data.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const deleteApp = createApiAction(
  ({ app, token }) => ProjectApi.deleteApp({ app, token }),
  {
    errorType: types.DELETE_APP_SAVING_FAILURE,
    requestType: types.DELETE_APP_SAVING,
    responseType: types.DELETE_APP_SAVING_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Your app data has been deleted successfully."
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to delete app data.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const loadProjectsDetails = createApiAction(
  (token) => ProjectApi.getProjects(token),
  {
    errorType: types.PROJECTS_DATA_FETCHING_FAILURE,
    requestType: types.PROJECTS_DATA_FETCHING,
    responseType: types.PROJECTS_DATA_FETCHING_SUCCESS,
    onError: (dispatch) => {
      const notification = {
        title: "Failed to load project data.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const loadProjectStatsData = createApiAction(
  ({ projectId, token }) => ProjectApi.getProjectStats({ projectId, token }),
  {
    errorType: types.PROJECT_STATS_DATA_FETCHING_FAILURE,
    requestType: types.PROJECT_STATS_DATA_FETCHING,
    responseType: types.PROJECT_STATS_DATA_FETCHING_SUCCESS,
    onError: (dispatch) => {
      const notification = {
        title: "Failed to load project stats data.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const loadSDKUserLocationData = createApiAction(
  ({ projectId, startDate, token }) =>
    ProjectApi.getProjectActiveUsers({ projectId, startDate, token }),
  {
    errorType: types.PROJECT_SDK_USER_FETCHING_FAILURE,
    requestType: types.PROJECT_SDK_USER_FETCHING,
    responseType: types.PROJECT_SDK_USER_FETCHING_SUCCESS,
    onError: (dispatch) => {
      const notification = {
        title: "Failed to load SDK active user data.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const createProjectApp = createApiAction(
  ({ projectId, data, token }) =>
    ProjectApi.createApp({ projectId, data, token }),
  {
    errorType: types.CREATE_APP_SAVING_FAILURE,
    requestType: types.CREATE_APP_SAVING,
    responseType: types.CREATE_APP_SAVING_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Project app created successfully.",
        autoDismiss: 3
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to create app.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const generateApiKey = createApiAction(
  ({ app, token }) => ProjectApi.generateApiKey({ app, token }),
  {
    errorType: types.GENERATE_API_KEY_FAILURE,
    requestType: types.GENERATE_API_KEY,
    responseType: types.GENERATE_API_KEY_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Your App Api Key has been generated successfully."
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to generate Api Key.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const generatePrimaryKey = createApiAction(
  ({ app, token }) => ProjectApi.generatePK({ app, token }),
  {
    errorType: types.GENERATE_PRIMARY_KEY_FAILURE,
    requestType: types.GENERATE_PRIMARY_KEY,
    responseType: types.GENERATE_PRIMARY_KEY_SUCCESS,
    onResponse: (dispatch, data, args) => {
      notifications.sendNotification("success", {
        title: "Your App Primary Key has been generated successfully."
      })(dispatch);
    },
    onError: (dispatch) => {
      const notification = {
        title: "Failed to generate Primary Key.",
        autoDismiss: 0
      };
      notifications.sendNotification("error", notification)(dispatch);
    }
  }
);

const projectAddToggle = function (add_state = false) {
  return (dispatch) => {
    if (add_state) {
      dispatch({ type: types.PROJECT_ADD });
    } else {
      dispatch({ type: types.PROJECT_NOT_ADD });
    }
  };
};

const refreshGeofenceListOnCountlySync = function () {
  return (dispatch) => {
    dispatch({ type: types.COUNTLY_SYNC_FAILURE });
  };
};

const projectAppUserClear = function (clear_state = false) {
  return (dispatch) => {
    if (clear_state) {
      dispatch({ type: types.USER_DATA_CLEAR });
    }
  };
};

export default {
  createProject,
  updateProject,
  deleteProject,
  updateApp,
  deleteApp,
  loadProjectsDetails,
  loadProjectStatsData,
  loadSDKUserLocationData,
  createProjectApp,
  generateApiKey,
  generatePrimaryKey,
  projectAddToggle,
  syncCountly,
  refreshGeofenceListOnCountlySync,
  projectAppUserClear,
  testWebhookEvent,
  testSlackEvent,
  sendTestEvent
};
