import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./reactNativeTitleProgressBar.scss";

const ReactNativeTitleProgressBar = ({ className }) => {
  return (
    <div className={`${className} react-progress`}>
    <div className="progress-content">
      <div className="progress-steps active">Install Module</div>
      <div className="progress-steps step-2">Install SDK</div>
      <div className="progress-steps step-3">Initialize SDK</div>
      <div className="progress-steps step-4">Create User</div>
      <div className="progress-steps step-5">Get permissions</div>
      <div className="progress-steps step-6">Start Tracking</div>
      </div>
    </div>
  );
};

ReactNativeTitleProgressBar.displayName = "ReactNativeTitleProgressBar";

export default Cssmodules(ReactNativeTitleProgressBar, styles);
