import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import AndroidSDKDocsBox from "./SDKDocsBox";

import styles from "./androidSDKDocs.scss";

const CreateUser = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `Roam.createUser(this,"User Description", new RoamCallBack() {
    @Override
    public void onSuccess(RoamUser roamUser) {
      roamUser.getUserId();
    }
    @Override
    public void onFailure(RoamError roamError) {
                roamError.getErrorCode();
                roamError.getErrorMessage();
    }
});`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <AndroidSDKDocsBox
          introduction="Roam SDK needs a User ID object to identify the device."
          className="third-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </AndroidSDKDocsBox>
      </div>
    </div>
  );
};

CreateUser.displayName = "CreateUser";
CreateUser.propTypes = {};
CreateUser.defaultProps = {};

export default Cssmodules(CreateUser, styles);
