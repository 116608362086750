import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import IOSSDKCodeType from "./IOSSDKCodeType";
import Button from "../../../../common/Button";
import IOSSDKDocsBox from "./SDKDocsBox";

import styles from "./iOSSDKDocs.scss";

const SetDeviceToken = ({
  docsStep,
  onGoBack,
  onGoNext,
  onIOSTypeChange,
  iOSType
}) => {
  const codeString1 = `func application(_ application: UIApplication, didRegisterForRemoteNotificationsWithDeviceToken deviceToken: Data) {
  GeoSpark.setDeviceToken(deviceToken)
}`;
  const codeString2 = `- (void)application:(UIApplication *)application didRegisterForRemoteNotificationsWithDeviceToken:(NSData *)deviceToken{
  [GeoSpark didRegisterForRemoteNotificationsWithDeviceToken:deviceToken];
}`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          introduction="Roam SDK is capable of sending push notifications to your users. Check here to get device token."
          className="third-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <IOSSDKCodeType
              codeString1={codeString1}
              codeString2={codeString2}
              onIOSTypeChange={onIOSTypeChange}
              iOSType={iOSType}
            />

            <CopyToClipboard
              text={
                iOSType === "Swift" ? codeString1.trim() : codeString2.trim()
              }
            >
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

SetDeviceToken.displayName = "SetDeviceToken";
SetDeviceToken.propTypes = {};
SetDeviceToken.defaultProps = {};

export default Cssmodules(SetDeviceToken, styles);
