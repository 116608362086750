import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const codeString = `
//In onCreate method of your Application class include the code below.
public class MainApplication extends Application implements ReactApplication {
   @Override
   public void onCreate() {
        super.onCreate();
        SoLoader.init(this, / native exopackage / false);
        Roam.initialize(this, "PUBLISH_KEY");
   }
}    
`;

const codeString1 = `
GeoSpark.setDeviceToken(context, "FCM DeviceToken");  
`;

const InstallAndroid = ({ docsStep, onGoBack, onGoNext }) => {
  return (
    <div className="install-Android-component">
      <div className="gs-codesection">
        <div>
          <span className="gs-span">
            Initialize the SDK with your publishable key.
          </span>
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </div>
        <h3>Set up firebase</h3>
        <div>
          <span>
            Set FCM DeviceToken to create a user. If you haven't set FCM
            DeviceToken already, then add Firebase to your Android project.
          </span>

          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString1}
            />
            <CopyToClipboard text={codeString1}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </div>
  );
};

InstallAndroid.displayName = "InstallAndroid";
InstallAndroid.propTypes = {};
InstallAndroid.defaultProps = {};

export default Cssmodules(InstallAndroid, styles);
