import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { FaRegCopy } from "react-icons/fa";
import AndroidSDKDocsBox from "./SDKDocsBox";

import Button from "../../../../common/Button";

import styles from "./androidSDKDocs.scss";

const InstallSdk = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `
  // add the maven below in your project build.gradle file.
  repositories {
    maven {
        url 'https://com-roam-android.s3.amazonaws.com/'
    }
  }
  // add the dependencies below in your app build.gradle file.
  dependencies { 
    implementation 'com.roam.sdk:roam-android:0.0.28'
}`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <AndroidSDKDocsBox
          introduction="Install the SDK in your project via Gradle in Android Studio and add the dependencies below in your app build.gradle file."
          className="first-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </AndroidSDKDocsBox>
      </div>
    </div>
  );
};

InstallSdk.displayName = "InstallSdk";
InstallSdk.propTypes = {};
InstallSdk.defaultProps = {};

export default Cssmodules(InstallSdk, styles);
