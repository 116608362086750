import React from "react";
import Cssmodules from "react-css-modules";
// import { ProgressBar } from "react-bootstrap";

import styles from "./progressFooter.scss";

const ProgressFooter = ({ className, stepTryIgnite }) => {
  return (
    <div className={`${className} progressBar`}>
      <div className="progress-content">
        <div className="progress-items active">About you</div>
        <div className="progress-items gs-create">Create project</div>
        <div className="progress-items gs-integrate">
          {className == "three-ignite-progress"
            ? "Try RoamAround"
            : "Integrate SDK"}{" "}
        </div>
        <div className="progress-items gs-initialize">Initialized</div>
      </div>
      {/* <ProgressBar now={25} /> */}
    </div>
  );
};

ProgressFooter.displayName = "ProgressFooter";

export default Cssmodules(ProgressFooter, styles);
