import * as types from "../actions/actionTypes";

const initialState = {
  current_project_id: null,
  users_fetching_error: null,
  dateSelected: null,
  users_fetching: false,
  page_limit: 0,
  user_pages: 0,
  no_of_active_users: null,
  user_events: null,
  user_timeline: null,
  user_trips: null,
  user_home_work: null,
  user_id: null,
  users: null,
  user_created_error: null,
  user_created: null,
  user_creating: false,
  get_user_list: false,
  user_list: null,
  user_list_error: null,
  user_locations_fetching: false,
  user_locations: null,
  apiKey: null
};

export default function geofenceReducer(state = initialState, action) {
  switch (action.type) {
    case types.SDK_USERS_DATA_FETCHING:
      let data = {
        users_fetching: true,
        dateSelected: action.data.data.date,
        current_project_id: action.data.data.project_id,
        page_limit: action.data.data.page_limit,
        apiKey: action.data.apiKey
      };

      if (action.data.data.project_id !== state.current_project_id) {
        data.page_limit = 0;
        data.user_pages = 0;
        data.no_of_active_users = null;
        data.user_events = null;
        data.user_timeline = null;
        data.user_trips = null;
        data.user_home_work = null;
        data.users = null;
      }
      if (action.data.apiKey !== state.apiKey) {
        state.users = null;
      }
      return Object.assign({}, state, data);
    case types.SDK_USERS_DATA_FETCHING_SUCCESS:
      let new_users = state.users;
      if (state.users && action.data.prev_page !== null) {
        new_users = new_users.concat(action.data.users);
      } else {
        new_users = action.data.users;
      }

      return Object.assign({}, state, {
        users_fetching: false,
        users: new_users,
        user_pages: action.data.next_page
      });
    case types.SDK_USERS_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        users_fetching: false,
        users_fetching_error: action.error
      });
    case types.SDK_NO_ACTIVE_USERS_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true
      });
    case types.SDK_NO_ACTIVE_USERS_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        users_fetching: false,
        no_of_active_users: action.data
      });
    case types.SDK_NO_ACTIVE_USERS_FETCHING_FAILURE:
      return Object.assign({}, state, {
        users_fetching: false,
        users_fetching_error: action.error
      });
    case types.USER_EVENTS_DATA_FETCHING_SUCCESS:
      let events_data = state.user_events;
      if (state.user_events) {
        events_data = action.data;
        let events = state.user_events.events;
        events = events.concat(action.data.events);
        action.data.events = events;
        let geofences = state.user_events.geofences;
        if(geofences){
          geofences = geofences.concat(action.data.geofences);
        }
        events_data.geofences = geofences;
        action.data.geofences = geofences;
      }

      return Object.assign({}, state, {
        users_fetching: false,
        user_events: action.data
      });
    case types.USER_EVENTS_DATA_FETCHING:
      let user_events = state.user_events;
      if (
        action.data.data.user_id !== state.user_id ||
        action.data.data.events_page_no === 1
      ) {
        user_events = null;
      }
      return Object.assign({}, state, {
        users_fetching: true,
        user_events: user_events,
        user_id: action.data.data.user_id
      });

    case types.USER_TIMELINE_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        users_fetching: false,
        user_timeline: action.data
      });
    case types.USER_TIMELINE_DATA_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true,
        user_timeline: null
      });

    case types.USER_TRIPS_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        users_fetching: false,
        user_trips: action.data.trips
      });
    case types.USER_TRIPS_DATA_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true
      });

    case types.USER_HOME_WORK_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        users_fetching: false,
        user_home_work: action.data
      });
    case types.USER_HOME_WORK_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true
      });

    case types.CREATE_USER_FAILURE:
      return Object.assign({}, state, {
        user_creating: false,
        user_created_error: action.error
      });
    case types.CREATE_USER_FETCHING:
      return Object.assign({}, state, {
        user_creating: true
      });
    case types.CREATE_USER_SUCCESS:
      return Object.assign({}, state, {
        user_creating: false,
        user_created: action.data
      });

    case types.GET_CREATED_USERS_FETCHING_FAILURE:
      return Object.assign({}, state, {
        get_user_list: false,
        user_list_error: action.error
      });
    case types.GET_CREATED_USERS_FETCHING:
      return Object.assign({}, state, {
        get_user_list: true
      });
    case types.GET_CREATED_USERS_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        get_user_list: false,
        user_list: action.data
      });

    case types.SEARCH_USER_FAILURE:
      return Object.assign({}, state, {
        users_fetching: false
      });
    case types.SEARCH_USER_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true
      });
    case types.SEARCH_USER_SUCCESS:
      let searchUser = null;
      if (action.data.hasOwnProperty("users")) {
        searchUser = action.data.users;
      } else {
        searchUser = action.data;
      }

      return Object.assign({}, state, {
        users_fetching: false,
        users: searchUser,
        user_pages: action.data.next_page
      });

    case types.GET_ACTIVE_USERS_FAILURE:
      return Object.assign({}, state, {
        users_fetching: false
      });
    case types.GET_ACTIVE_USERS_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true
      });
    case types.GET_ACTIVE_USERS_SUCCESS:
      let activeUsers = state.users;
      if (state.users && action.data.prev_page !== null) {
        activeUsers = activeUsers.concat(action.data.users);
      } else {
        activeUsers = action.data.users;
      }

      // let activeUsers = null;
      // if (action.data.hasOwnProperty("users")) {
      //   activeUsers = action.data.users;
      // } else {
      //   activeUsers = action.data;
      // }

      return Object.assign({}, state, {
        users_fetching: false,
        users: activeUsers,
        user_pages: action.data.next_page
      });

    case types.GET_USER_LOCATIONS_FAILURE:
      return Object.assign({}, state, {
        user_locations_fetching: false
      });
    case types.GET_USER_LOCATIONS_FETCHING:
      return Object.assign({}, state, {
        user_locations_fetching: true
      });
    case types.GET_USER_LOCATIONS_SUCCESS:
      return Object.assign({}, state, {
        user_locations_fetching: false,
        user_locations: action.data.locations
      });

    default:
      return state;
  }
}
