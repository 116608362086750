import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./iOSTitleProgressBar.scss";

const IOSTitleProgressBar = ({ className }) => {
  return (
    <div className={`${className} ios-progress`}>
      <div className="progress-content">
        <div className="progress-steps active">Install SDK</div>
        <div className="progress-steps step-2">Configure project</div>
        {/* <div className="progress-steps step-3">Set Device Token</div> */}
        <div className="progress-steps step-3">Initialize SDK</div>
        <div className="progress-steps step-4">Create User</div>
        <div className="progress-steps step-5">Start Tracking</div>
      </div>
    </div>
  );
};

IOSTitleProgressBar.displayName = "IOSTitleProgressBar";

export default Cssmodules(IOSTitleProgressBar, styles);
