import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./createSDK.scss";
import FieldGroup from "../../../common/InputBox";
import { FaAndroid, FaApple } from "react-icons/fa";
import rightintegrateimage from "../../../../public/images/gradlephant.png";
import rightintegrateiosimage from "../../../../public/images/cocoapods.png";
import rightintegratenpmimage from "../../../../public/images/npm.png";
import leftintegrateimage from "../../../../public/images/manual-download.png";
// import { SymbolErrorHelp } from "../../../common/commonMessage/errorMessages";
import { NameErrorHelp } from "../../../common/commonMessage/errorMessages";
import Flutter from "../../../../public/images/flutter-colored.svg";

const CreateSDK = ({
  projectName,
  validPackageName,
  validBundleId,
  handleChange,
  onCreateApp,
  step,
  SDKType
}) => {
  return (
    <div className="android-ask-component">
      <h3>
        Integrate the
        <span className="sdk-ask-types">
          {SDKType === 1 ? "Android" : SDKType === 2 ? "iOS" : "React Native"}
        </span>
        SDK for <br />
        <span> {projectName}</span>
      </h3>

      <div className="postlogin-card">
        {SDKType !== 2 && (
          <div className="pre-textbox gs-sdk-intergrate">
            <FieldGroup
              id="PackageName"
              label="Package Name*"
              name="packageName"
              type="text"
              placeholder="Please enter a package name "
              onChange={(e) => handleChange(e, "packageName")}
              help={
                validPackageName > 0
                  ? NameErrorHelp[validPackageName - 1]
                  : null
              }
              icon={
                <span className="gs-sdk-icon">
                  <FaAndroid />
                </span>
              }
            />
          </div>
        )}

        {SDKType !== 1 && (
          <div className="pre-textbox gs-sdk-intergrate">
            <FieldGroup
              id="BundleID"
              label="Bundle ID*"
              name="bundleId"
              type="text"
              placeholder="enter bundle id"
              onChange={(e) => handleChange(e, "bundleId")}
              help={validBundleId > 0 ? NameErrorHelp[validBundleId - 1] : null}
              icon={
                <span className="gs-sdk-icon">
                  <FaApple />
                </span>
              }
            />
          </div>
        )}

        <div className="pre-textbox gs-sdk-intergrate gs-integration-method">
          <p>Select Integration Method</p>

          {SDKType == 1 && (
            <div className="gs-type">
              <span
                onClick={(e) => onCreateApp(e, true)}
                className="gs-integrate-type"
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={leftintegrateimage}
                />
                <span>Manual</span>
              </span>

              <span
                className="gs-integrate-type"
                onClick={(e) => onCreateApp(e)}
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={rightintegrateimage}
                />
                <span>Gradle (Recomended)</span>
              </span>
            </div>
          )}

          {SDKType == 2 && (
            <div className="gs-type">
              <span
                onClick={(e) => onCreateApp(e, true)}
                className="gs-integrate-type"
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={leftintegrateimage}
                />
                <span>Manual</span>
              </span>

              <span
                className="gs-integrate-type"
                onClick={(e) => onCreateApp(e)}
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={rightintegrateiosimage}
                />
                <span>CocoaPods (Recomended)</span>
              </span>
            </div>
          )}

          {SDKType == 3 && (
            <div className="gs-type">
              {/* <span
                onClick={e => onCreateApp(e, true)}
                className="gs-integrate-type"
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={leftintegrateimage}
                />
                <span>Manual</span>
              </span> */}

              <span
                onClick={(e) => onCreateApp(e, true)}
                className="gs-integrate-type"
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={rightintegratenpmimage}
                />
                <span>NPM</span>
              </span>
            </div>
          )}
          {SDKType == 4 && (
            <div className="gs-type">
              {/* <span
                onClick={e => onCreateApp(e, true)}
                className="gs-integrate-type"
              >
                <img
                  alt="roam"
                  className="geo-rightimage"
                  src={leftintegrateimage}
                />
                <span>Manual</span>
              </span> */}

              <span
                onClick={(e) => onCreateApp(e, true)}
                className="gs-integrate-type"
              >
                <img alt="roam" className="geo-rightimage" src={Flutter} />
                <span>Pub.dev</span>
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

CreateSDK.displayName = "CreateSDK";
CreateSDK.propTypes = {};
CreateSDK.defaultProps = {};

export default Cssmodules(CreateSDK, styles);
