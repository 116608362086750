import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import moment from "moment";
import _ from "underscore";
import { FaAndroid, FaApple, FaReact } from "react-icons/fa";

import CommonMethod from "../common/commonMethod";
import notificationsActions from "../../actions/common/notifications";
import projectAction from "../../actions/projectAction";
import BoxAndMap from "../../components/common/BoxAndMap";
import Navbar from "../../components/common/navbar/Navbar";
import OverlaySpinner from "../../components/common/OverlaySpinner";
import ProgressFooter from "../../components/common/footer/ProgressFooter";
import IntegrateSDK from "../../components/project/createProject/IntegrateSDK";
import CreateSDK from "../../components/project/createProject/integrateSDK/CreateSDK";
import IntegrationDone from "../../components/project/createProject/integrateSDK/IntegrationDone";

import AndroidSDKIntegrationSteps from "../../components/project/createProject/integrateSDK/AndroidSDKMethod/AndroidSDKIntegrationSteps";
import IOSSDKIntegrationSteps from "../../components/project/createProject/integrateSDK/IOSSDKMethod/IOSSDKIntegrationSteps";
import ReactNativeSDKIntegrationSteps from "../../components/project/createProject/integrateSDK/ReactNativeSDKMethod/ReactNativeSDKIntegrationSteps";
let UserApiInteval;

export class ManualProjectContainer extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showLoading: false,
      step: 0,
      SDKType: 1,
      SDKCreated: false,
      projectName: null,
      packageName: null,
      bundleId: null,
      validPackageName: 0,
      validBundleId: 0,
      projectId: null,
      token: null,
      docsStep: 0
    };
    this.handleChange = this.handleChange.bind(this);
    this.onChangeRoute = this.onChangeRoute.bind(this);
    this.onGoBackSteps = this.onGoBackSteps.bind(this);
    this.onGoBack = this.onGoBack.bind(this);
    this.onClickOnSDK = this.onClickOnSDK.bind(this);
    this.onCreateApp = this.onCreateApp.bind(this);
    this.onGoNext = this.onGoNext.bind(this);
    this.onIntegrationDone = this.onIntegrationDone.bind(this);
    this.onSkipIntegration = this.onSkipIntegration.bind(this);
    this.searchBySDKType = this.searchBySDKType.bind(this);
    this.docsRedirect = this.docsRedirect.bind(this);
    this.onGoBackToDemo = this.onGoBackToDemo.bind(this);
  }

  async componentDidMount() {
    const project_name = this.props.location.state.project_name;
    const project_id = window.location.pathname.split("/")[2];
    const token = await localStorage.getItem("playground_token");
    this.setState({
      projectName: project_name,
      projectId: project_id,
      token: token
    });
  }

  componentWillUpdate(nextProps, nextState) {
    const { SDKType, SDKCreated } = this.state;

    if (
      !_.isEmpty(nextProps.projects_details) &&
      this.searchBySDKType(nextProps.projects_details[0], SDKType) &&
      !SDKCreated
    ) {
      this.setState({
        step: 2,
        SDKCreated: true
      });
    }
  }

  searchBySDKType(project, SDKType) {
    let foundSDK = false;
    project.apps.map((app, j) => {
      if (app.app_platform === SDKType) {
        foundSDK = true;
      }
    });

    return foundSDK;
  }

  async handleChange(e, name) {
    let change = {};
    change[name] = e.target.value;

    if (name === "packageName") {
      const isValidPackageName = await CommonMethod.checkPackageNameValidation(
        e.target.value
      );
      this.setState({
        validPackageName: isValidPackageName
      });
    } else if (name === "bundleId") {
      const isValidBundleId = await CommonMethod.checkBundleIDValidation(
        e.target.value
      );
      this.setState({
        validBundleId: isValidBundleId
      });
    }

    this.setState(change);
  }

  onChangeRoute(e, path = "/project-demo") {
    e.preventDefault();
    this.props.history.push(path);
  }

  onGoBackSteps() {
    const { step } = this.state;
    this.setState({
      step: step - 1
    });
  }

  onClickOnSDK(e, label) {
    let type = 3;
    if (label === "Android") {
      type = 1;
    } else if (label === "iOS") {
      type = 2;
    } else if (label === "Flutter") {
      type = 4;
    }
    this.setState({
      SDKType: type,
      step: 1
    });
  }

  async onGoNext() {
    const { SDKType, docsStep, step, projectId, token } = this.state;
    let docsSteps = docsStep + 1;
    let navSteps = step;

    if (
      (SDKType === 1 && docsStep === 3) ||
      (SDKType === 2 && docsStep === 3) ||
      (SDKType === 3 && docsStep === 5)
    ) {
      const startDate = moment().format("YYYY-MM-DD");
      navSteps = 3;
      UserApiInteval = setInterval(() => {
        this.props.actions.loadSDKUserLocationData({
          projectId: projectId,
          startDate: startDate,
          token: token
        });
      }, 5000);
    }
    this.setState({
      docsStep: docsSteps,
      step: navSteps
    });
  }

  onGoBack() {
    const { docsStep } = this.state;
    if (docsStep === 0) {
      this.setState({
        step: 1
      });
    } else {
      let steps = docsStep - 1;
      this.setState({
        docsStep: steps
      });
    }
  }

  async onCreateApp(e, redirect = false) {
    e.preventDefault();
    const { projectId, packageName, bundleId, SDKType, token } = this.state;

    let data = {
      app_platform: SDKType
    };

    let data1 = {
      app_platform: SDKType
    };

    let data2 = {
      app_platform: SDKType
    };

    const isValidPackageName = await CommonMethod.checkPackageNameValidation(
      packageName
    );
    const isValidBundleId = await CommonMethod.checkBundleIDValidation(
      bundleId
    );

    let isValidForm = false;

    if (SDKType === 1) {
      data.package_name = packageName;
      isValidForm = isValidPackageName === 0;
    } else if (SDKType === 2) {
      data.bundle_id = bundleId;
      isValidForm = isValidBundleId === 0;
    } else {
      if (packageName && bundleId) {
        data1.app_platform = 1;
        data1.package_name = packageName;
        data2.app_platform = 2;
        data2.bundle_id = bundleId;
      } else if (packageName) {
        data1.app_platform = 1;
        data1.package_name = packageName;
      } else {
        data1.app_platform = 2;
        data1.bundle_id = bundleId;
      }
      isValidForm = isValidPackageName === 0 || isValidBundleId === 0;
    }

    this.setState({
      validPackageName: isValidPackageName,
      validBundleId: isValidBundleId
    });

    if (isValidForm) {
      if (isValidForm) {
        this.setState({
          showLoading: true
        });

        const { actions } = this.props;

        if (SDKType == 3 || SDKType == 4) {
          data = data1;
        }

        actions.createProjectApp({ projectId, data, token });
        if (packageName && bundleId) {
          data = data2;
          await setTimeout(
            () => actions.createProjectApp({ projectId, data, token }),
            2500
          );
        }
        localStorage.setItem("is_project_updated", "YES");
        await setTimeout(() => actions.loadProjectsDetails(token), 2000);
        if (redirect) {
          if (this.props.hasOwnProperty("onCloseModal")) {
            this.props.onCloseModal();
          } else {
            setTimeout(
              () => this.props.history.push("/pricing"),
              2000
            );
          }
          this.docsRedirect();
        }
      }
    }
  }

  docsRedirect() {
    // for android 1 for ios 2
    //check if its manual and sdk type is 1, redirect it to docs page and close the modal (https://docs.roam.ai/android/troubleshooting#install-sdk-manual)
    //check if its manual and sdk type is 2, redirect it to docs page and close the modal (https://docs.roam.ai/ios/troubleshooting#install-sdk-manual)

    // for reactnative any thing
    //check if its manual, redirect it to docs page and close the modal (https://github.com/roam-ai/roam-reactnative)
    //add code to close the modal and redirect to docs page (https://github.com/roam-ai/roam-reactnative)
    const { SDKType } = this.state;
    let redirect_url = "";
    if (SDKType === 1) {
      redirect_url =
        "https://docs.roam.ai/android/troubleshooting#install-sdk-manual";
    } else if (SDKType === 2) {
      redirect_url =
        "https://docs.roam.ai/ios/troubleshooting#install-sdk-manual";
    } else {
      redirect_url = "https://github.com/roam-ai/roam-reactnative";
    }
    window.open(redirect_url, "_blank");
  }

  onIntegrationDone(e) {
    const { token } = this.state;
    e.preventDefault();
    const { actions } = this.props;
    actions.loadProjectsDetails(token);
    this.props.history.push("/pricing");
  }

  onSkipIntegration(e) {
    e.preventDefault();
    const { token } = this.state;
    const { actions } = this.props;
    actions.loadProjectsDetails(token);
    this.props.history.push("/pricing");
  }

  async onGoBackToDemo() {
    // add the removal of the project code
    const { token, projectId } = this.state;
    const { actions } = this.props;
    const project = {
      project_id: projectId
    };
    await actions.deleteProject({ project, token });
    this.props.history.push("/create-project");
  }

  componentWillUnmount() {
    clearInterval(UserApiInteval);
  }

  render() {
    const {
      step,
      SDKType,
      projectName,
      validPackageName,
      validBundleId,
      docsStep
    } = this.state;

    const { users, project_saving } = this.props;

    const publish_key = this.props.location.state.publish_key;

    if (users && users.length > 0) {
      clearInterval(UserApiInteval);
    }

    return (
      <div>
        <Navbar />
        <BoxAndMap
          showMap={step === 3}
          users={users}
          showDemoImage={step === 0}
          showIcon={step > 0}
          label="post-login"
          icon={
            SDKType === 1 ? (
              <FaAndroid />
            ) : SDKType === 2 ? (
              <FaApple />
            ) : (
              <FaReact />
            )
          }
        >
          <div className="project-left">
            {step === 0 ? (
              <IntegrateSDK
                onGoBack={this.onGoBackToDemo}
                onClickOnSDK={this.onClickOnSDK}
                onSkipIntegration={this.onSkipIntegration}
                projectName={projectName}
                SDKType={SDKType}
                onCreateApp={this.onCreateApp}
              />
            ) : step === 1 ? (
              <CreateSDK
                onGoBack={this.onGoBackSteps}
                onClickOnSDK={this.onClickOnSDK}
                projectName={projectName}
                validBundleId={validBundleId}
                validPackageName={validPackageName}
                handleChange={this.handleChange}
                SDKType={SDKType}
                onCreateApp={this.onCreateApp}
              />
            ) : step === 3 ? (
              <IntegrationDone
                onIntegrationDone={this.onIntegrationDone}
                SDKType={SDKType}
              />
            ) : SDKType === 1 ? (
              <AndroidSDKIntegrationSteps
                docsStep={docsStep}
                onGoBack={this.onGoBack}
                onGoNext={this.onGoNext}
                projectName={projectName}
                publish_key={publish_key}
              />
            ) : SDKType === 2 ? (
              <IOSSDKIntegrationSteps
                docsStep={docsStep}
                onGoBack={this.onGoBack}
                onGoNext={this.onGoNext}
                projectName={projectName}
                publish_key={publish_key}
              />
            ) : (
              <ReactNativeSDKIntegrationSteps
                docsStep={docsStep}
                onGoBack={this.onGoBack}
                onGoNext={this.onGoNext}
                projectName={projectName}
              />
            )}
          </div>
          <div className="gs-footer" />
          <ProgressFooter
            className={
              step === 0
                ? ("half-progress": "third-quarter-progress")
                : step === 3
                ? "full-progress"
                : "third-quarter-progress"
            }
          />
        </BoxAndMap>

        {project_saving && (
          <OverlaySpinner show={project_saving} fullScreen={true} />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    users_fetching: state.projectsReducer.users_fetching,
    app_saving: state.projectsReducer.app_saving,
    users: state.projectsReducer.users,
    projects_details: state.projectsReducer.projects_details,
    project_saving: state.projectsReducer.project_saving
  };
}

function mapDispatchToProps(dispatch) {
  const actions = {
    ...notificationsActions,
    ...projectAction
  };
  const actionMap = { actions: bindActionCreators(actions, dispatch) };
  return actionMap;
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ManualProjectContainer);
