import React from "react";
import Cssmodules from "react-css-modules";
import { Tabs, Tab } from "react-bootstrap";

import styles from "./reactNativeSDKDocs.scss";

const SDKType = ({ component1, component2 }) => {
  return (
    <div className="gs-codesection">
      <div className="sdk-type-component">
        <Tabs defaultActiveKey="iOS" id="sdk-type">
          <Tab eventKey="iOS" title="iOS">
            {component1}
          </Tab>
          <Tab eventKey="Android" title="Android">
            {component2}
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

SDKType.displayName = "SDKType";
SDKType.propTypes = {};
SDKType.defaultProps = {};

export default Cssmodules(SDKType, styles);
