import ApiCall from "./apiCall";

class AuthApi {
  static createAccount(
    email,
    password,
    account_name,
    full_name,
    promo_code,
    source,
    industry
  ) {
    return ApiCall.post("accounts/", {
      email: email,
      password: password,
      account_name: account_name,
      full_name: full_name,
      promo_code: promo_code,
      source: source,
      industry: industry
    });
  }

  static loginAccount(email, password, remember) {
    return ApiCall.post("accounts/login/", {
      email: email,
      password: password,
      remember: remember
    });
  }

  static emailCodeVerification(email, code) {
    return ApiCall.post("accounts/verification-code/", {
      email: email,
      code: code
    });
  }

  static forgotPassword(email, type) {
    return ApiCall.post("accounts/resend-verify/", {
      email: email,
      type: type
    });
  }

  static resetPassword(verification_token, password, confirm_password) {
    return ApiCall.post(`accounts/reset-password/${verification_token}/`, {
      verification_token: verification_token,
      password: password,
      confirm_password: confirm_password
    });
  }

  static logoutAccount(token) {
    return ApiCall.post("accounts/logout/", {}, token);
  }
}

export default AuthApi;
