import React from "react";
import PropTypes from "prop-types";
import CSSModules from "react-css-modules";
import styles from "./overlaySpinner.scss";

// NOTE: for non-fullScreen display, parent need to have display: "relative" in order to work
// TODO: removed left property when aligning correctly in full screen mode
const OverlaySpinner = ({ fullScreen }) => (
  <div
    className={
      fullScreen === true
        ? "overlayspinner-default-component"
        : "overlayspinner-component"
    }
    // styleName="overlayspinner-component"
    style={{ position: fullScreen ? "fixed" : "absolute" }}
  >
    <div className="spinner">
      {/* <div className="bounce1" style={{ left: fullScreen ? 20 : 0 }} />
      <div className="bounce2" style={{ left: fullScreen ? 20 : 0 }} />
      <div style={{ left: fullScreen ? 20 : 0 }} /> */}
      <div className="double-bounce1" />
      <div className="double-bounce2" />
    </div>
  </div>
);

OverlaySpinner.displayName = "OverlaySpinner";
OverlaySpinner.propTypes = {
  fullScreen: PropTypes.bool
};
OverlaySpinner.defaultProps = {
  fullScreen: true
};

export default CSSModules(OverlaySpinner, styles);
