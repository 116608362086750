import React from "react";
import Cssmodules from "react-css-modules";

import { FaChevronCircleRight } from "react-icons/fa";
import styles from "./integrateSDKCard.scss";

const IntegrateSDKCard = ({ icon, label, onClickOnSDK }) => {
  return (
    <div className="integrate-sdk-card-component">
      <div onClick={(e) => onClickOnSDK(e, label)}>
        <span className="gs-sdk-icon">{icon}</span>
        <span className="gs-sdk-text">
          Roam SDK for <span class="gs-textdark">{label}</span>
        </span>
        <span class="gs-sdk-go">
          <FaChevronCircleRight />
        </span>
      </div>
    </div>
  );
};

IntegrateSDKCard.displayName = "IntegrateSDKCard";
IntegrateSDKCard.propTypes = {};
IntegrateSDKCard.defaultProps = {
  onClickOnSDK: () => {}
};

export default Cssmodules(IntegrateSDKCard, styles);
