import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./integrationDone.scss";
import Button from "../../../common/Button";

const IntegrationDone = ({ onIntegrationDone, SDKType }) => {
  return (
    <div className="sdk-integration-done">
      <h3>Awesome!</h3>
      <div>
        You've installed{" "}
        <span>
          the Roam SDK for
          {SDKType === 1
            ? " Android"
            : SDKType === 2
            ? " iOS"
            : " React Native"}
          .
        </span>
      </div>
      <div>Discover our features!</div>
      {/* <div className="project-info-box">
        <div className="box-set">
          <div className="box">Trips</div>
          <div className="box">Geofences</div>
        </div>
        <div className="box-set">
          <div className="box">Set Tacking inMotion</div>
          <div className="box">Forum</div>
        </div>
      </div> */}

      <div className="done-button">
        <Button
          label="Done"
          className="gs-primary"
          onClick={(e) => onIntegrationDone(e)}
        />
      </div>
    </div>
  );
};

IntegrationDone.displayName = "IntegrationDone";
IntegrationDone.propTypes = {};
IntegrationDone.defaultProps = {};

export default Cssmodules(IntegrationDone, styles);
