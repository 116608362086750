import React from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";
import { Form } from "react-bootstrap";

import styles from "./inputBox.scss";

const InputBox = ({
  id,
  label,
  help,
  onChange,
  type,
  name,
  value,
  disabled,
  placeholder,
  validationState,
  autoFocus,
  maxLength,
  icon,
}) => {
  return (
    <Form.Group controlId={id} validationState={help ? "error" : null}>
      <Form.Control
        className={"textinput"}
        type={type}
        value={value}
        placeholder={placeholder}
        name={name}
        onChange={onChange}
        disabled={disabled}
        autoFocus={autoFocus}
        autoComplete="new-password"
        maxLength={maxLength}
      />
      <Form.Label>{label}</Form.Label>
      {icon}
      {help && <Form.Text className="text-muted">{help}</Form.Text>}
    </Form.Group>
  );
};

InputBox.displayName = "InputBox";
InputBox.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validationState: PropTypes.string,
};
InputBox.defaultProps = {
  onChange() {
    return null;
  },
  autoFocus: false,
};

export default Cssmodules(InputBox, styles);
