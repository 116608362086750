import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";

import IOSSDKDocsBox from "./SDKDocsBox";
import IOSSDKCodeType from "./IOSSDKCodeType";

import styles from "./iOSSDKDocs.scss";

const CreateUser = ({
  docsStep,
  onGoBack,
  onGoNext,
  onIOSTypeChange,
  iOSType
}) => {
  const codeString1 = `Roam.createUser("User Description",{ (user) in
  // user.userId
},onFailure: { (error) in
  // error.errorCode
  // error.errorMessage
})`;
  const codeString2 = `[Roam createUser:@"User Description" :^(RoamUser * user) {
  // user.userId
} onFailure:^(RoamError * error) {
  // error.errorCode
  // error.errorMessage
}];`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          introduction="Roam SDK needs a User ID object to identify the device."
          className="forth-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <IOSSDKCodeType
              codeString1={codeString1}
              codeString2={codeString2}
              onIOSTypeChange={onIOSTypeChange}
              iOSType={iOSType}
            />
            <CopyToClipboard
              text={
                iOSType === "Swift" ? codeString1.trim() : codeString2.trim()
              }
            >
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

CreateUser.displayName = "CreateUser";
CreateUser.propTypes = {};
CreateUser.defaultProps = {};

export default Cssmodules(CreateUser, styles);
