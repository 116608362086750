import React from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";
import _ from "underscore";
import styles from "./createProject.scss";
import IgniteCard from "./IgniteCard";
import ProjectNameForm from "./ProjectNameForm";
import InitialIgniteProjectCard from "./InitialIgniteProjectCard.js";

const CreateProject = ({
  handleChange,
  onSubmit,
  keyPress,
  validProjectName,
  onChangeRoute,
  onCreateProject,
  disabled,
  showFirstLabel,
  manualIgniteStatus,
  projects_details
}) => {
  return (
    <div className="create-project-component">
      {manualIgniteStatus == true ? (
        <div>
          <ProjectNameForm
            handleChange={handleChange}
            onSubmit={onSubmit}
            keyPress={keyPress}
            validProjectName={validProjectName}
            onChangeRoute={onChangeRoute}
            onCreateProject={onCreateProject}
            disabled={disabled}
            showFirstLabel={showFirstLabel}
            manualIgniteStatus={manualIgniteStatus}
          />
          <IgniteCard
            onChangeRoute={onChangeRoute}
            disabled={disabled}
            onCreateProject={onCreateProject}
            manualIgniteStatus={manualIgniteStatus}
          />
        </div>
      ) : (
        <div>
          <InitialIgniteProjectCard
            handleChange={handleChange}
            onSubmit={onSubmit}
            keyPress={keyPress}
            validProjectName={validProjectName}
            onChangeRoute={onChangeRoute}
            onCreateProject={onCreateProject}
            disabled={disabled}
            showFirstLabel={showFirstLabel}
            manualIgniteStatus={manualIgniteStatus}
          />
        </div>
      )}
    </div>
  );
};

CreateProject.displayName = "CreateProject";
CreateProject.propTypes = {
  handleChange: PropTypes.func
};
CreateProject.defaultProps = {};

export default Cssmodules(CreateProject, styles);
