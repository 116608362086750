import * as types from "../actions/actionTypes";

const initialState = {
  project_saving: false,
  app_saving: false,
  project_stats_fetching: false,
  projects_fetching: false,
  delete_project: false,
  delete_app_id: null,
  projects_details: null,
  projects_fetching_error: null,
  users_fetching: false,
  users: null,
  users_fetching_error: null,
  projects: null,
  create_project_error: null,
  is_project_add: false,
  countly_sync_status: false,
  test_webhook_response: null,
  test_slack_response: null,
  test_response: null,
  project_created: false
};

function searchByProjectId(projects, id) {
  for (let i = 0; i < projects.length; i++) {
    if (projects[i].id === id) {
      return i;
    }
  }
  return null;
}

function searchByAppId(projects, app_id) {
  let ii = null,
    ji = null;

  projects.map((project, i) => {
    project.apps.map((app, j) => {
      if (app.app_id === app_id) {
        ii = i;
        ji = j;
      }
    });
  });

  return [ii, ji];
}

export default function myAccountReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_CREATE_SAVING:
      return Object.assign({}, state, {
        project_saving: true
      });
    case types.PROJECT_CREATE_SAVING_SUCCESS:
      let new_project =
        state.projects_details === null ? [] : state.projects_details;
      new_project = new_project.concat(action.data);
      return Object.assign({}, state, {
        project_saving: false,
        projects_details: new_project,
        project_created: true
      });
    case types.PROJECT_CREATE_SAVING_FAILURE:
      return Object.assign({}, state, {
        project_saving: false,
        users_fetching_error: action,
        project_created: false
      });

    case types.PROJECTS_DATA_FETCHING:
      return Object.assign({}, state, {
        projects_fetching: true
      });
    case types.PROJECTS_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        projects_fetching: false,
        projects_details: action.data
      });
    case types.PROJECTS_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        projects_fetching: false,
        projects_fetching_error: action.error
      });

    case types.PROJECT_STATS_DATA_FETCHING:
      return Object.assign({}, state, {
        project_stats_fetching: true
      });

    case types.PROJECT_STATS_DATA_FETCHING_SUCCESS:
      let ObjctIndx = searchByProjectId(
        state.projects_details,
        action.data.project_id
      );

      if (ObjctIndx != null) {
        state.projects_details[ObjctIndx].stats = action.data;
      }

      return Object.assign({}, state, {
        project_stats_fetching: false,
        projects_details: state.projects_details
      });

    case types.DELETE_PROJECT_SAVING:
      return Object.assign({}, state, {
        delete_project: true
      });

    case types.DELETE_PROJECT_SAVING_SUCCESS:
      let ObjectIndxe = searchByProjectId(
        state.projects_details,
        action.data.id
      );

      if (ObjectIndxe != null) {
        state.projects_details.splice(ObjectIndxe, 1);
      }
      return Object.assign({}, state, {
        projects_details: state.projects_details,
        delete_project: false
      });

    case types.PROJECT_SDK_USER_FETCHING:
      return Object.assign({}, state, {
        users_fetching: true
      });
    case types.PROJECT_SDK_USER_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        users_fetching: false,
        users: action.data.users
      });
    case types.PROJECT_SDK_USER_FETCHING_FAILURE:
      return Object.assign({}, state, {
        users_fetching: false,
        users_fetching_error: action.error
      });

    case types.CREATE_APP_SAVING:
      return Object.assign({}, state, {
        project_saving: true
      });
    case types.CREATE_APP_SAVING_SUCCESS:
      let ObjectIndex = searchByProjectId(
        state.projects_details,
        action.data.app_id.split("_")[0]
      );

      if (ObjectIndex != null) {
        state.projects_details[ObjectIndex].apps.push(action.data);
      }
      return Object.assign({}, state, {
        projects_details: state.projects_details,
        project_saving: false
      });
    case types.CREATE_APP_SAVING_FAILURE:
      return Object.assign({}, state, {
        project_saving: false,
        users_fetching_error: action.error
      });

    case types.PROJECT_DATA_SAVING_SUCCESS:
      let ObjectIndx = searchByProjectId(
        state.projects_details,
        action.data.id
      );

      if (ObjectIndx != null) {
        state.projects_details[ObjectIndx] = action.data;
      }
      return Object.assign({}, state, {
        projects_details: state.projects_details,
        projects_fetching: false
      });

    case types.UPDATE_APP_SAVING:
      return Object.assign({}, state, {
        projects_fetching: true
      });

    case types.UPDATE_APP_SAVING_SUCCESS:
      let Indx = searchByAppId(state.projects_details, action.data.app_id);
      if (Indx != null) {
        state.projects_details[Indx[0]].apps[Indx[1]] = action.data;
      }
      return Object.assign({}, state, {
        projects_details: state.projects_details,
        projects_fetching: false
      });

    case types.DELETE_APP_SAVING_SUCCESS:
      let Index = searchByAppId(state.projects_details, state.delete_app_id);
      if (Index[0] != null) {
        state.projects_details[Index[0]].apps.splice(Index[1], 1);
      }
      return Object.assign({}, state, {
        projects_details: state.projects_details,
        delete_project: false
      });

    case types.DELETE_APP_SAVING:
      return Object.assign({}, state, {
        delete_project: true,
        delete_app_id: action.data.app.app_id
      });

    case types.GENERATE_API_KEY:
      return Object.assign({}, state, {
        project_saving: true
      });

    case types.GENERATE_API_KEY_SUCCESS:
      const ObjectI = searchByProjectId(state.projects_details, action.data.id);

      if (ObjectI != null) {
        state.projects_details[ObjectI].secret_api_key =
          action.data.secret_api_key;
      }

      return Object.assign({}, state, {
        projects_details: state.projects_details,
        project_saving: false
      });

    case types.GENERATE_PRIMARY_KEY:
      return Object.assign({}, state, {
        project_saving: true
      });

    case types.GENERATE_PRIMARY_KEY_SUCCESS:
      const ObjectIn = searchByProjectId(
        state.projects_details,
        action.data.id
      );

      if (ObjectIn != null) {
        state.projects_details[ObjectIn].publish_key = action.data.publish_key;
      }

      return Object.assign({}, state, {
        projects_details: state.projects_details,
        project_saving: false
      });
    case types.PROJECT_ADD:
      return Object.assign({}, state, {
        is_project_add: true
      });
    case types.PROJECT_NOT_ADD:
      return Object.assign({}, state, {
        is_project_add: false
      });
    case types.COUNTLY_SYNC_SUCCESS:
      return Object.assign({}, state, {
        countly_sync_status: true
      });
    case types.COUNTLY_SYNC_FAILURE:
      return Object.assign({}, state, {
        countly_sync_status: false
      });
    case types.USER_DATA_CLEAR:
      return Object.assign({}, state, {
        users: null
      });

    case types.TEST_EVENTS_SAVING:
      return Object.assign({}, state, {
        test_response: ''
      });
    case types.TEST_EVENTS_FAILURE:
      return Object.assign({}, state, {
        test_response: action.data.response.data
      });
    case types.TEST_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        test_response: action.data?.status
      });

    case types.TEST_WEBHOOK_EVENTS_FAILURE:
      return Object.assign({}, state, {
        test_webhook_response: action.data.response.data
      });
    case types.TEST_WEBHOOK_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        test_webhook_response: action.data
      });

    case types.TEST_SLACK_EVENTS_FAILURE:
      return Object.assign({}, state, {
        test_slack_response: action.data.response.data
      });
    case types.TEST_SLACK_EVENTS_SUCCESS:
      return Object.assign({}, state, {
        test_slack_response: action.data
      });
    default:
      return state;
  }
}
