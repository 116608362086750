const dataSet = {
    emailBlackList: [
        "upcmaill.com",
        "hxqmail.com",
        "mailernam.com",
        "mailezee.com",
        "sweatmail.com",
        "emailnube.com",
        "remailsky.com",
        "mrisemail.com",
        "newe-mail.com",
        "provamail.com",
        "fenceve.com",
        "sharklasers.com",
        "guerrillamail.info",
        "grr.la",
        "guerrillamail.biz",
        "guerrillamail.com",
        "guerrillamail.de",
        "guerrillamail.org",
        "guerrillamail.net",
        "guerrillamailblock.com",
        "pokemail.net",
        "spam4.me",
        "enayu.com",
        "aallaa.org",
        "inbox-me.top",
        "mailinator.com",
        "yopmail.com",
        "optimaweb.me",
        "opten.email",
        "awdrt.net",
        "maildrop.cc",
        "trash-mail.com",
        "re-gister.com",
        "trash-me.com",
        "you-spam.com",
        "fake-box.com",
        "opentrash.com",
        "fls4.gleeze.com",
        "xww.ro",
        "drivetagdev.com",
        "maildx.com",
        "furusato.tokyo",
        "ppetw.com",
        "szerz.com",
        "cetpass.com",
        "mailfs.com",
        "rkomo.com",
        "o.opendns.ro",
        "t.psh.me",
        "drivetagdev.com",
        "i.xcode.ro",
        "glubex.com",
        "tvchd.com",
        "u.dmarc.ro",
        "sroff.com",
        "www.barryogorman.com",
        "zhcne.com",
        "isdaq.com",
        "nezzart.com",
        "laoho.com"
    ],
    serverErrorCodes: [500, 501, 502, 503, 504, 505, 506, 507, 508, 509, 510, 511]
}

export default dataSet;