import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import Button from "../../../../common/Button";

import ReactNativeSDKDocsBox from "./SDKDocsBox";

import styles from "./reactNativeSDKDocs.scss";

const CreateUser = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `
  Roam.createUser("User Description", (success) => { 
    // do something on success    
  }, (error) => {
   // do something on error
  });
  `;

  return (
    <div className="rn-component">
      <div className="integrate-sdk-card">
        <ReactNativeSDKDocsBox
          introduction="Roam SDK needs a User ID object to identify the device."
          className="forth-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="javascript"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </ReactNativeSDKDocsBox>
      </div>
    </div>
  );
};

CreateUser.displayName = "CreateUser";
CreateUser.propTypes = {};
CreateUser.defaultProps = {};

export default Cssmodules(CreateUser, styles);
