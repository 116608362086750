import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import styles from "./reactNativeSDKDocs.scss";
import Button from "../../../../common/Button";
const codeString = `
// Call this method to check Location services for Android
GeoSpark.checkLocationServices( (status) => {
    // do something with status
}); 
​
// Call this method to request Location services for Android
GeoSpark.requestLocationServices();
`;

const AndroidPermission = () => {
  return (
    <div className="android-permission-component">
      <div className="gs-codesection">
        <SyntaxHighlighterComponent language="java" codeString={codeString} />
        <CopyToClipboard text={codeString}>
          <Button className="icon-button">
            <FaRegCopy />
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

AndroidPermission.displayName = "AndroidPermission";
AndroidPermission.propTypes = {};
AndroidPermission.defaultProps = {};

export default Cssmodules(AndroidPermission, styles);
