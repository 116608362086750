import React, { useState } from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";
import { DropdownButton, MenuItem, Dropdown, Image } from "react-bootstrap";
import { FaUsers } from "react-icons/fa";
import { IndustryType } from "./commonMessage/dataType";
import { IconContext } from "react-icons";

import styles from './customSelect.scss';

const CustomDropdown = ({
  placeholder,
  onChange,
  industry,
  items
}) => {

  return (

      <div className="event-test-container">
        <Dropdown 
        drop="down"
        className="event-dropdown"
        >
        <h6>Industry (optional)</h6>
        <Dropdown.Toggle variant="primary" id="dropdown-basic">
          {!industry ? "Select industry" : industry.name}
        </Dropdown.Toggle>
        <Dropdown.Menu>
          {
            items.map(
              (item, i) => (
                <Dropdown.Item onSelect={() => {
                  onChange({target: "industry", value: item})
                }}>{item.name}</Dropdown.Item>
              )
            )
          }
        </Dropdown.Menu>
        <IconContext.Provider
                  value={{
                    className: !industry ? "gs-grey" : "gs-filled"
                  }}
                >
                  <span className="gs-sdk-icon">
                  <FaUsers />
                  </span>
                </IconContext.Provider>
        </Dropdown>
      </div> 
  );
};

CustomDropdown.displayName = "CustomDropdown";
CustomDropdown.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  help: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func,
  type: PropTypes.string,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  validationState: PropTypes.string,
};
CustomDropdown.defaultProps = {
  onChange() {
    return null;
  },
  autoFocus: false,
};

export default Cssmodules(CustomDropdown, styles);





    