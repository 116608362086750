import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./reactNativeSDKIntegrationSteps.scss";
import InstallModule from "../ReactNativeSDKDocs/InstallModule";
import InstallSDK from "../ReactNativeSDKDocs/InstallSDK";
import InitializeSDK from "../ReactNativeSDKDocs/InitializeSDK";
import GetPermissions from "../ReactNativeSDKDocs/GetPermissions";

import CreateUser from "../ReactNativeSDKDocs/CreateUser";
import StartTracking from "../ReactNativeSDKDocs/StartTracking";

const ReactNativeSDKIntegrationSteps = ({
  validPackageName,
  handleChange,
  docsStep,
  onGoBack,
  onGoNext,
  projectName
}) => {
  return (
    <div className="ReactNative-ask-component">
      <div>
        <h3>
          Integrate the ReactNative SDK for <br />
          <span>{projectName}</span>
        </h3>

        {docsStep === 0 ? (
          <InstallModule onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 1 ? (
          <InstallSDK onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 2 ? (
          <InitializeSDK onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 3 ? (
          <CreateUser onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 4 ? (
          <GetPermissions onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : docsStep === 5 ? (
          <StartTracking onGoBack={onGoBack} onGoNext={onGoNext} />
        ) : (
          <InstallSDK onGoBack={onGoBack} onGoNext={onGoNext} />
        )}
      </div>
    </div>
  );
};

ReactNativeSDKIntegrationSteps.displayName = "ReactNativeSDKIntegrationSteps";
ReactNativeSDKIntegrationSteps.propTypes = {};
ReactNativeSDKIntegrationSteps.defaultProps = {};

export default Cssmodules(ReactNativeSDKIntegrationSteps, styles);
