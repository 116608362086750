import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { FaRegCopy } from "react-icons/fa";
import RNSDKDocsBox from "./SDKDocsBox";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const InstallModule = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `
  $ npm install react-native-geospark --save
  $ react-native link react-native-geospark
  `;
  return (
    <div className="rn-sdk-component">
      <div className="integrate-sdk-card">
        <RNSDKDocsBox
          introduction="In your project directory, install from npm, and then link it."
          className="first-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />

            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </RNSDKDocsBox>
      </div>
    </div>
  );
};

InstallModule.displayName = "InstallModule";
InstallModule.propTypes = {};
InstallModule.defaultProps = {};

export default Cssmodules(InstallModule, styles);
