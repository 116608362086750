import * as types from "../actions/actionTypes";

const initialState = {
  profile_details: null,
  profile_fetching: false,
  profile_fetching_error: null,
  profile_saving: false,
  profile_saving_error: null
};

export default function profileReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROFILE_DATA_FETCHING:
      return Object.assign({}, state, {
        profile_fetching: true
      });
    case types.PROFILE_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        profile_fetching: false,
        profile_details: action.data
      });

    case types.PROFILE_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        profile_fetching: false,
        profile_fetching_error: action.error
      });
    case types.PROFILE_DATA_SAVING:
      return Object.assign({}, state, {
        profile_details: state.profile_details,
        profile_saving: true
      });
    case types.PROFILE_DATA_SAVING_SUCCESS:
      return Object.assign({}, state, {
        profile_saving: false,
        profile_details: action.data
      });
    case types.PROFILE_DATA_SAVING_FAILURE:
      return Object.assign({}, state, {
        profile_saving: false,
        profile_saving_error: action.error
      });
    case types.CHANGE_PASSWORD_SAVING:
      return Object.assign({}, state, {
        profile_saving: true
      });
    case types.CHANGE_PASSWORD_SAVING_SUCCESS:
      return Object.assign({}, state, {
        profile_saving: false
      });
    case types.CHANGE_PASSWORD_SAVING_FAILURE:
      return Object.assign({}, state, {
        profile_saving: false,
        profile_saving_error: action.error
      });

    default:
      return state;
  }
}
