import _ from "underscore";
import AuthApi from "../../api/authApi";
import notifications from "./notifications";
import history from "./history";

export function createApiAction(
  apiCaller,
  {
    errorType,
    requestType,
    responseType,
    errorConverter = (error) => error.data,
    responseConverter = (data) => data,
    onError = () => null,
    onRequest = () => null,
    onResponse = () => null
  }
) {
  function action(args, allowActionLevelnotifcation = true) {
    function errorAction(error) {
      return (dispatch) => {
        const data = errorConverter(error);
        if (data === undefined) {
          throw error;
        }
        dispatch({ type: errorType, data });
        onError(dispatch, error, args);
      };
    }

    function requestAction(args) {
      if (typeof args === "object") {
        if (args === null) {
          setTimeout(function () {
            window.location = "/login";
          }, 100);
        }
      }

      return (dispatch) => {
        dispatch({ type: requestType, data: args });
        onRequest(dispatch, args);
      };
    }

    function responseAction(data) {
      const respo = JSON.parse(JSON.stringify(data));
      return (dispatch) => {
        if (respo && respo.status < 300) {
          dispatch({
            type: responseType,
            data: responseConverter(data.data.data)
          });
          // now compress the allowActionLevelnotification to the data field
          // for our convinience
          if (!(args instanceof Object)) {
            args = { token: args };
          }
          args.allowActionLevelnotifcation = allowActionLevelnotifcation;
          onResponse(dispatch, data, args);
          if (
            respo &&
            respo.hasOwnProperty("status") &&
            (respo.status === 200 || respo.status === 201) &&
            respo.data.msg === "Success." &&
            localStorage.getItem("card_detail_error")
          ) {
            localStorage.removeItem("card_detail_error");
          }
        } else if (
          respo &&
          respo.hasOwnProperty("response") &&
          respo.response.hasOwnProperty("status") &&
          (respo.response.status === 400 ||
            respo.response.status === 401 ||
            respo.response.status === 405 ||
            respo.response.status === 404)
        ) {
          if (
            respo.response.status === 400 &&
            respo.response.data.hasOwnProperty("data") &&
            respo.response.data.data.hasOwnProperty("project_id") &&
            respo.response.data.data.project_id.hasOwnProperty("project_id") &&
            respo.response.data.data.project_id.project_id ===
              "This field is invalid."
          ) {
            history.push({
              pathname: "/projects"
            });
          }

          if (
            respo.response.status === 405 ||
            (respo.response.status === 404 &&
              respo.config.url.match(/v3\/user/))
          ) {
            history.push({
              pathname: "/projects"
            });
          }

          if (respo.response.status === 405 || respo.response.status === 404) {
            return;
          }

          if (
            respo.response.data.msg ===
            "Issue adding card. Please contact support."
          ) {
            localStorage.setItem("card_detail_error", "invalid_card");
          }
          if (respo.response.data.msg === "Unknown Error.") {
            localStorage.setItem("card_detail_error", "invalid GST");
            notifications.sendNotification("error", {
              title: "Invalid tax Id."
            })(dispatch);
          }

          if (
            respo.response.data.hasOwnProperty("data") &&
            respo.response.data.data.hasOwnProperty("non_field_error")
          ) {
            const errorMsg = respo.response.data.data.non_field_error;
            if (errorMsg[0] === "Project does not exist.") {
              history.push({
                pathname: "/projects"
              });
            }
          }

          if (
            respo.response.data.hasOwnProperty("data") &&
            respo.response.data.data.hasOwnProperty("non_field_error")
          ) {
            const errorMsg = respo.response.data.data.non_field_error;

            if (errorMsg[0] === "Account settings already active.") {
              notifications.sendNotification("error", {
                title: "Already in the same plan."
              })(dispatch);
            } else if (errorMsg[0] === "Customer Id does not exist.") {
              localStorage.setItem("card_detail_error", "found");
              notifications.sendNotification("error", {
                title: "Please check your Card details."
              })(dispatch);
            } else if (
              [
                "This geofence is part of one or more active campaigns.",
                "This geofence is part of an active campaign."
              ].includes(errorMsg[0])
            ) {
              const data = errorConverter(respo.response);
              if (data === undefined) {
                throw errorMsg[0];
              }
              dispatch({ type: errorType, data });
              onError(dispatch, respo.response, args);
            } else {
              if (
                respo.response.data.data.non_field_error.hasOwnProperty(
                  "payment_Error"
                )
              ) {
                localStorage.setItem("card_detail_error", "card error");
                notifications.sendNotification("error", {
                  title: "Issue adding card. Please contact support."
                })(dispatch);
              } else {
                if (errorMsg?.request_Error) {
                  notifications.sendNotification("error", {
                    title: errorMsg.request_Error
                  })(dispatch);
                } else
                  notifications.sendNotification("error", {
                    title: errorMsg[0]
                  })(dispatch);
              }
              if (
                errorMsg[0] ===
                  "Current plan limit exceeded for your account." &&
                respo.config.url.match(/dashboard\/projects/) !== null
              ) {
                history.push({
                  pathname: "/projects"
                });
              }
            }
          } else if (respo.response.data.msg === "Token is expired.") {
            AuthApi.logoutAccount(localStorage.getItem("playground_token"));
            localStorage.removeItem("playground_token");
            localStorage.removeItem("playground_token_time");

            notifications.sendNotification("error", {
              title: "You session is expired. Please login again."
            })(dispatch);
            setTimeout(function () {
              window.location = "/login";
            }, 3000);
          } else {
            if (respo.response.data.msg === "Unknown Error.") {
              notifications.sendNotification("error", {
                title: "Invalid Tax Id"
              })(dispatch);
            } else if (respo.response.data.msg === "Unkown Event") {
              notifications.sendNotification("error", {
                title: "Select event to test."
              })(dispatch);
            } else if (
              respo.response.data.msg === "Error Occured." &&
              respo.response.data.hasOwnProperty("data") &&
              respo.response.data.data.hasOwnProperty("geofence_id") &&
              respo.response.data.data.geofence_id.hasOwnProperty(
                "geofence_id"
              ) &&
              respo.response.data.data.geofence_id.geofence_id ===
                "This field is invalid."
            ) {
              notifications.sendNotification("error", {
                title: "Geofence ID is invalid."
              })(dispatch);
            } else if (
              respo.response.status === 400 &&
              respo.response.hasOwnProperty("data") &&
              respo.response.data.hasOwnProperty("data") &&
              respo.response.data.data.hasOwnProperty("user_id") &&
              respo.response.data.data.user_id.hasOwnProperty("user_id") &&
              respo.response.data.data.user_id.user_id ===
                "This field is invalid."
            ) {
              notifications.sendNotification("error", {
                title: "Invalid User Id."
              })(dispatch);
            } else {
              notifications.sendNotification("error", {
                title: respo.response.data.msg
              })(dispatch);
            }
          }
          dispatch({
            type: errorType,
            data: data
          });
        } else {
          try {
            localStorage.removeItem("BackendError");
            dispatch({
              type: responseType,
              data: responseConverter(data.data.data)
            });
          } catch (err) {
            dispatch({
              type: responseType,
              data: {}
            });
            localStorage.setItem("BackendError", "TRUE");
            setTimeout(function () {
              window.location = "/login";
            }, 2000);
          }
        }
      };
    }

    return (dispatch) => {
      dispatch(requestAction(args));
      apiCaller(args)
        .then((resp) => dispatch(responseAction(resp)))
        .catch((error) => dispatch(errorAction(error)));
    };
  }

  return action;
}

export default { createApiAction };
