import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import Button from "../../../../common/Button";
import IOSSDKDocsBox from "./SDKDocsBox";

import styles from "./reactNativeSDKDocs.scss";

const StartTracking = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `
  GeoSpark.startTracking();   
  `;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          introduction="To start tracking the location."
          className="sixth-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />

            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

StartTracking.displayName = "StartTracking";
StartTracking.propTypes = {};
StartTracking.defaultProps = {};

export default Cssmodules(StartTracking, styles);
