import React from "react";
import PropTypes from "prop-types";
import { Form } from "react-bootstrap";

import "./projectNameForm.scss";
import FieldGroup from "../../common/InputBox";
import { FaFileAlt } from "react-icons/fa";
import { ExtraShortNameErrorHelp } from "../../common/commonMessage/errorMessages";
import Button from "../../common/Button";
import { IconContext } from "react-icons";
import { IoMdListBox } from "react-icons/io";

import IgniteLogo from "../../../public/images/ignite-small.png";
import RoamAroundLogo from "../../../public/images/roamaround-logo.svg";

import "./igniteCard.scss";
import commonStyle from "../../common/commonStyle";

const InitialIgniteProjectCard = ({
  handleChange,
  onSubmit,
  keyPress,
  validProjectName,
  onChangeRoute,
  onCreateProject,
  disabled,
  showFirstLabel,
  manualIgniteStatus
}) => {
  let msg = "Try our demo app";
  if (manualIgniteStatus) {
    msg = "Continue";
  }
  return (
    <div>
      <div>
        <h3>
          Create your{" "}
          {showFirstLabel && !manualIgniteStatus ? <span>first</span> : ""}{" "}
          project
        </h3>
        <h6>One place to manage and visualize your location enabled apps.</h6>
      </div>

      <Form onKeyDown={keyPress}>
        <div className="project-card">
          <div className="pre-textbox">
            <FieldGroup
              id="ProjectName"
              label="Project Name*"
              name="projectName"
              type="text"
              placeholder="enter project name"
              onChange={(e) => handleChange(e)}
              help={
                validProjectName > 0
                  ? ExtraShortNameErrorHelp[validProjectName - 1]
                  : null
              }
              icon={
                <IconContext.Provider
                  value={{
                    className: validProjectName !== 0 ? "gs-grey" : "gs-filled"
                  }}
                >
                  <div>
                    <IoMdListBox />
                  </div>
                </IconContext.Provider>
              }
            />
          </div>
        </div>
      </Form>

      {/* ignite card*/}

      <div className="ignite-card-component">
        <div className="gs-ignite-head">
          <h3>
            <span>RoamAround </span>
            {/* <a
              href="https://www.roam.ai/"
              target="_blank"
              rel="noopener noreferrer"
              style={commonStyle.linkColor}
            >
              roam.ai
            </a> */}
          </h3>
          <div>
            <img
              alt="ignite-logo"
              src={RoamAroundLogo}
              style={{ width: "3em", height: "auto" }}
            />
          </div>
        </div>
        <p className="gs-ignite-descp">
          <span style={{ "font-weight": "500" }}>Not sure where to start?</span>{" "}
          Try our demo app to experience location tracking and other features.
        </p>
      </div>

      <div className="gs-project-button">
        <Button
          className={
            validProjectName !== 0
              ? "gs-btn gs-disabled create-project-initial-btn"
              : "gs-primary create-project-initial-btn"
          }
          disabled={validProjectName !== 0}
          label="Create Project"
          onClick={(e) => onCreateProject(e, "manual")}
        />
        <span style={{ marginRight: "22px" }}>or</span>
        <a
          className={
            disabled
              ? "disabled gs-btn gs-disabled"
              : "gs-primary gs-ignite-initial-btn"
          }
          href="/project-demo"
          style={{
            padding: "11Px 35px",
            textDecoration: "none",
            fontSize: "14px"
          }}
          onClick={(e) => onCreateProject(e, "demo")}
        >
          Try Demo App
        </a>
      </div>
    </div>
  );
};

InitialIgniteProjectCard.propTypes = {
  handleChange: PropTypes.func,
  showFirstLabel: false
};
InitialIgniteProjectCard.defaultProps = {};

export default InitialIgniteProjectCard;
