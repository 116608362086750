import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import IOSPlistConfige from "../../../../../public/images/IOSPlistConfige.png";
import IOSBKModeConfige from "../../../../../public/images/IOSBKModeConfige.png";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const codeString = `
pod 'roam-ios'

`;

const InstallIOS = ({ docsStep, onGoBack, onGoNext }) => {
  return (
    <div className="install-ios-component">
      <div>
        <div>
          <span className="gs-span">
            Install using Cocoapods, open podfile and add SDK to file.
          </span>
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="swift"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
          <span className="gs-span">
            Once you have updated your podfile, run pod install in your
            terminal.
          </span>
        </div>
        <h3>Configure project</h3>
        <span className="gs-span">
          To configure the location services, add following entries to the
          Info.plist file.
        </span>
      </div>
      <img
        className="gs-integrate-img"
        src={IOSPlistConfige}
        alt="IOS Plist Confige"
      />
      <span className="gs-span">
        Then, in your project settings, go to Capabilities > Background Modes
        and turn on background fetch, location updates ,remote-notifications.
      </span>
      <img
        className="gs-integrate-img"
        src={IOSBKModeConfige}
        alt="IOS Background Mode Confige"
      />
      <span className="gs-span">
        Then, go to Build Settings in the project targets and change 'Always
        Embed Swift Standard Libraries' to 'Yes'.
      </span>
      <div>
        <h4>Manual Linking</h4>
        <ol>
          <li>
            Open the iOS module files, located inside
            node_modules/react-native-geospark/ios/.
          </li>
          <li>Open the app workspace file (AppName.xcworkspace) in Xcode.</li>
          <li>
            Move the RNGeoSpark.h and RNGeoSpark.m files to your project. When
            shown a popup window, select Create groups.
          </li>
        </ol>
      </div>
    </div>
  );
};

InstallIOS.displayName = "InstallIOS";
InstallIOS.propTypes = {};
InstallIOS.defaultProps = {};

export default Cssmodules(InstallIOS, styles);
