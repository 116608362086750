import React from "react";
import Cssmodules from "react-css-modules";
import QRCode from "qrcode.react";
import { IconContext } from "react-icons";
import SimpleLoader from "./SimpleLoader";
import MapComponent from "./MapComponent";
import styles from "./boxAndMap.scss";
import rightimage from "../../public/images/map.webp";
import rightdemoimage from "../../public/images/Roamaround-banner.png";

const titleString =
  "This is a long" +
  " string. That's why we want to " +
  " split it over several lines";

const BoxAndMapComponent = ({
  children,
  showQRCode,
  showDemoImage,
  publishKey,
  showMap,
  showIcon,
  label,
  icon,
  users
}) => {
  return (
    <div
      className={label === "post-login" ? "postlogin-main" : "prelogin-main"}
    >
      {children}
      <div className="prelogin-right">
        {showIcon && (
          <div className="map-icon-container">
            <IconContext.Provider
              value={{ color: "white", size: "2em", className: "map-icon" }}
            >
              <div>{icon}</div>
            </IconContext.Provider>
          </div>
        )}

        {showQRCode && (
          <div>
            <QRCode value={publishKey} className="gs-qr" size={174} />
            <div className="loaderqr-container">
              <SimpleLoader loaderTitle="Waiting for the location update from the user device" />
            </div>
          </div>
        )}
        {showMap ? (
          <MapComponent users={users} />
        ) : (
          <div>
            <div>
              <img
                alt="roam"
                className={showQRCode ? "gs-img-code" : "geo-rightimage"}
                src={rightimage}
              />

              {showDemoImage && (
                <img
                  alt="roam"
                  className="gs-rightdemoimage"
                  src={rightdemoimage}
                />
              )}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

BoxAndMapComponent.displayName = "BoxAndMapComponent";
BoxAndMapComponent.propTypes = {};
BoxAndMapComponent.defaultProps = {
  showQRCode: false,
  showMap: false,
  label: "pre-login",
  icon: null,
  showDemoImage: false
};

export default Cssmodules(BoxAndMapComponent, styles);
