import * as types from "../actions/actionTypes";

const initialState = {
  cards_fetching: false,
  cards: null,
  billing_details: null,
  billing_fetching: false,
  cards_fetching_error: null,
  billing_invoices: null,
  billing_invoices_fetching: false,
  card_add_success: false,
  billing_monthly_spend_fetching: false,
  billing_monthly_spend_details: null,
  iban_details_fetching: false,
  iban_details: null,
  iban_payment_saving: false,
  iban_payment: null,
  coupon_code_fetching: false,
  coupon_code_details: null
};

export default function geofenceReducer(state = initialState, action) {
  switch (action.type) {
    case types.BILLING_DETAILS_FETCHING:
      return Object.assign({}, state, {
        billing_fetching: true
      });
    case types.BILLING_DETAILS_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        billing_fetching: false,
        billing_details: action.data
      });
    case types.CARDS_DETAILS_FETCHING:
      return Object.assign({}, state, {
        cards_fetching: true
      });
    case types.CARDS_DETAILS_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        cards_fetching: false,
        cards: action.data
      });
    case types.CARDS_DETAILS_FETCHING_FAILURE:
      return Object.assign({}, state, {
        cards_fetching: false,
        cards_fetching_error: action.error
      });
    case types.CREATE_CARD_SAVING_SUCCESS:
      return Object.assign({}, state, {
        cards_fetching: false,
        cards: action.data,
        card_add_success: true
      });
    case types.CREATE_CARD_SAVING:
      return Object.assign({}, state, {
        cards_fetching: true,
        card_add_success: true
      });
    case types.CREATE_CARD_SAVING_FAILURE:
      if (
        action.data.response.data.msg ===
        "Issue adding card. Please contact support."
      ) {
        return Object.assign({}, state, {
          card_add_success: false
        });
      }
    case types.BILLING_INVOICES_FETCHING:
      return Object.assign({}, state, {
        billing_invoices_fetching: true
      });
    case types.BILLING_INVOICES_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        billing_invoices_fetching: false,
        billing_invoices: action.data
      });
    case types.BILLING_MONTHLY_SPEND_FETCHING:
      return Object.assign({}, state, {
        billing_monthly_spend_fetching: true
      });
    case types.BILLING_MONTHLY_SPEND_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        billing_monthly_spend_fetching: false,
        billing_monthly_spend_details: action.data
      });

    case types.IBAN_DETAILS_FETCHING:
      return Object.assign({}, state, {
        iban_details_fetching: true
      });
    case types.IBAN_DETAILS_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        iban_details_fetching: false,
        iban_details: action.data
      });
    case types.IBAN_DETAILS_FETCHING_FAILURE:
      return Object.assign({}, state, {
        iban_details_fetching: false,
        iban_details: action.error
      });
    case types.IBAN_PAYMENT_SAVING:
      return Object.assign({}, state, {
        iban_payment_saving: true
      });
    case types.IBAN_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        iban_payment_saving: false,
        iban_payment: action.data
      });
    case types.IBAN_PAYMENT_FAILURE:
      return Object.assign({}, state, {
        iban_payment_saving: false,
        iban_payment: action.error
      });

    case types.COUPON_CODE_FETCHING:
      return Object.assign({}, state, {
        coupon_code_fetching: true
      });
    case types.COUPON_CODE_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        coupon_code_fetching: false,
        coupon_code_details: action
      });
    case types.COUPON_CODE_FETCHING_FAILURE:
      return Object.assign({}, state, {
        coupon_code_fetching: false,
        coupon_code_details: action
      });

    case types.REMOVE_COUPON_CODE_FETCHING:
      return Object.assign({}, state, {
        coupon_code_fetching: true
      });
    case types.REMOVE_COUPON_CODE_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        coupon_code_fetching: false,
        coupon_code_details: action
      });
    case types.REMOVE_COUPON_CODE_FETCHING_FAILURE:
      return Object.assign({}, state, {
        coupon_code_fetching: false,
        coupon_code_details: action
      });

    default:
      return state;
  }
}
