import React from "react";
import Cssmodules from "react-css-modules";

import styles from "./androidTitleProgressBar.scss";

const AndroidTitleProgressBar = ({ className }) => {
  return (
    <div className={`${className} andr-progress`}>
      <div className="progress-content">
        <div className="progress-steps active">Install SDK</div>
        {/* <div className="progress-steps step-2">Set Device Token</div> */}
        <div className="progress-steps step-2">Initialize SDK</div>
        <div className="progress-steps step-3">Create User</div>
        <div className="progress-steps step-4">Start Tracking</div>
      </div>
    </div>
  );
};

AndroidTitleProgressBar.displayName = "AndroidTitleProgressBar";

export default Cssmodules(AndroidTitleProgressBar, styles);
