import axios from "axios";
let testEnv = "https://test.api.geospark.co/v3/";
let motionUrl = "https://test.api.geospark.co/v3/";

if (process.env.REACT_APP_NODE_ENV === "stage") {
  testEnv = "https://playground-api.geospark.xyz/v3/";
} else if (process.env.REACT_APP_NODE_ENV === "development") {
  testEnv = "http://localhost:8000/v3/";
  motionUrl = "http://localhost:8000/v3/";
} else if (process.env.REACT_APP_NODE_ENV === "production") {
  testEnv = "https://api.geospark.co/v3/";
  motionUrl = "https://api.geospark.co/v3/";
}

class ApiCall {
  static get(url, token) {
    return axios({
      method: "get",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: { Authorization: `token ${token}` }
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static getUserEvents(url, token){
    return axios({
      method: "get",
      url: `${url}`,
      timeout: 120000,
      headers: { 'Api-key' : `${token}` }
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static getFromMotion(url, token) {
    return axios({
      method: "get",
      url: `${motionUrl}${url}`,
      timeout: 120000,
      headers: { Authorization: `token ${token}` }
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static getWithoutAuth(url) {
    return axios({
      method: "get",
      timeout: 120000,
      url: `${testEnv}${url}`
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static getFor3rdParty(url) {
    return axios({
      method: "get",
      timeout: 120000,
      url: `${url}`
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static post(url, data, token) {
    return axios({
      method: "post",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: { Authorization: `token ${token}` },
      data: data
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static postMultiForm(url, data, token) {
    return axios({
      method: "post",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: {
        Authorization: `token ${token}`,
        "Content-Type": "multipart/form-data"
      },
      data: data
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static postApiKey(url, data, apikey) {
    return axios({
      method: "post",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: {
        "Api-key": `${apikey}`
      },
      data: data
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static getApiKey(url, apikey) {
    return axios({
      method: "get",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: {
        "Api-key": `${apikey}`
      }
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static deleteApiKey(url, data, apikey) {
    return axios({
      method: "delete",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: {
        "Api-key": `${apikey}`
      }
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static put(url, data, token) {
    return axios({
      method: "put",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: { Authorization: `token ${token}` },
      data: data
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }

  static delete(url, data, token) {
    return axios({
      method: "delete",
      url: `${testEnv}${url}`,
      timeout: 120000,
      headers: { Authorization: `token ${token}` },
      data: data
    })
      .then((response) => response)
      .catch((error) => {
        return error;
      });
  }
}

export default ApiCall;
