import React from "react";
import PropTypes from "prop-types";
import Cssmodules from "react-css-modules";
import { Button } from "react-bootstrap";

import styles from "./button.scss";

const ButtonComponent = ({
  label,
  type,
  disabled,
  id,
  className,
  onClick,
  children,
  style
}) => {
  return (
    <Button
      type={type}
      onClick={onClick}
      disabled={disabled}
      bsPrefix={`gs-btn ${className}`}
      style={style}
      id={id}
    >
      {children}
      {label}
    </Button>
  );
};

ButtonComponent.displayName = "ButtonComponent";
ButtonComponent.propTypes = {
  label: PropTypes.string,
  className: PropTypes.string,
  onClick: PropTypes.func
};
ButtonComponent.defaultProps = {
  onClick() {
    return null;
  }
};

export default Cssmodules(ButtonComponent, styles);
