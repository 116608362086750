import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import RNSDKDocsBox from "./SDKDocsBox";
import SDKType from "./SDKType";
import InstallIOS from "./InstallIOS";
import InstallAndroid from "./InstallAndroid";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const InstallSdk = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `
  pod 'roam-ios'
  `;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <RNSDKDocsBox
          introduction="Install using Cocoapods, open podfile add SDK to file."
          className="second-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <SDKType
            component1={<InstallIOS />}
            component2={<InstallAndroid />}
          />
          <CopyToClipboard text={codeString}>
            <Button className="icon-button">
              <FaRegCopy />
            </Button>
          </CopyToClipboard>
        </RNSDKDocsBox>
      </div>
    </div>
  );
};

InstallSdk.displayName = "InstallSdk";
InstallSdk.propTypes = {};
InstallSdk.defaultProps = {};

export default Cssmodules(InstallSdk, styles);
