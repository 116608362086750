import React from "react";
import Cssmodules from "react-css-modules";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";
import IOSSDKDocsBox from "./SDKDocsBox";
import IOSSDKCodeType from "./IOSSDKCodeType";
import styles from "./iOSSDKDocs.scss";

const InitializeSDK = ({
  docsStep,
  onGoBack,
  onGoNext,
  onIOSTypeChange,
  iOSType,
  publish_key
}) => {
  const codeString1 = `func application(_ application: UIApplication, didFinishLaunchingWithOptions launchOptions: [UIApplication.LaunchOptionsKey: Any]?) -> Bool {
  Roam.intialize("PUBLISHABLEKEY")
      return true
}`;
  const codeString2 = `- (BOOL)application:(UIApplication *)application didFinishLaunchingWithOptions:(NSDictionary *)launchOptions {
  [Roam intialize:@"PUBLISHABLEKEY"];
  return YES;
} `;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <IOSSDKDocsBox
          introduction="Initialize the SDK in your AppDelegate class before calling any other Roam methods under this application:didFinishLaunchingWithOptions:"
          className="third-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div>
            <span className="gs-publishkey">Publish key: {publish_key}</span>
          </div>
          <div className="gs-codesection">
            <IOSSDKCodeType
              codeString1={codeString1.trim()}
              codeString2={codeString2.trim()}
              onIOSTypeChange={onIOSTypeChange}
              iOSType={iOSType}
            />
            <CopyToClipboard
              text={
                iOSType === "Swift" ? codeString1.trim() : codeString2.trim()
              }
            >
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </IOSSDKDocsBox>
      </div>
    </div>
  );
};

InitializeSDK.displayName = "InitializeSDK";
InitializeSDK.propTypes = {};
InitializeSDK.defaultProps = {};

export default Cssmodules(InitializeSDK, styles);
