import React, { Component } from "react";
import Cssmodules from "react-css-modules";

import styles from "./iOSSDKIntegrationSteps.scss";
import InstallSdk from "../IOSSDKDocs/InstallSDK";
import ConfigeProject from "../IOSSDKDocs/ConfigeProject";
import SetDeviceToken from "../IOSSDKDocs/SetDeviceToken";
import InitializedSDK from "../IOSSDKDocs/InitializedSDK";
import CreateUser from "../IOSSDKDocs/CreateUser";
import StartTracking from "../IOSSDKDocs/StartTracking";

export class IOSSDKIntegrationSteps extends Component {
  constructor(props) {
    super(props);
    this.state = {
      iOSType: "Swift",
    };
    this.onIOSTypeChange = this.onIOSTypeChange.bind(this);
  }

  onIOSTypeChange(e) {
    this.setState({
      iOSType: e,
    });
  }

  render() {
    const { iOSType } = this.state;
    const { projectName, docsStep, onGoBack, onGoNext, publish_key } =
      this.props;

    return (
      <div className="IOS-ask-component">
        <div>
          <h3>
            Integrate the IOS SDK for
            <br />
            <span> {projectName}</span>
          </h3>

          {docsStep === 0 ? (
            <InstallSdk
              onGoBack={onGoBack}
              onGoNext={onGoNext}
              iOSType={iOSType}
              onIOSTypeChange={this.onIOSTypeChange}
            />
          ) : docsStep === 1 ? (
            <ConfigeProject onGoBack={onGoBack} onGoNext={onGoNext} />
          ) : docsStep === 2 ? (
            <InitializedSDK
              onGoBack={onGoBack}
              onGoNext={onGoNext}
              iOSType={iOSType}
              onIOSTypeChange={this.onIOSTypeChange}
              publish_key={publish_key}
            />
          ) : docsStep === 3 ? (
            <CreateUser
              onGoBack={onGoBack}
              onGoNext={onGoNext}
              iOSType={iOSType}
              onIOSTypeChange={this.onIOSTypeChange}
            />
          ) : docsStep === 4 ? (
            <StartTracking
              onGoBack={onGoBack}
              onGoNext={onGoNext}
              iOSType={iOSType}
              onIOSTypeChange={this.onIOSTypeChange}
            />
          ) : (
            <InstallSdk
              onGoBack={onGoBack}
              onGoNext={onGoNext}
              iOSType={iOSType}
              onIOSTypeChange={this.onIOSTypeChange}
            />
          )}
        </div>
      </div>
    );
  }
}

IOSSDKIntegrationSteps.displayName = "IOSSDKIntegrationSteps";
IOSSDKIntegrationSteps.propTypes = {};
IOSSDKIntegrationSteps.defaultProps = {};

Cssmodules(IOSSDKIntegrationSteps, styles);
export default IOSSDKIntegrationSteps;
