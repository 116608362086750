import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { github } from "react-syntax-highlighter/dist/esm/styles/hljs";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const codeString = `
// Call this method to check Motion Permission for iOS
GeoSpark.checkMotionPermission( (status) => {
    // do something with status
}); 

// Call this method to request Motion Permission for iOS
GeoSpark.requestMotionPermission();
`;

const IOSPermission = () => {
  return (
    <div className="ios-permission-component">
      <div className="gs-codesection">
        <SyntaxHighlighterComponent language="swift" codeString={codeString} />
        <CopyToClipboard text={codeString}>
          <Button className="icon-button">
            <FaRegCopy />
          </Button>
        </CopyToClipboard>
      </div>
    </div>
  );
};

IOSPermission.displayName = "IOSPermission";
IOSPermission.propTypes = {};
IOSPermission.defaultProps = {};

export default Cssmodules(IOSPermission, styles);
