import React from "react";
import Cssmodules from "react-css-modules";
import { Tabs, Tab } from "react-bootstrap";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";

import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";

import styles from "./reactNativeSDKDocs.scss";

const ReactNativeSDKCodeType = ({ codeString1, codeString2 }) => {
  return (
    <div className="ios-code-type-component">
      <Tabs defaultActiveKey="Swift" id="ios-code-type">
        <Tab eventKey="Swift" title="Swift">
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString1}
            />
            <CopyToClipboard text={codeString1}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </Tab>
        {codeString2 && (
          <Tab eventKey="Objective-C" title="Objective-C">
            <div className="gs-codesection">
              <SyntaxHighlighterComponent
                language="Objective-C"
                codeString={codeString2}
              />
              <CopyToClipboard text={codeString2}>
                <Button className="icon-button">
                  <FaRegCopy />
                </Button>
              </CopyToClipboard>
            </div>
          </Tab>
        )}
      </Tabs>
    </div>
  );
};

ReactNativeSDKCodeType.displayName = "ReactNativeSDKCodeType";
ReactNativeSDKCodeType.propTypes = {};
ReactNativeSDKCodeType.defaultProps = {};

export default Cssmodules(ReactNativeSDKCodeType, styles);
