import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";
import AndroidSDKDocsBox from "./SDKDocsBox";

import styles from "./androidSDKDocs.scss";

const InitializeSDK = ({ docsStep, onGoBack, onGoNext, publish_key }) => {
  const codeString = `//In onCreate method of your Application class include the code below.
public void onCreate() {
    super.onCreate();
    Roam.initialize(this, "PUBLISH_KEY");
    ...
}`;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <AndroidSDKDocsBox
          introduction="Initialize your SDK with Publishable key."
          className="second-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div>
            <span className="gs-publishkey">Publish key: {publish_key}</span>
          </div>

          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString}
            />
            <CopyToClipboard text={codeString}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </AndroidSDKDocsBox>
      </div>
    </div>
  );
};

InitializeSDK.displayName = "InitializeSDK";
InitializeSDK.propTypes = {};
InitializeSDK.defaultProps = {};

export default Cssmodules(InitializeSDK, styles);
