import * as types from "../actions/actionTypes";

const initialState = {
  current_project_id: null,
  trips_fetching: false,
  trips: null,
  trip_route: null,
  trips_route_fetching: false,
  trips_fetching_error: null,
  trip_pages: null
};

export default function tripReducer(state = initialState, action) {
  switch (action.type) {
    case types.PROJECT_TRIPS_DATA_FETCHING:
      let data = {
        trips_fetching: true,
        dateSelected: action.data.data.date,
        current_project_id: action.data.project_id,
        page_limit: action.data.data.page_limit
      };

      if (action.data.project_id !== state.current_project_id) {
        data.trips = null;
        data.trip_route = null;
        data.trip_pages = null;
      }

      return Object.assign({}, state, data);
    case types.PROJECT_TRIPS_DATA_FETCHING_SUCCESS:
      let new_trips = state.trips;
      if (state.trips && action.data.prev_page !== null) {
        new_trips = new_trips.concat(action.data.trips);
      } else {
        new_trips = action.data.trips;
      }
      return Object.assign({}, state, {
        trips_fetching: false,
        trips: new_trips,
        trip_pages: action.data.next_page
      });
    case types.PROJECT_TRIPS_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        trips_fetching: false,
        trips_fetching_error: action.error
      });

    case types.PROJECT_TRIP_ROUTE_DATA_FETCHING:
      return Object.assign({}, state, {
        trips_fetching: true
      });
    case types.PROJECT_TRIP_ROUTE_DATA_FETCHING_SUCCESS:
      return Object.assign({}, state, {
        trips_fetching: false,
        trip_route: action.data
      });
    case types.PROJECT_TRIP_ROUTE_DATA_FETCHING_FAILURE:
      return Object.assign({}, state, {
        trips_fetching: false,
        trips_fetching_error: action.error
      });
    case types.CLEAR_TRIPS_DATA:
      return Object.assign({}, state, {
        current_project_id: null,
        trips_fetching: false,
        trips: null,
        trip_route: null,
        trips_route_fetching: false,
        trips_fetching_error: null,
        trip_pages: null
      });
    default:
      return state;
  }
}
