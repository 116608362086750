import React from "react";
import Cssmodules from "react-css-modules";
import SyntaxHighlighterComponent from "../../../../common/SyntaxHighlighter";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FaRegCopy } from "react-icons/fa";

import Button from "../../../../common/Button";
import AndroidSDKDocsBox from "./SDKDocsBox";

import styles from "./androidSDKDocs.scss";

const SetDeviceToken = ({ docsStep, onGoBack, onGoNext }) => {
  const codeString = `
  GeoSpark.setDeviceToken(this, "FCM DeviceToken")
  
  `;
  return (
    <div className="android-ask-component">
      <div className="integrate-sdk-card">
        <AndroidSDKDocsBox
          introduction="Set FCM device token to create an user. If you haven't set FCM DeviceToken already, then add firebase to your project."
          className="second-step"
          Button1Label="Go Back"
          Button2Label="Next"
          onClickOnButton1={onGoBack}
          onClickOnButton2={onGoNext}
        >
          <div className="gs-codesection">
            <SyntaxHighlighterComponent
              language="java"
              codeString={codeString.trim()}
            />
            <CopyToClipboard text={codeString.trim()}>
              <Button className="icon-button">
                <FaRegCopy />
              </Button>
            </CopyToClipboard>
          </div>
        </AndroidSDKDocsBox>
      </div>
    </div>
  );
};

SetDeviceToken.displayName = "SetDeviceToken";
SetDeviceToken.propTypes = {};
SetDeviceToken.defaultProps = {};

export default Cssmodules(SetDeviceToken, styles);
